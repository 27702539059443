import { AccountLicense } from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import type { SecuritySettingType } from '@experiences/interfaces';
import { SecuritySettingEnum } from '@experiences/interfaces';
import { UiSuspensefulOutlet } from '@experiences/ui-common';
import type { PropsWithChildren } from 'react';
import React, {
    Suspense,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
    AuthSettingsDeprecated,
    AuthSettingsDeprecatedConfigure,
    AuthSettingsPasswordPolicy,
    ConfigureSSO,
    Encryption,
    EncryptionConfigure,
    IPRestriction,
    IPRestrictionAdd,
    IPRestrictionBulkImport,
    IPRestrictionEdit,
    OrganizationAccessPolicy,
    OrganizationAccessPolicyAdd,
    SecuritySettings,
    SecuritySettingsAttributeMapping,
    SecuritySettingsConfigure,
    SecuritySettingsSAMLProvisioningRules,
    SecuritySettingsSAMLProvisioningRulesAdd,
    SecuritySettingsSAMLProvisioningRulesEdit,
    SessionPolicy,
} from '../../common/constants/RouteNames';
import { routePaths } from '../../common/constants/routePaths';
import useCheckLicense from '../../common/hooks/useCheckLicense';
import { useOrganizationName } from '../../common/hooks/useOrganizationName';
import BreadcrumbProvider from '../../common/providers/BreadcrumbProvider';
import CheckGuard from '../../container/helpers/CheckGuard';
import type { UiRouteObject } from '../../container/routeConfigs/UiRouteObject';
import { accountType as accountTypeSelector } from '../../store/selectors';

const SecuritySettingsPageComponent = React.lazy(() => import('./SecuritySettings'));
const ConfigureSSOComponent = React.lazy(() => import('../authsettings/subcomponents/ConfigureSSOComponent'));
const ConfigureSSOPage = React.lazy(() => import('./ConfigureSSOPage'));
const SAMLProvisioningRulesComponent = React.lazy(() => import('../authsettings/subcomponents/SAMLProvisioningRulesComponent'));
const CreateEditSAMLProvisioningRuleComponent = React.lazy(
    () => import('../authsettings/subcomponents/CreateEditSAMLProvisioningRuleComponent')
);
const AttributeMappingPageComponent = React.lazy(() => import('./attributeMapping/AttributeMappingPageComponent'));
const AddEditIPRestrictionComponent = React.lazy(() => import('./subcomponents/IPRestriction/AddEditIPRestrictionComponent'));
const IPRestrictionBulkImportComponent = React.lazy(() => import('./subcomponents/IPRestriction/IPRestrictionBulkImportComponent'));
const EncryptionConfigureComponent = React.lazy(() => import('./subcomponents/Encryption/EncryptionConfigureComponent'));
const AddOrganizationAccessPolicyMembersDrawerComponent = React.lazy(
    () => import('./accessPolicy/add/AddOrganizationAccessPolicyMembersDrawerComponent')
);

const EditPasswordPolicyComponent = React.lazy(() => import('../authsettings/subcomponents/EditPasswordPolicyComponent'));

const SecuritySettingsProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { formatMessage: translate } = useIntl();

    const organizationName = useOrganizationName();

    const breadCrumbLinks = useMemo(() => [
        {
            index: 0,
            link: routePaths.admin,
            name: organizationName,
        },
        {
            index: 1,
            link: routePaths.adminSecurityAuthentication,
            name: translate({ id: 'CLIENT_SECURITY_SETTINGS' }),
        },
        {
            index: 1,
            link: routePaths.adminSecurityIpRestriction,
            name: translate({ id: 'CLIENT_SECURITY_SETTINGS' }),
        },
        {
            index: 1,
            link: routePaths.adminSecuritySessionPolicy,
            name: translate({ id: 'CLIENT_SECURITY_SETTINGS' }),
        },
        {
            index: 1,
            link: routePaths.adminSecurityEncryption,
            name: translate({ id: 'CLIENT_SECURITY_SETTINGS' }),
        },
        {
            index: 1,
            link: routePaths.adminSecurityAccessRestriction,
            name: translate({ id: 'CLIENT_SECURITY_SETTINGS' }),
        },
        {
            index: 2,
            link: routePaths.adminSecurityAuthenticationConfigureAAD,
            name: translate({ id: `CLIENT_AUTH_SETTINGS_CONFIGURE_AAD_TITLE` }),
        },
        {
            index: 2,
            link: routePaths.adminSecurityAuthenticationConfigureSAML,
            name: translate({ id: `CLIENT_AUTH_SETTINGS_CONFIGURE_SAML_TITLE` }),
        },
        {
            index: 2,
            link: routePaths.adminSecurityAuthenticationConfigureSAMLProvisioningRules,
            name: translate({ id: 'CLIENT_PAGE_SAML_PROVISIONING_RULES' }),
        },
        {
            index: 2,
            link: routePaths.adminSecurityAuthenticationAttributeMapping,
            name: translate({ id: 'CLIENT_ATTRIBUTE_MAPPING_2' }),
        },
        {
            index: 3,
            link: routePaths.adminSecurityAuthenticationConfigureSAMLProvisioningRulesAdd,
            name: translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_ADD_NEW_RULE' }),
        },
        {
            index: 3,
            link: routePaths.adminSecurityAuthenticationConfigureSAMLProvisioningRulesEdit,
            name: translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_EDIT_RULE' }),
        },
    ], [ organizationName, translate ]);

    return <BreadcrumbProvider breadcrumbs={breadCrumbLinks}>
        {children}
    </BreadcrumbProvider>;
};

const SecuritySettingsRouteProtector: React.FC<PropsWithChildren<{ type: SecuritySettingType }>> = ({
    children, type,
}) => {
    const EnableOrganizationAccessPolicy = !useFeatureFlagValue(Features.DisableFeatureFedRamp.name);
    const EnableAttributeMapping = useFeatureFlagValue(Features.EnableAttributeMapping.name);

    const accountType = useSelector(accountTypeSelector);

    const { isOffersRevampAndCommunity } = useCheckLicense();

    if (type === SecuritySettingEnum.AttributeMapping) {
        return <Suspense>
            {CheckGuard(
                EnableAttributeMapping
                    && (AccountLicense[accountType] <= AccountLicense.TRIAL || isOffersRevampAndCommunity),
                <AttributeMappingPageComponent />
            )}
        </Suspense>;
    }

    if (type === SecuritySettingEnum.IpRestriction) {
        return <>
            {CheckGuard(
                (AccountLicense[accountType] <= AccountLicense.TRIAL || isOffersRevampAndCommunity),
                <SecuritySettingsPageComponent
                    type={SecuritySettingEnum.IpRestriction}
                    position="left"
                />)}
            <UiSuspensefulOutlet />
        </>;
    }

    if (type === SecuritySettingEnum.SessionPolicy) {
        return <>
            {CheckGuard(
                (AccountLicense[accountType] <= AccountLicense.TRIAL || isOffersRevampAndCommunity),
                <SecuritySettingsPageComponent
                    type={SecuritySettingEnum.SessionPolicy}
                />)}
            <UiSuspensefulOutlet />
        </>;
    }

    if (type === SecuritySettingEnum.AccessRestriction) {
        return <>
            {CheckGuard(
                EnableOrganizationAccessPolicy,
                <SecuritySettingsPageComponent
                    type={SecuritySettingEnum.AccessRestriction}
                />)}
            <UiSuspensefulOutlet />
        </>;
    }

    return <>
        <SecuritySettingsPageComponent type={type} />
        <UiSuspensefulOutlet />
    </>;
};

export const SecurityRoutes: UiRouteObject = {
    path: routePaths.adminSecurity,
    element: <SecuritySettingsProvider>
        <UiSuspensefulOutlet />
    </SecuritySettingsProvider>,
    children: [
        {
            path: routePaths.adminSecurityAuthentication,
            redirectUrls: [ SecuritySettings, AuthSettingsDeprecated ],
            element: <SecuritySettingsRouteProtector type={SecuritySettingEnum.Authentication} />,
            children: [
                {
                    path: routePaths.adminSecurityAuthenticationConfigure,
                    redirectUrls: [ ConfigureSSO ],
                    element: <ConfigureSSOComponent />,
                },
                {
                    path: routePaths.adminSecurityAuthenticationPasswordPolicy,
                    redirectUrls: [ AuthSettingsPasswordPolicy ],
                    element: <EditPasswordPolicyComponent />,
                    explicitEnvironments: [ 'k8s' ],
                },
            ],
        },
        {
            path: routePaths.adminSecurityAuthenticationConfigureAAD,
            redirectUrls: [ SecuritySettingsConfigure.replace(':type', 'aad'), AuthSettingsDeprecatedConfigure ],
            element: <ConfigureSSOPage type="aad" />,
        },
        {
            path: routePaths.adminSecurityAuthenticationConfigureSAML,
            redirectUrls: [ SecuritySettingsConfigure.replace(':type', 'saml2') ],
            element: <ConfigureSSOPage type="saml2" />,
        },
        {
            path: routePaths.adminSecurityAuthenticationConfigureSAMLProvisioningRules,
            redirectUrls: [ SecuritySettingsSAMLProvisioningRules ],
            element: <SAMLProvisioningRulesComponent />,
        },
        {
            path: routePaths.adminSecurityAuthenticationConfigureSAMLProvisioningRulesAdd,
            redirectUrls: [ SecuritySettingsSAMLProvisioningRulesAdd.replace(':type', 'add') ],
            element: <CreateEditSAMLProvisioningRuleComponent type="add" />,
        },
        {
            path: routePaths.adminSecurityAuthenticationConfigureSAMLProvisioningRulesEdit,
            redirectUrls: [ SecuritySettingsSAMLProvisioningRulesEdit.replace(':type', 'edit') ],
            element: <CreateEditSAMLProvisioningRuleComponent type="edit" />,
        },
        {
            path: routePaths.adminSecurityAuthenticationAttributeMapping,
            redirectUrls: [ SecuritySettingsAttributeMapping ],
            element: <SecuritySettingsRouteProtector type={SecuritySettingEnum.AttributeMapping} />,
        },
        {
            path: routePaths.adminSecurityIpRestriction,
            redirectUrls: [ IPRestriction ],
            element: <SecuritySettingsRouteProtector type={SecuritySettingEnum.IpRestriction} />,
            explicitEnvironments: [ 'cloud' ],
            children: [
                {
                    path: routePaths.adminSecurityIpRestrictionAdd,
                    redirectUrls: [ IPRestrictionAdd.replace(':type', 'add') ],
                    element: <AddEditIPRestrictionComponent type="add" />,
                },
                {
                    path: routePaths.adminSecurityIpRestrictionEdit,
                    redirectUrls: [ IPRestrictionEdit.replace(':type', 'edit') ],
                    element: <AddEditIPRestrictionComponent type="edit" />,
                },
                {
                    path: routePaths.adminSecurityIpRestrictionBulkAdd,
                    redirectUrls: [ IPRestrictionBulkImport ],
                    element: <IPRestrictionBulkImportComponent />,
                },
            ],
        },
        {
            path: routePaths.adminSecuritySessionPolicy,
            redirectUrls: [ SessionPolicy ],
            element: <SecuritySettingsRouteProtector type={SecuritySettingEnum.SessionPolicy} />,
        },
        {
            path: routePaths.adminSecurityEncryption,
            redirectUrls: [ Encryption ],
            element: <SecuritySettingsRouteProtector type={SecuritySettingEnum.Encryption} />,
            children: [
                {
                    path: routePaths.adminSecurityEncryptionAdd,
                    redirectUrls: [ EncryptionConfigure.replace(':type', 'add') ],
                    element: <EncryptionConfigureComponent type="add" />,
                },
                {
                    path: routePaths.adminSecurityEncryptionEdit,
                    redirectUrls: [ EncryptionConfigure.replace(':type', 'edit') ],
                    element: <EncryptionConfigureComponent type="edit" />,
                },
            ],
        },
        {
            path: routePaths.adminSecurityAccessRestriction,
            redirectUrls: [ OrganizationAccessPolicy ],
            element: <SecuritySettingsRouteProtector type={SecuritySettingEnum.AccessRestriction} />,
            children: [
                {
                    path: routePaths.adminSecurityAccessRestrictionAdd,
                    redirectUrls: [ OrganizationAccessPolicyAdd ],
                    element: <AddOrganizationAccessPolicyMembersDrawerComponent />,
                },
            ],
        },
    ],
};
