import type { IGroup } from '../../common/interfaces/cis/group';
import { updateUserGroups } from '../../store/action/UserProfileAction';
import {
    axiosDelete,
    get,
    post,
    put,
} from '../utility/Requests';

export const groupUrl = `/api/identity/Group`;

export interface IGroupPayload {
    partitionGlobalId: string;
    name: string;
    id?: string;
}

export interface ICreateGroupPayload extends IGroupPayload {
    id: string;
    directoryUserMemberIDs: string[];
}

export interface IEditGroupPayload extends IGroupPayload {
    directoryUserIDsToAdd: string[];
    directoryUserIDsToRemove: string[];
}

export function getGroups({ partitionGlobalId }: { url: string; partitionGlobalId: string }) {
    return get<IGroup[]>(`${groupUrl}/${partitionGlobalId}`);
}

export function getGroupById({
    partitionGlobalId, id,
}: { url?: string; partitionGlobalId: string; id: string }) {
    return get<IGroup>(`${groupUrl}/${partitionGlobalId}/${id}`);
}

export function getGroupsWithLicenses({ partitionGlobalId }: { url?: string; partitionGlobalId: string }) {
    return get<IGroup[]>(`${groupUrl}/${partitionGlobalId}/licenses`, { urlParams: { partitionGlobalId } });
}

export async function createGroup(payload: ICreateGroupPayload, isCurrentUser: boolean) {
    const group = await post<IGroup>(groupUrl, { body: payload });

    if (group && isCurrentUser) {
        await updateUserGroups('create', [ group.id ]);
    }

    return group;
}

export async function editGroup(
    id: string,
    payload: IEditGroupPayload,
    user: { type: 'create' | 'delete'; isCurrentUser: boolean },
) {
    const group = await put<IGroup>(`${groupUrl}/${id}`, { body: payload });

    if (group && user.isCurrentUser) {
        await updateUserGroups(user.type, [ group.id ]);
    }

    return group;
}

export async function deleteGroups(partitionGlobalId: string, payload: { groupIDs: string[] }, isCurrentUser: boolean) {
    await axiosDelete(`${groupUrl}/${partitionGlobalId}`, { body: payload });

    if (isCurrentUser) {
        await updateUserGroups('delete', payload.groupIDs);
    }
}

export function checkIfOnlyUserInGroup({
    partitionGlobalId, userGlobalId, groupId,
}: { url?: string; partitionGlobalId: string; userGlobalId: string; groupId?: string }) {
    return get<boolean>(`${groupUrl}/${partitionGlobalId}/${groupId}/checkIfOnlyUserInGroup`, { urlParams: { userGlobalId } });
}
