import { Entity } from '@experiences/interfaces';
import { UiSuspensefulOutlet } from '@experiences/ui-common';
import type { PropsWithChildren } from 'react';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { Users } from '../../common/constants/RouteNames';
import { routePaths } from '../../common/constants/routePaths';
import BreadcrumbProvider from '../../common/providers/BreadcrumbProvider';
import type { UiRouteObject } from '../../container/routeConfigs/UiRouteObject';

const UsersPageComponent = React.lazy(() => import('./UsersPageComponent'));
const AddEditUserComponent = React.lazy(() => import('./subcomponents/AddEditUserComponent'));

const HostIdentitiesProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { formatMessage: translate } = useIntl();

    const breadCrumbLinks = useMemo(() => [
        {
            index: 0,
            link: routePaths.admin,
            name: translate({ id: 'CLIENT_HOST' }),
        },
        {
            index: 1,
            link: routePaths.adminHostIdentitiesUsers,
            name: translate({ id: 'CLIENT_USERS' }),
        },
    ], [ translate ]);

    return <BreadcrumbProvider breadcrumbs={breadCrumbLinks}>
        {children}
    </BreadcrumbProvider>;
};

export const HostIdentitiesRoutes: UiRouteObject = {
    path: routePaths.adminHostIdentities,
    element: <HostIdentitiesProvider>
        <UiSuspensefulOutlet />
    </HostIdentitiesProvider>,
    children: [
        {
            path: routePaths.adminHostIdentitiesUsers,
            redirectUrls: [ Users ],
            element: <>
                <UsersPageComponent entityType={Entity.USERS} />
                <UiSuspensefulOutlet />
            </>,
            children: [
                {
                    path: routePaths.adminHostIdentitiesUsersAdd,
                    redirectUrls: [ `${Users}/add` ],
                    element: <AddEditUserComponent type="add" />,
                },
                {
                    path: routePaths.adminHostIdentitiesUsersEdit,
                    redirectUrls: [ `${Users}/edit` ],
                    element: <AddEditUserComponent type="edit" />,
                },
            ],
        },
    ],
};

