import type { ITenant } from '@experiences/interfaces';
import { get } from '@experiences/util';

export const organizationManagementTenantUri = '/api/organization/tenant';

export function getTenants(organizationGuid: string, accessToken: string, accountLogicalName: string, visibleTenants: boolean = false) {
    return get<ITenant[]>(organizationManagementTenantUri, {
        accessToken,
        urlParams: {
            organizationGuid,
            accountName: accountLogicalName,
            includeTenantServices: true,
            visibleTenants,
        },
    });
}
