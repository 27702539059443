import { GlobalStyles } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { useIntl } from 'react-intl';

import * as RouteNames from '../../../common/constants/RouteNames';
import useIsOnPrem from '../../../common/hooks/useIsOnPrem';
import { UiDropdownButton } from '../../common/UiDropdownButton/UiDropdownButton';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        title: {
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '24px',
            color: theme.palette.semantic.colorForeground,
            marginBottom: '16px',
        },
        header: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
    }),
}));

const FreeTrialsHeader: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    return <>
        <UiText className={classes.title}>
            {translate({ id: 'CLIENT_FREE_TRIALS' })}
        </UiText>
    </>;
};

const FreeTrialsHeaderK8s: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();
    return <div className={classes.header}>
        <UiText className={classes.title}>
            {translate({ id: 'CLIENT_FREE_TRIALS' })}
        </UiText>
        <UiDropdownButton
            label={translate({ id: 'CLIENT_ACTIVATE_TRIAL_LICENSE' })}
            menuItems={[
                {
                    onClick: () => {
                        navigate(getRoute(RouteNames.LicensingActivateLicenseOnline),
                            {
                                state: {
                                    previousLocation: location.pathname,
                                    isUpdateLicense: false,
                                    isEnterpriseActivation: false,
                                    isTrialPerSkuActivation: true,
                                },
                            });
                    },
                    label: translate({ id: 'CLIENT_ONLINE_ACTIVATION' }),
                },
                {
                    onClick: () => {
                        navigate(getRoute(RouteNames.ActivateOffline),
                            {
                                state: {
                                    previousLocation: location.pathname,
                                    isUpdateLicense: false,
                                    isTrialPerSkuActivation: true,
                                },
                            });
                    },
                    label: translate({ id: 'CLIENT_OFFLINE_ACTIVATION' }),
                },
            ]}
        />
    </div>;
};

export default (props: any) => {
    const isOnPrem = useIsOnPrem();

    return isOnPrem ? <FreeTrialsHeaderK8s {...props} /> : <FreeTrialsHeader {...props} />;
};
