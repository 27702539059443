import { entitlementsToLegacyCodes } from '@experiences/constants';
import { useGetErrorInfo } from '@experiences/error';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import type {
    IProductAllocation,
    ITabEntitlementUsage,
} from '@experiences/interfaces';
import { UiText } from '@experiences/ui-common';
import {
    getSortedEntitlementUsageConfigurations,
    getSortedRobotsAndServicesProductConfigurations,
    getSortedUserBundleConfigurations,
} from '@experiences/util';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import sortBy from 'lodash/sortBy';
import React, {
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import {
    useDispatch,
    useSelector,
} from 'react-redux';
import { useLocation } from 'react-router-dom';
import useSWR from 'swr';

import {
    CONSUMABLES_PRODUCTS,
    dashboardLicenses,
    LegacyProductsToUserBundleLicenseMap,
} from '../../common/constants/Constant';
import {
    entitlementsUrl,
    isEntitledMultiple,
} from '../../services/licensing/EntitlementsService';
import { getTenantProductAllocations } from '../../services/licensing/LicenseService';
import { getRobotsAndServices } from '../../services/licensing/management/AccountService';
import { setUnlicensedState } from '../../store/action/UserProfileAction';
import {
    accountGlobalId,
    accountType,
    isHostModeSelector,
    userGlobalId,
} from '../../store/selectors';
import {
    computeProductProperties,
    extractEntitlementUsages,
    extractProducts,
} from '../../util/LicenseUtil';
import RobotUsage from '../usage/helpers/RobotUsage';
import { UiChartSection } from './helperComponents/UiConsumableCard/UiChartSection';
import { HomePageLicenseAllocations } from './HomePageLicenseAllocations';
import { LicensingProductAllocations } from './LicensingProductAllocations';
import { generateRobotsAndServicesLicenesFetchKey } from './subcomponents/LicensingDataFetchKeyGenerators';
import LoadingStateSkeletonComponent from './subcomponents/LoadingStateSkeletonComponent';

const useStyles = makeStyles(theme =>
    createStyles({
        parent: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        product: { flex: '50%' },
        content: {
            display: 'flex',
            minHeight: '80px',
            justifyContent: 'space-between',
        },
        subTitle: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontWeight: 600,
            marginTop: '24px',
            marginBottom: '8px',
        },
        noLicensesAvailable: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontWeight: 600,
            marginTop: '24px',
            marginLeft: '24px',
            marginBottom: '6px',
        },
    }),
);

const provisionWithEntitlementToFeatureFlagNameMap: { [key: string]: string } = {
    'Insights.FullMode': Features.EnableProvisionWithEntitlementsInsights.name,
    'AutomationHub.Enabled': Features.EnableProvisionWithEntitlementsAutomationHub.name,
    'ProcessMining.Enabled': Features.EnableProvisionWithEntitlementsProcessMining.name,
    'TestManager.Enabled': Features.EnableProvisionWithEntitlementsTestManager.name,
};

function getProvisionWithEntitlementsFeatureFlags() {
    return Object.keys(entitlementsToLegacyCodes)
        .map(product => ({
            product,
            featureFlagValue: useFeatureFlagValue(provisionWithEntitlementToFeatureFlagNameMap[product]) as boolean,
        }));
}

export const ViewLicensesPanel: React.FC<{
    selectedTab: string;
    isHome?: boolean;
    useLegacyProducts?: boolean;
    isServiceMode?: boolean;
    tenantId?: string;
    tenantIds?: string[];
    enabledConsumablesTab?: boolean;
}> = ({
    selectedTab, isHome = false, useLegacyProducts = false, isServiceMode = false, tenantId = '', tenantIds, enabledConsumablesTab = false,
}) => {
    const serviceEntitlementToFeatureFlagValueMap = getProvisionWithEntitlementsFeatureFlags();
    const enableRobotUnits = useFeatureFlagValue(Features.EnableRobotUnits.name);
    const enableApiCalls = useFeatureFlagValue(Features.EnableApiCallsUsage.name);
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const location = useLocation();
    const isHostMode = useSelector(isHostModeSelector);
    const isGov = useFeatureFlagValue(Features.DisableFeatureFedRamp.name);
    const accountTypeSelector = useSelector(accountType);
    const userId = useSelector(userGlobalId);
    const accountId = useSelector(accountGlobalId);
    const dispatch = useDispatch();
    const { getErrorObject } = useGetErrorInfo();
    const autodetectWhenLicenseIsRemovedByHostAdmin = window.env.autodetectWhenLicenseIsRemovedByHostAdmin;

    const robotsAndServicesLicenesFetchKey = useMemo(
        () => generateRobotsAndServicesLicenesFetchKey(isServiceMode, tenantId),
        [ isServiceMode, tenantId ]);

    const {
        data: licenses,
        isValidating,
        error: requestError,
        mutate,
    } = useSWR(
        robotsAndServicesLicenesFetchKey ? {
            url: robotsAndServicesLicenesFetchKey,
            userGlobalId: userId,
            accountGlobalId: accountId,
            tenantGlobalId: tenantId,
        } : null,
        ({
            url, userGlobalId, accountGlobalId, tenantGlobalId,
        }: {
            url: string;
            userGlobalId: string;
            accountGlobalId: string;
            tenantGlobalId: string;
        }) =>
            isServiceMode
                ? getTenantProductAllocations({
                    url,
                    accountGlobalId,
                    tenantGlobalId,
                })
                : getRobotsAndServices({
                    url,
                    accountUserId: userGlobalId,
                    accountGlobalId,
                }),
    );

    const { data: hasEntitlements } = useSWR(
        {
            url: entitlementsUrl,
            entitlements: Object.keys(entitlementsToLegacyCodes),
        },
        isEntitledMultiple,
    );

    const entitlementProductAllocations = useMemo(() => {
        let productAllocations = licenses?.productAllocations ?? [];
        if (hasEntitlements?.isEntitled) {
            Object.entries(hasEntitlements.isEntitled).forEach(([ key, value ]) => {
                if (!serviceEntitlementToFeatureFlagValueMap.filter(p => p.product === key)[0].featureFlagValue) {
                    return;
                }
                productAllocations = productAllocations.filter(p => p.code !== entitlementsToLegacyCodes[key]);
                if (value && entitlementsToLegacyCodes[key]) {
                    productAllocations.push({
                        code: entitlementsToLegacyCodes[key],
                        allocated: 1,
                        total: 1,
                    });
                }
            });
        }
        return productAllocations;
    }, [ hasEntitlements, licenses?.productAllocations ]);

    useEffect(() => {
        if (location.state && (location.state as any)['refresh']) {
            mutate();
        }
    }, [ location, mutate ]);

    useEffect(() => {
        (async () => {
            if (!isValidating && requestError) {
                const errorObject = await getErrorObject(requestError);
                if (
                    errorObject.response?.status === 404 &&
                    !isServiceMode &&
                    autodetectWhenLicenseIsRemovedByHostAdmin
                ) {
                    dispatch(setUnlicensedState());
                }
            }
        })();
    }, [
        isValidating,
        requestError,
        getErrorObject,
        isServiceMode,
        autodetectWhenLicenseIsRemovedByHostAdmin,
        dispatch,
    ]);

    const mapLegacyProducts = useCallback((productAllocations: IProductAllocation[]) => {
        const legacyProducts = productAllocations
            .filter(p => (p.allocated > 0 || p.total > 0) && !!LegacyProductsToUserBundleLicenseMap[p.code])
            .map(product => ({
                ...product,
                code: LegacyProductsToUserBundleLicenseMap[product.code],
            }));

        return sortBy(legacyProducts, product =>
            Object.values(LegacyProductsToUserBundleLicenseMap).indexOf(product.code),
        );
    }, []);

    const productsToRender = useMemo(() => {
        const mlKeys = licenses?.mlKeys;
        const productAllocations =
            selectedTab === dashboardLicenses.ROBOTS_AND_SERVICES || useLegacyProducts
                ? entitlementProductAllocations
                : licenses?.userLicensingBundles;

        if (useLegacyProducts && productAllocations) {
            return mapLegacyProducts(productAllocations);
        }

        if (!productAllocations || (!mlKeys && !isServiceMode)) {
            return undefined;
        }

        const productConfigs =
            selectedTab === dashboardLicenses.USERS
                ? getSortedUserBundleConfigurations()
                : getSortedRobotsAndServicesProductConfigurations();

        // map product allocations to product configurations by `code`
        let foundProducts = extractProducts(productConfigs, productAllocations, isServiceMode, isHome);

        if (selectedTab !== dashboardLicenses.USERS && enabledConsumablesTab && (!isHome || isServiceMode)) {
            foundProducts = foundProducts.filter(p => !CONSUMABLES_PRODUCTS.includes(p.code));
        }

        return selectedTab === dashboardLicenses.USERS
            ? foundProducts
            : foundProducts.map(p => computeProductProperties(p, isHostMode, mlKeys, accountTypeSelector, isGov));
    }, [
        licenses?.mlKeys,
        entitlementProductAllocations,
        licenses?.userLicensingBundles,
        selectedTab,
        useLegacyProducts,
        isServiceMode,
        isHome,
        isGov,
        enabledConsumablesTab,
        mapLegacyProducts,
        isHostMode,
        accountTypeSelector,
    ]);

    const entitlementUsagesToRender = useMemo(() => {
        const orderedEntitlementUsages = licenses?.entitlementUsages ?
            extractEntitlementUsages(getSortedEntitlementUsageConfigurations(), licenses?.entitlementUsages)
            : [] ;

        if (selectedTab === dashboardLicenses.ROBOTS_AND_SERVICES && enabledConsumablesTab && !isHome) {
            return [] as ITabEntitlementUsage[];
        }

        return selectedTab === dashboardLicenses.ROBOTS_AND_SERVICES ?
            orderedEntitlementUsages
            : [] as ITabEntitlementUsage[];
    }, [ enabledConsumablesTab, isHome, licenses?.entitlementUsages, selectedTab ]);

    const hasDocumentUnderstandingLicense = useMemo(() => productsToRender ? productsToRender.some(p => p.code === 'TIE') : false, [ productsToRender ]);

    const hasRobotUnitsLicense = useMemo(() => productsToRender ? enableRobotUnits && productsToRender.some(p => p.code === 'RU') : false, [ productsToRender, enableRobotUnits ]);

    const hasAiUnitsLicense = useMemo(() => productsToRender ? productsToRender.some(p => p.code === 'AIU') : false, [ productsToRender ]);

    const showLicenseUsage = useMemo(() =>
        hasRobotUnitsLicense || hasDocumentUnderstandingLicense || hasAiUnitsLicense, [ hasDocumentUnderstandingLicense, hasRobotUnitsLicense, hasAiUnitsLicense ]);

    return productsToRender ? (
        productsToRender.length === 0 && entitlementUsagesToRender.length === 0 ? (
            <UiText className={classes.noLicensesAvailable}>
                {translate({ id: 'CLIENT_NO_LICENSE_AVAILABLE' })}
            </UiText>
        ) : (
            <div>
                {isHome ?
                    <HomePageLicenseAllocations
                        products={productsToRender}
                        entitlementUsages={entitlementUsagesToRender}
                    /> :
                    <LicensingProductAllocations
                        products={productsToRender}
                        entitlementUsages={entitlementUsagesToRender}
                        loading={isValidating} />}
                {selectedTab === dashboardLicenses.ROBOTS_AND_SERVICES && (
                    <div>
                        { showLicenseUsage && (
                            <>
                                <UiText className={classes.subTitle}>
                                    {translate({ id: 'CLIENT_USAGE_TITLE' })}
                                </UiText>
                                { hasDocumentUnderstandingLicense && (
                                    <RobotUsage
                                        tenantIds={tenantIds}
                                        isServiceMode={isServiceMode}
                                        productsToDisplay={[ 'TIE' ]}
                                    />
                                )}
                                { hasAiUnitsLicense && !enabledConsumablesTab && (
                                    <RobotUsage
                                        tenantIds={tenantIds}
                                        isServiceMode={isServiceMode}
                                        productsToDisplay={[ 'AIU' ]}
                                    />
                                )}
                                { hasRobotUnitsLicense && !enabledConsumablesTab && (
                                    <RobotUsage
                                        tenantIds={tenantIds}
                                        isServiceMode={isServiceMode}
                                        productsToDisplay={[ 'RU' ]}
                                    />
                                )}
                            </>
                        )}
                        { !isServiceMode && !enabledConsumablesTab && enableApiCalls && (
                            <UiChartSection product='APICALLS' />)}
                    </div>
                )}
            </div>
        )
    ) : (<LoadingStateSkeletonComponent
        childGridColumns={6}
        childGridElements={(selectedTab === dashboardLicenses.USERS ? [ 1, 2 ] : [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ])} />);
};
