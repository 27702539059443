import { UiSuspensefulOutlet } from '@experiences/ui-common';
import type { PropsWithChildren } from 'react';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
    ActivateOffline,
    DeactivateOffline,
    Licensing,
    LicensingActivateLicenseOnline,
} from '../../common/constants/RouteNames';
import { routePaths } from '../../common/constants/routePaths';
import useCheckLicense from '../../common/hooks/useCheckLicense';
import BreadcrumbProvider from '../../common/providers/BreadcrumbProvider';
import type { UiRouteObject } from '../../container/routeConfigs/UiRouteObject';
import ActivateLicenseOfflineComponent from './subcomponents/ActivateLicenseOfflineComponent';

const LicensesComponent = React.lazy(() => import('./Licenses'));
const EcommerceMonthlyUpsellComponent = React.lazy(() => import('../ecommerce/EcommerceMonthlyUpsellComponent'));
const ActivateLicenseOnlineComponent = React.lazy(() => import('./subcomponents/ActivateLicenseOnlineComponent'));
const DeactivateLicenseOfflineComponent = React.lazy(() => import('./subcomponents/DeactivateLicenseOfflineComponent'));

const HostLicensesProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { formatMessage: translate } = useIntl();

    const breadCrumbLinks = useMemo(() => [
        {
            index: 0,
            link: routePaths.admin,
            name: translate({ id: 'CLIENT_HOST' }),
        },
        {
            index: 1,
            link: routePaths.adminHostLicense,
            name: translate({ id: 'CLIENT_LICENSES' }),
        },
    ], [ translate ]);

    return <BreadcrumbProvider breadcrumbs={breadCrumbLinks}>
        {children}
    </BreadcrumbProvider>;
};

const HostLicensesComponent: React.FC = () => {
    const { isPro } = useCheckLicense();

    return isPro ? <EcommerceMonthlyUpsellComponent /> : <ActivateLicenseOnlineComponent />;
};

export const HostLicenseRoutes: UiRouteObject = {
    path: routePaths.adminHostLicense,
    element: <HostLicensesProvider>
        <UiSuspensefulOutlet />
    </HostLicensesProvider>,
    children: [
        {
            path: routePaths.adminHostLicense,
            redirectUrls: [ Licensing ],
            element: <>
                <LicensesComponent />
                <UiSuspensefulOutlet />
            </>,
            children: [
                {
                    path: routePaths.adminHostLicenseActivateOffline,
                    redirectUrls: [ ActivateOffline ],
                    element: <ActivateLicenseOfflineComponent />,
                },
                {
                    path: routePaths.adminHostLicenseActivateOnline,
                    redirectUrls: [ LicensingActivateLicenseOnline ],
                    element: <HostLicensesComponent />,
                },
                {
                    path: routePaths.adminHostLicenseDeactivateOffline,
                    redirectUrls: [ DeactivateOffline ],
                    element: <DeactivateLicenseOfflineComponent />,
                },
            ],
        },
    ],
};
