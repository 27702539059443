import {
    get,
    post,
} from '../utility/Requests';

export const AX_DIRECTORY_ID = 'd4b048e8-2e23-49c1-975f-f741073d08a4';
export enum ORCH_ROLE {
    ALLOW_TO_BE_AUTOMATION_USER_ROLE = 'Allow to be Automation User',
}
const ORCH_URL = '/api/orchestrator/roles';

export async function getOrchRoleExistsForGroup(urlParams: { groupId: string; tenantId?: string; role: string }) {
    const res = await get<string>(ORCH_URL, { urlParams });
    return res;
}

export async function setOrchRoleForGroup(body: { groupId: string; tenantId?: string; role: string }) {
    return await post<string[]>(ORCH_URL, { body });
}
