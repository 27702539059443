import { useFeatureFlagValue } from '@experiences/feature-flags';
import type { PropsWithChildren } from 'react';
import React from 'react';

import InvalidUrl from '../../component/authentication/InvalidUrl';

export const CheckGuardComponent: React.FC<PropsWithChildren<{ tester?: ((...args: any[]) => boolean) | boolean; flag?: string }>> = ({
    tester, flag, children,
}) => {
    const flagValue = useFeatureFlagValue(flag ?? '');

    if (flagValue) {
        return children;
    }

    if (typeof tester === 'function') {
        return tester() ? children : <InvalidUrl />;
    }

    return tester ? children : <InvalidUrl />;
};
