import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useGetRoutes } from '@experiences/util';
import React, {
    useEffect,
    useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { type RouteObject } from 'react-router-dom';

import * as RouteNames from '../../common/constants/RouteNames';
import useCheckLicense from '../../common/hooks/useCheckLicense';
import useIsOnPrem from '../../common/hooks/useIsOnPrem';
import InvalidUrl from '../../component/authentication/InvalidUrl';
import { HomeRoutes } from '../../component/home/HomeRoutes';
import { HostRoutes } from '../../component/HostRoutes';
import NotificationPageComponent from '../../component/notifications/NotificationPageComponent';
import { OrgMigrationPage } from '../../component/organizationsettings/subcomponents/OrgMigration/OrgMigrationPage';
import { ProfileRoutes } from '../../component/profile/ProfileRoutes';
import { ResourceCenterRoutes } from '../../component/resourceCenter/ResourceCenterRoutes';
import { PrivateRootRoutes } from '../../component/RootRoutes';
import {
    isAdminSelector,
    isHostModeSelector,
} from '../../store/selectors';
import CheckGuard from '../helpers/CheckGuard';
import {
    flattenRedirectUrls,
    recursiveRouteReplace,
} from './routeHelpers';
import type { UiRouteObject } from './UiRouteObject';
import { useEcommerceRoutesDefault } from './useEcommerceRoutesDefault';
import { useEcommerceRoutesK8s } from './useEcommerceRoutesK8s';

const usePrivateShellRoutes = () => {
    const EnabledScheduledOrgMigration = useFeatureFlagValue(Features.EnabledScheduledOrgMigration.name);

    const isHostMode = useSelector(isHostModeSelector);
    const isAdmin = useSelector(isAdminSelector);
    const isOnPrem = useIsOnPrem();
    const { isFreeOrCommunityRevamp } = useCheckLicense();
    const ecommerceRoutes = useEcommerceRoutesDefault();
    const ecommerceRoutesK8s = useEcommerceRoutesK8s();

    const { setRoutes } = useGetRoutes();

    useEffect(() => {
        setRoutes([
            ProfileRoutes,
            ...(isAdmin && !isHostMode ? [ PrivateRootRoutes ] : []),
            ...(isHostMode ? [ HostRoutes ] : [ HomeRoutes, ResourceCenterRoutes ]),
        ]);
    }, [ isAdmin, isHostMode, setRoutes ]);

    return useMemo(() => {
        const nestedRoutes = [
            ...(isAdmin && !isHostMode ? [ PrivateRootRoutes ] : []),
            ...(isHostMode
                ? [ HostRoutes ]
                : [
                    HomeRoutes,
                    ResourceCenterRoutes,
                ]),
            ProfileRoutes,
            {
                path: RouteNames.Notifications,
                element: <NotificationPageComponent />,
            },
            {
                path: RouteNames.OrganizationMigration,
                element:
                CheckGuard(!isFreeOrCommunityRevamp && EnabledScheduledOrgMigration, <OrgMigrationPage />),
            },
            ...(isAdmin ? (isOnPrem ? ecommerceRoutesK8s : ecommerceRoutes) : []),
            {
                path: '*', // Fallback for invalid urls
                element: <InvalidUrl />,
            },
        ] satisfies UiRouteObject[];

        const flattenedRoutes = flattenRedirectUrls(nestedRoutes);
        const fullRoutes = [
            ...nestedRoutes,
            ...flattenedRoutes,
        ] satisfies UiRouteObject[];
        const relativeRoutes = recursiveRouteReplace(fullRoutes, RouteNames.PortalPrefix);
        return relativeRoutes as RouteObject[];
    }, [
        EnabledScheduledOrgMigration,
        ecommerceRoutes,
        ecommerceRoutesK8s,
        isAdmin,
        isFreeOrCommunityRevamp,
        isHostMode,
        isOnPrem,
    ]);
};

export default usePrivateShellRoutes;
