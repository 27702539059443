import type { Persona } from '@experiences/interfaces';

import type { slidesData } from '../common/interfaces/slide';
import { get } from './utility/Requests';

export const carouselURL = '/api/content/carousel/getCarouselCMSData';

export async function getContentfulResource({
    url: _url, language, dominantPersona,
}: { url: string; language: string; dominantPersona: Persona }) {
    return get<slidesData>(carouselURL, {
        urlParams: {
            language,
            dominantPersona,
        },
    });
}
