import type {
    TMProject,
    WidgetContext,
    WidgetItem,
    WidgetTemplate,
} from '@experiences/interfaces';

import { getTMProjects } from '../services/getTMProjects';

export const TMProjectsWidget: WidgetTemplate<TMProject> = {
    name: 'TMProjects',
    titleKey: 'CLIENT_TESTMANAGER_PROJECTS',
    emptyMsgKey: () => 'CLIENT_NO_TESTMANAGER_PROJECTS',
    cardRows: 2,
    cardHeight: 102,
    fetch: async (ctx: WidgetContext) => {
        const projects = await getTMProjects(ctx.accessToken, ctx.tenant);
        return Promise.resolve(projects.data);
    },
    type: 'Card',
    transform: (data: TMProject[]): Array<WidgetItem<TMProject>> => data.map(project => ({
        data: project,
        clickable: true,
        header: project.name,
        description: '',
        category: project.projectPrefix,
        isSubtitleTime: true,
        subtitleTimePrefixKey: 'CLIENT_LAST_UPDATED',
        subtitle: project.updated,
    })),
    clickHandler: (data: TMProject, ctx: WidgetContext) => {
        window.location.assign(getTestManagerProjectUrl(ctx, data));
    },
    emptyStateButtonHandler: (ctx: WidgetContext) => {
        window.location.assign(`${window.location.origin}/${ctx.accountLogicalName}/${ctx.tenant}/testmanager_`);
    },
};

export function getTestManagerProjectUrl(ctx: WidgetContext, data: TMProject): string {
    return `${window.location.origin}/${ctx.accountLogicalName}/${ctx.tenant}/testmanager_/${data.projectPrefix}/dashboard`;
}
