import { AccountLicense } from '@experiences/constants';
import { TenantEnvironment } from '@experiences/interfaces';
import { TenantsEvent } from '@experiences/telemetry';
import { GlobalStyles } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import LanguageIcon from '@mui/icons-material/Language';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import UnfoldMore from '@mui/icons-material/UnfoldMore';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import {
    createStyles,
    makeStyles,
    useTheme,
} from '@mui/styles';
import { ApTooltip } from '@uipath/portal-shell-react';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useSWR from 'swr';

import * as RouteNames from '../../common/constants/RouteNames';
import useCheckLicense from '../../common/hooks/useCheckLicense';
import { useEnabledTenantOperations } from '../../common/hooks/useEnabledTenantOperations';
import type { ITenant } from '../../common/interfaces/tenant/tenant';
import { ReactComponent as HostTenantShellIcon } from '../../images/hostTenantShell.svg';
import { ReactComponent as OrgTenantShellIcon } from '../../images/orgTenantShell.svg';
import {
    getAllOnPremOrganizations,
    onPremOrganizationManagementUri,
} from '../../services/organization/OnPremOrganizationService';
import {
    accountType,
    isAdminSelector,
    isHostModeSelector,
    isOrgAdminSelector,
    profile,
} from '../../store/selectors';
import { UiUpgradePopOver } from '../common/UiUpgradeChip/UiUpgradePopOver';
import { TenantStatusConstants } from '../tenants/TenantConstants';
import { useTenantOperationTrackerContext } from '../tenants/TenantOperationTrackerContextProvider';
import { useTenantsContext } from '../tenants/TenantsContextProvider';

const useStyles = makeStyles(theme =>
    createStyles({
        ...GlobalStyles(theme),
        tenantShellContainer: {
            borderRight: `1px solid ${theme.palette.semantic.colorBorderDeEmp}`,
            height: '100%',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            justifyContent: 'space-between',
            minWidth: '312px',
            maxWidth: '312px',
            '@media all and (max-width: 1150px)': {
                minWidth: '175px',
                maxWidth: '175px',
            },
        },
        tenantActionBar: {
            display: 'flex',
            margin: '18px 10px 0 20px',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        tenantActionHeader: {
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '20px',
            color: theme.palette.semantic.colorForeground,
        },
        list: {
            background: theme.palette.semantic.colorBackground,
            '&[role="menu"]': { boxShadow: 'none' },
        },
        listRoot: {
            paddingTop: '0px',
            paddingBottom: '0px',
        },
        tenantList: {
            overflowY: 'auto',
            overflowX: 'hidden',
            height: '100%',
        },
        menuItem: {
            padding: '6px 20px',
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.semantic.colorForeground,
        },
        orgMenuItem: { height: '48px' },
        tenantMenuItem: { height: '32px' },
        tenantSpacing: { justifyContent: 'space-between' },
        tenantIcon: { marginRight: '8px' },
        filler: { height: '100%' },
        footer: {
            fontSize: '14px',
            marginBottom: '4px',
            padding: '24px 36px',
        },
        supportText: { fontWeight: 600 },
        footerSpacing: { marginBottom: '12px' },
        chip: {
            fontSize: '12px',
            fontWeight: 600,
            backgroundColor: `${theme.palette.semantic.colorInfoBackground} !important`,
        },
        addTenant: { '&.Mui-disabled': { pointerEvents: 'auto' } },
        orgIcon: { margin: '0 8px 2px 0' },
        tenantShellHeader: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        upgradeChip: { display: 'visible' },
        orgName: {
            flex: 1,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
        chipSpacer: { marginLeft: '16px' },
        '@media all and (max-width: 1800px)': { upgradeChip: { display: 'none' } },
    }),
);

const TenantShell: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const navigate = useNavigateWithParams();
    const location = useLocation();
    const getRoute = useRouteResolver();

    const theme = useTheme();
    const { includesLicense } = useCheckLicense();

    const [ searching, setSearching ] = useState(false);
    const [ query, setQuery ] = useState('');
    const [ active, setActive ] = useState('org');
    const [ anchorEl, setAnchorEl ] = useState<HTMLElement | null>(null);

    const isAdmin = useSelector(isAdminSelector);
    const isHost = useSelector(isHostModeSelector);
    const accountProfile = useSelector(profile);
    const licensePlan = useSelector(accountType);

    const isOrgAdmin = useSelector(isOrgAdminSelector);

    const { licenseCode: supportId } = useMemo(() => accountProfile.accountUserDto, [ accountProfile ]);

    const tenantId = useMemo(() => {
        const tenantUrlRegex = isHost
            ? /\/(?:organization|service)\/([\w-]+)?(?:\/|$)/
            : /\/(?:tenant|service)\/([\w-]+)?(?:\/|$)/;
        return location.pathname.match(tenantUrlRegex);
    }, [ isHost, location.pathname ]);

    const {
        data, tenantsWithoutServices, isLoading,
    } = useTenantsContext();

    const { canAddTenant } = useEnabledTenantOperations(data, isAdmin, isHost);
    const { checkOperationList } = useTenantOperationTrackerContext();

    const { data: organizations } = useSWR(
        isHost ? { url: `${onPremOrganizationManagementUri}/getAllOrganizations` } : null,
        getAllOnPremOrganizations,
    );

    const { companyName } = useMemo(() => accountProfile.accountUserDto, [ accountProfile ]);

    const addOrganizationTooltip = useMemo(() => translate({ id: 'CLIENT_CREATE_NEW_ORG_TENANT_SHELL' }), [ translate ]);

    const createTenantToolTip = useMemo(() => {
        if (!isHost && !canAddTenant && AccountLicense[licensePlan] === AccountLicense.ENTERPRISE) {
            return translate({ id: 'CLIENT_MAX_TENANTS_REACHED' });
        }
        return isHost ? addOrganizationTooltip : translate({ id: 'CLIENT_TENANT_CREATE' });
    }, [ addOrganizationTooltip, canAddTenant, isHost, licensePlan, translate ]);

    const tenantUpgradeLicenseType = useMemo(() => {
        if (includesLicense([ AccountLicense.FREEKIT, AccountLicense.COMMUNITY ])) {
            return AccountLicense.PRO;
        } else if (includesLicense([ AccountLicense.PRO, AccountLicense['PRO-TRIAL'] ])) {
            return AccountLicense.ENTERPRISE;
        }
        return undefined;
    }, [ includesLicense ]);

    const filteredTenants = useMemo(() => query
        ? tenantsWithoutServices.filter(tenant => tenant.name.toLowerCase().includes(query.toLowerCase()))
        : tenantsWithoutServices, [ query, tenantsWithoutServices ]);

    const filteredOrganizations = useMemo(() => query
        ? organizations?.results.filter(tenant => tenant.name.toLowerCase().includes(query.toLowerCase()))
        : organizations?.results, [ organizations?.results, query ]);

    const tenantChipMessage = useCallback((tenant: ITenant) => {
        if (tenant.status.toLowerCase() === TenantStatusConstants.DISABLED.toLowerCase()) {
            return 'CLIENT_DISABLED';
        }
        if (tenant.isCanaryTenant) {
            return 'CLIENT_CANARY_TENANT';
        }
        return '';
    }
    , []);

    const noResults = useMemo(() =>
        tenantsWithoutServices.length > 0 ? <UiText
            className={clsx(classes.tenantSpacing, classes.menuItem)}
            data-cy="tenant-shell-no-match">
            {translate({ id: 'CLIENT_PEOPLE_PICKER_NO_OPTIONS' }, { query })}
        </UiText> : <></>
    , [ classes.menuItem, classes.tenantSpacing, query, tenantsWithoutServices.length, translate ]);

    const resetSearch = useCallback(() => {
        setQuery('');
        setSearching(false);
    }, []);

    const handleMenuItemClick = useCallback((option: string, shouldUpdateShell = true) => {
        setActive(option);
        resetSearch();

        if (option === 'org') {
            navigate(getRoute(RouteNames.OrganizationAdminHome));
        } else {
            if (shouldUpdateShell) {
                window.PortalShell?.AccountAndTenants.updateCurrentTenant(option);
            }

            if (isHost) {
                navigate(getRoute(RouteNames.OrganizationHome.replace(':organizationId', option)));
            } else {
                if (!tenantId?.length) {
                    navigate(getRoute(RouteNames.TenantHome.replace(':tenantId', option)));
                } else {
                    navigate(location.pathname.replace(tenantId[1], option));
                }
            }
        }
    }, [ getRoute, isHost, location.pathname, navigate, resetSearch, tenantId ]);

    useEffect(() => {
        if (tenantId?.length) {
            setActive(tenantId[1]);
            if (filteredTenants.find(tenant => tenant.id === tenantId[1])?.status?.toLowerCase()
                === TenantStatusConstants.ENABLED.toLowerCase()) {
                window.PortalShell?.AccountAndTenants.updateCurrentTenant(tenantId[1]);
            }
        } else {
            setActive('org');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ location.pathname ]);

    const addTenantOnClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        canAddTenant ?
            navigate(getRoute(isHost ? RouteNames.HostOrganizationAdd : RouteNames.TenantCreate))
            : setAnchorEl(event.currentTarget);
    }, [ canAddTenant, getRoute, isHost, navigate ]);

    const organizationShellList = useMemo(() => filteredOrganizations
        ?.sort((objA, objB) => new Date(objA.createdOn).getTime() - new Date(objB.createdOn).getTime())
        .map(organization => (
            <MenuItem
                key={`organization-shell-item-${organization.name}`}
                className={clsx(classes.menuItem, classes.tenantSpacing)}
                selected={active === organization.id}
                style={{ fontWeight: active === organization.id ? 600 : 400 }}
                onClick={e => {
                    e.preventDefault();
                    handleMenuItemClick(organization.id);
                }}
                data-cy={`organization-shell-item-${organization.name}`}
            >
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <OrgTenantShellIcon
                        className={clsx(classes.orgIcon, classes.svgIcon)}
                        width='24px'
                    />
                    {organization.name}
                </div>
            </MenuItem>
        )),
    [
        active,
        classes.menuItem,
        classes.orgIcon,
        classes.svgIcon,
        classes.tenantSpacing,
        filteredOrganizations,
        handleMenuItemClick,
    ]);

    const tenantShellList = useMemo(() => filteredTenants
        .map(tenant => (
            <MenuItem
                key={`tenant-shell-item-${tenant.name}`}
                className={clsx(classes.menuItem, classes.tenantSpacing)}
                selected={active === tenant.id}
                style={{ fontWeight: active === tenant.id ? 600 : 400 }}
                onClick={e => {
                    e.preventDefault();
                    handleMenuItemClick(
                        tenant.id,
                        tenant.status.toLowerCase() === TenantStatusConstants.ENABLED.toLowerCase(),
                    );
                }}
                data-cy={`tenant-shell-item-${tenant.name}`}
            >
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    {tenant.environment === TenantEnvironment.Production || !tenant.environment ?
                        <LanguageIcon
                            className={classes.tenantIcon}
                            style={{
                                color: tenant.color ?? theme.palette.grey,
                                width: '16px',
                            }}
                        />
                        : <ApTooltip content={translate({ id: 'CLIENT_TENANT_SHELL_NON_PRODUCTION_TOOLTIP' }, { status: tenant.status })}>
                            <UnfoldMore
                                className={classes.tenantIcon}
                                sx={{ transform: 'rotate(90deg)' }}
                                style={{
                                    color: tenant.color ?? theme.palette.yellow,
                                    width: '16px',
                                }}
                            />
                        </ApTooltip>}
                    {tenant.name}
                </div>
                {checkOperationList(tenant.id) ?
                    <CircularProgress size={15} /> :
                    tenantChipMessage(tenant) &&
                        <Chip
                            className={classes.chip}
                            size="small"
                            clickable={false}
                            label={translate({ id: tenantChipMessage(tenant) })}
                            data-cy={`${tenant.name}-tenant-status-chip`} />}
            </MenuItem>),
        ),
    [
        active,
        checkOperationList,
        classes.chip,
        classes.menuItem,
        classes.tenantIcon,
        classes.tenantSpacing,
        filteredTenants,
        handleMenuItemClick,
        tenantChipMessage,
        theme.palette.grey,
        theme.palette.yellow,
        translate,
    ]);

    return <div className={classes.tenantShellContainer}>
        <MenuList
            className={classes.list}
            classes={{ root: classes.listRoot }}>
            <MenuItem
                data-cy="tenant-shell-admin-home"
                className={clsx(classes.menuItem, classes.orgMenuItem)}
                selected={active === 'org'}
                onClick={e => {
                    e.preventDefault();
                    handleMenuItemClick('org');
                }}>
                {isHost ?
                    <HostTenantShellIcon
                        className={clsx(classes.orgIcon, classes.svgIcon)}
                        width='24px'
                    />
                    :
                    <OrgTenantShellIcon
                        className={clsx(classes.orgIcon, classes.svgIcon)}
                        width='24px'
                    />}
                <div
                    className={classes.orgName}
                    style={{ fontWeight: active === 'org' ? 600 : 400 }}>
                    {isHost ? translate({ id: 'CLIENT_HOST' }) : companyName}
                </div>
            </MenuItem>
        </MenuList>
        <div className={classes.tenantActionBar}>
            {!searching
                ?
                <div className={classes.tenantShellHeader}>
                    <UiText className={classes.tenantActionHeader}>
                        {translate({ id: isHost ? 'CLIENT_ORGANIZATIONS' : 'CLIENT_PAGE_TENANTS' })}
                    </UiText>
                </div>
                : <TextField
                    data-cy="tenant-shell-search"
                    placeholder={translate({ id: isHost ? 'CLIENT_SEARCH_ORGANIZATION' : 'CLIENT_SEARCH_TENANT' })}
                    InputProps={{
                        endAdornment: (
                            <IconButton aria-label={translate({ id: 'CLIENT_CLOSE' })}>
                                <CloseIcon
                                    onClick={() => {
                                        resetSearch();
                                    }} />
                            </IconButton>
                        ),
                    }}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus={searching}
                    value={query}
                    onChange={event => setQuery(event?.target.value)}
                    fullWidth />}
            <div>
                {!searching && <IconButton
                    data-cy="tenant-shell-search-button"
                    style={{ marginRight: isHost ? '-8px' : '' }}
                    aria-label={translate({ id: isHost ? 'CLIENT_SEARCH_ORGANIZATION' : 'CLIENT_SEARCH_TENANT' })}
                    onClick={() => setSearching(true)}>
                    <SearchOutlinedIcon />
                </IconButton>}
                <IconButton
                    aria-label={createTenantToolTip}
                    className={classes.addTenant}
                    onClick={addTenantOnClick}
                    data-cy="tenant-shell-add-button"
                    disabled={isLoading || !isOrgAdmin || (!canAddTenant && AccountLicense[licensePlan] === AccountLicense.ENTERPRISE)}>
                    <Tooltip title={createTenantToolTip}>
                        <AddIcon />
                    </Tooltip>
                </IconButton>
                {tenantUpgradeLicenseType != null && <UiUpgradePopOver
                    title={translate({ id: `CLIENT_CHIP_MAX_TENANTS_CUSTOMIZATION_HEADER_${AccountLicense[tenantUpgradeLicenseType]}`.toUpperCase() })}
                    description={translate({ id: `CLIENT_CHIP_MAX_TENANTS_CUSTOMIZATION_DESCRIPTION_${AccountLicense[tenantUpgradeLicenseType]}`.toUpperCase() })}
                    iconDescription={translate({ id: `CLIENT_CHIP_MAX_TENANTS_CUSTOMIZATION_ICON_DESCRIPTION_${AccountLicense[tenantUpgradeLicenseType]}`.toUpperCase() })}
                    licenseType={tenantUpgradeLicenseType}
                    icon={<FormatListNumberedOutlinedIcon />}
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    telemetryTitle={TenantsEvent.AddTenant}
                />}
            </div>
        </div>
        <MenuList
            data-cy={`${isHost ? 'organization' : 'tenant'}-shell-list`}
            className={clsx(classes.tenantList, classes.list)}>
            {isLoading
                ? Array.from(Array(5)).map((_x, i) =>
                    <MenuItem
                        key={`tenant-shell-item-${i}`}
                        className={clsx(classes.menuItem, classes.tenantMenuItem)}
                        disabled
                        aria-label={translate({ id: 'CLIENT_LOADING_MESSAGE' })}
                    >
                        <Skeleton
                            style={{ marginRight: '8px' }}
                            variant="rectangular"
                            width="16px"
                            height="16px"
                        />
                        <Skeleton
                            data-cy="tenant-shell-loading-skeleton"
                            variant="rectangular"
                            height="16px"
                            width={i % 2 === 0 ? '100%' : '50%'}
                        />
                    </MenuItem>)
                : (isHost
                    ? ((organizationShellList?.length ?? 0) <= 0 ? noResults : organizationShellList)
                    : ((tenantShellList?.length ?? 0) <= 0 ? noResults : tenantShellList)
                )}
        </MenuList>
        <div className={classes.footer}>
            {window.env.showVersion && window.env?.CLOUDRPA_VERSION && <UiText
                className={classes.footerSpacing}
                data-cy="tenant-shell-version">
                {`${translate({ id: 'CLIENT_VERSION' })}: `}
                <span className={classes.supportText}>
                    {window.env.CLOUDRPA_VERSION}
                </span>
            </UiText>}
            {supportId && supportId.toLowerCase() !== 'unlicensed' && <UiText data-cy="tenant-shell-support-id">
                {`${translate({ id: 'CLIENT_SUPPORT_ID' })}: `}
                <span className={classes.supportText}>
                    {supportId}
                </span>
            </UiText>}
        </div>
    </div>;
};

export default TenantShell;
