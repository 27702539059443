import { UiSuspensefulOutlet } from '@experiences/ui-common';
import React from 'react';

import type { UiRouteObject } from '../container/routeConfigs/UiRouteObject';
import { HostAdminRoutes } from './organization/HostAdminRoutes';

export const HostRoutes: UiRouteObject = {
    element: <UiSuspensefulOutlet />,
    children: [ HostAdminRoutes ],
};
