import { SecuritySettingEnum } from '@experiences/interfaces';
import { UiSuspensefulOutlet } from '@experiences/ui-common';
import type { PropsWithChildren } from 'react';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
    AuthSettingsConfigureAAD,
    AuthSettingsConfigureAD,
    AuthSettingsConfigureADDirectory,
    AuthSettingsConfigureGoogle,
    AuthSettingsConfigureSaml,
    AuthSettingsPasswordPolicy,
    SecuritySettings,
    SessionPolicy,
} from '../../common/constants/RouteNames';
import { routePaths } from '../../common/constants/routePaths';
import BreadcrumbProvider from '../../common/providers/BreadcrumbProvider';
import type { UiRouteObject } from '../../container/routeConfigs/UiRouteObject';

const SecuritySettingsPageComponent = React.lazy(() => import('./SecuritySettings'));
const EditPasswordPolicyComponent = React.lazy(() => import('../authsettings/subcomponents/EditPasswordPolicyComponent'));
const ConfigureADComponent = React.lazy(() => import('../authsettings/subcomponents/ConfigureADComponent'));
const ConfigureAADComponent = React.lazy(() => import('../authsettings/subcomponents/ConfigureAADComponent'));
const ConfigureGoogleComponent = React.lazy(() => import('../authsettings/subcomponents/ConfigureGoogleComponent'));
const ConfigureSamlComponent = React.lazy(() => import('../authsettings/subcomponents/ConfigureSamlComponent'));
const SecuritySettingsADForm = React.lazy(() => import('../authsettings/subcomponents/SecuritySettingsADForm'));

const HostSecurityProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { formatMessage: translate } = useIntl();

    const breadCrumbLinks = useMemo(() => [
        {
            index: 0,
            link: routePaths.admin,
            name: translate({ id: 'CLIENT_HOST' }),
        },
        {
            index: 1,
            link: routePaths.adminHostSecurityAuthentication,
            name: translate({ id: 'CLIENT_SECURITY_SETTINGS' }),
        },
        {
            index: 1,
            link: routePaths.adminHostSecuritySessionPolicy,
            name: translate({ id: 'CLIENT_SESSION_POLICY' }),
        },
        {
            index: 2,
            link: routePaths.adminHostSecurityAuthenticationConfigureAD,
            name: translate({ id: 'CLIENT_AD_SSO_CONFIGURATION' }),
        },
        {
            index: 2,
            link: routePaths.adminHostSecurityAuthenticationConfigureAAD,
            name: translate({ id: 'CLIENT_AAD_SSO_CONFIGURATION' }),
        },
        {
            index: 2,
            link: routePaths.adminHostSecurityAuthenticationConfigureGoogle,
            name: translate({ id: 'CLIENT_GOOGLE_SSO_CONFIGURATION' }),
        },
        {
            index: 2,
            link: routePaths.adminHostSecurityAuthenticationConfigureSaml,
            name: translate({ id: 'CLIENT_SAML_SSO_CONFIGURATION' }),
        },
        {
            index: 2,
            link: routePaths.adminHostSecurityAuthenticationConfigureADDirectory,
            name: translate({ id: 'CLIENT_AD_SSO_CONFIGURATION' }),
        },
    ], [ translate ]);

    return <BreadcrumbProvider breadcrumbs={breadCrumbLinks}>
        {children}
    </BreadcrumbProvider>;
};

export const HostSecurityRoutes: UiRouteObject = {
    path: routePaths.adminHostSecurity,
    redirectUrls: [ SecuritySettings ],
    element: <HostSecurityProvider>
        <UiSuspensefulOutlet />
    </HostSecurityProvider>,
    children: [
        {
            path: routePaths.adminHostSecurityAuthentication,
            redirectUrls: [ SecuritySettings ],
            element: <>
                <SecuritySettingsPageComponent type={SecuritySettingEnum.Authentication} />
                <UiSuspensefulOutlet />
            </>,
            children: [
                {
                    path: routePaths.adminHostSecurityAuthenticationPasswordPolicy,
                    redirectUrls: [ AuthSettingsPasswordPolicy ],
                    element: <EditPasswordPolicyComponent />,
                },
            ],
        },
        {
            path: routePaths.adminHostSecurityAuthenticationConfigureAD,
            redirectUrls: [ AuthSettingsConfigureAD ],
            element: <ConfigureADComponent />,
        },
        {
            path: routePaths.adminHostSecurityAuthenticationConfigureAAD,
            redirectUrls: [ AuthSettingsConfigureAAD ],
            element: <ConfigureAADComponent />,
        },
        {
            path: routePaths.adminHostSecurityAuthenticationConfigureGoogle,
            redirectUrls: [ AuthSettingsConfigureGoogle ],
            element: <ConfigureGoogleComponent />,
        },
        {
            path: routePaths.adminHostSecurityAuthenticationConfigureSaml,
            redirectUrls: [ AuthSettingsConfigureSaml ],
            element: <ConfigureSamlComponent />,
        },
        {
            path: routePaths.adminHostSecurityAuthenticationConfigureADDirectory,
            redirectUrls: [ AuthSettingsConfigureADDirectory ],
            element: <SecuritySettingsADForm />,
        },
        {
            path: routePaths.adminHostSecuritySessionPolicy,
            redirectUrls: [ SessionPolicy ],
            element: <SecuritySettingsPageComponent type={SecuritySettingEnum.SessionPolicy} />,
        },
    ],
};

