import type { IConsumable } from '@experiences/interfaces';
import Grid from '@mui/material/Grid';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React from 'react';

import { UiChartSection } from './UiChartSection';
import { UiConsumableCard } from './UiConsumableCard';

const useStyles = makeStyles(theme =>
    createStyles({
        chart: {
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: '21px',
            paddingRight: '11px',
            paddingTop: '8px',
            paddingBottom: '22px',
            borderLeft: `1px solid ${theme.palette.semantic.colorBorder}`,
            borderRight: `1px solid ${theme.palette.semantic.colorBorder}`,
            borderBottom: `1px solid ${theme.palette.semantic.colorBorder}`,
            position: 'relative',
        },
        chartRightBorder: {
            '&:after': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: '50%',
                width: '50%',
                borderTop: `1px solid ${theme.palette.semantic.colorBorder}`,
            },
        },
        chartLeftBorder: {
            '&:after': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '50%',
                borderTop: `1px solid ${theme.palette.semantic.colorBorder}`,
            },
        },
    }),
);

export const UiConsumableGroup: React.FC<{
    consumable: IConsumable;
    handleOpenChart: (selected: string) => void;
    chartSection: { opened: boolean; selected: string };
    tenantId?: string;
    index: number;
}> = ({
    consumable, handleOpenChart, chartSection, tenantId, index,
}) => {
    const classes = useStyles();

    return (<>
        <UiConsumableCard
            key={consumable.code}
            consumable={consumable}
            handleOpenChart={handleOpenChart}
            isSelected={chartSection.selected === consumable.code && chartSection.opened}
            tenantId={tenantId}
            order={index} />
        {chartSection?.opened && consumable.code === chartSection.selected &&
              <Grid
                  xs={12}
                  item
                  data-cy="licenses-grid-chart"
                  order={index + (index % 2 ? 1 : 2)}
              >
                  <div className={`${classes.chart} ${ index % 2 ? classes.chartLeftBorder : classes.chartRightBorder}`}>
                      <UiChartSection
                          product={chartSection.selected}
                          consumable={consumable}
                          tenantId={tenantId}
                      />
                  </div>
              </Grid>}
    </>
    );
};
