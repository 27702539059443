import { ContactUsLinks } from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import type { ICurrentTrial } from '@experiences/interfaces';
import { TrialServiceEvent } from '@experiences/telemetry';
import { GlobalStyles } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import {
    getAllProductsConfigurations,
    useLocalizedLinks,
} from '@experiences/util';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import { PortalCustomIcon } from '@uipath/portal-shell-react';
import React, {
    useCallback,
    useState,
} from 'react';
import {
    FormattedDate,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { CONSUMABLES_PRODUCTS } from '../../../common/constants/Constant';
import useIsOnPrem from '../../../common/hooks/useIsOnPrem';
import {
    getConsumables,
    licenseManagementAccountConsumablesUrl,
} from '../../../services/licensing/management/AccountService';
import { subscriptionCodeToServiceName } from '../../../services/licensing/TrialPerSku';
import {
    accountGlobalId,
    accountType,
    EnableUserLicensingSelector,
    isHostModeSelector,
} from '../../../store/selectors';
import { useTelemetryHelper } from '../../../telemetry/TelemetryHelper';
import {
    computeProductProperties,
    extractProducts,
} from '../../../util/LicenseUtil';
import { UiConsumableGroup } from '../helperComponents/UiConsumableCard/UiConsumableGroup';
import { LicensingProductAllocations } from '../LicensingProductAllocations';
import { CurrentTrialActionButtons } from './CurrentTrialActionButtons';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        title: {
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '24px',
            color: theme.palette.semantic.colorForeground,
            marginBottom: '16px',
        },
        currentTrialService: { marginBottom: '40px' },
        trialServiceInfoBar: {
            display: 'flex',
            justifyContent: 'flexStart',
            alignItems: 'center',
        },
        serviceTitle: {
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForeground,
        },
        serviceExpiryDate: {
            display: 'flex',
            justifyContent: 'flexStart',
        },
        divider: {
            marginTop: '10px',
            marginBottom: '3px',
            borderColor: theme.palette.semantic.colorBorderDeEmp,
        },
        titleAndDate: { marginLeft: '12px' },
        contactSalesButton: {
            color: theme.palette.semantic.colorForegroundLink,
            cursor: 'pointer',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
            '&:hover': { textDecoration: 'underline' },
            marginLeft: 'auto',
        },
    }),
}));
export const CurrentTrialsPanel: React.FC<{ currentTrials: ICurrentTrial[] }> = ({ currentTrials }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const enableConsumablesTab = useFeatureFlagValue(Features.EnableConsumablesTab.name);
    const accountTypeSelector = useSelector(accountType);
    const isHostMode = useSelector(isHostModeSelector);
    const isGov = useFeatureFlagValue(Features.DisableFeatureFedRamp.name);
    const getLocalizedLink = useLocalizedLinks();
    const userLicensingIsEnabled = useSelector(EnableUserLicensingSelector);
    const { logEvent } = useTelemetryHelper();

    const isOnPrem = useIsOnPrem();

    const accountId = useSelector(accountGlobalId);
    const { data: consumablesToRender } = useSWR(
        enableConsumablesTab ? {
            url: licenseManagementAccountConsumablesUrl,
            accountGlobalId: accountId,
            includeTrialConsumables: true,
        } : null,
        getConsumables,
    );

    const [ chartUsageSection, setChartUsageSection ] = useState<{ opened: boolean; selected: string }>(
        {
            opened: false,
            selected: '',
        });

    const handleOpenChartUsage = useCallback((selected: string) => {
        setChartUsageSection(prevState => ({
            opened: prevState.selected === selected ? !prevState.opened : true,
            selected,
        }));
    }, [ setChartUsageSection ]);

    const currentTrialsUsage = (currentTrial: ICurrentTrial) => {

        let products = extractProducts(
            getAllProductsConfigurations(userLicensingIsEnabled),
            currentTrial.products,
            false,
            false,
        ).map(p => computeProductProperties(p, isHostMode, undefined, accountTypeSelector, isGov));

        const trialConsumableProducts = products
            .map(p => p.code)
            .filter(code => CONSUMABLES_PRODUCTS.includes(code));

        let consumables = undefined;

        if (enableConsumablesTab && trialConsumableProducts.length > 0) {
            consumables = consumablesToRender ? consumablesToRender.filter(p => trialConsumableProducts.includes(p.code)) : undefined;
            products = products.filter(p => !trialConsumableProducts.includes(p.code));
        }

        return <>
            {(enableConsumablesTab && consumables && consumables.length > 0) && (
                <Grid
                    container
                    spacing={0}>
                    { consumables.map((consumable, index) =>
                        <UiConsumableGroup
                            key={index}
                            consumable={consumable}
                            handleOpenChart={handleOpenChartUsage}
                            chartSection={chartUsageSection}
                            index={index} />)}
                </Grid>
            )}
            { products.length > 0 &&
            <LicensingProductAllocations
                products={products.map(p => computeProductProperties(p, isHostMode, undefined, accountTypeSelector, isGov))}
                entitlementUsages={[]}
                loading={false}
            />}
        </>;
    };

    return currentTrials && currentTrials.length > 0 ? (
        <>
            <UiText className={classes.title}>
                {translate({ id: 'CLIENT_CURRENT_TRIALS' })}
            </UiText>
            {currentTrials.map((currentTrial, i) => (
                <div
                    key={i}
                    className={classes.currentTrialService}
                    data-cy={`current-trial-${currentTrial.subscriptionCode.toLowerCase()}`}
                >
                    <div className={classes.trialServiceInfoBar}>
                        <PortalCustomIcon
                            name={subscriptionCodeToServiceName[currentTrial.subscriptionCode]}
                            size="32px" />
                        <div className={classes.titleAndDate}>
                            <UiText className={classes.serviceTitle}>
                                {translate({ id: `CLIENT_CURRENT_TRIAL_SERVICE_HEADER_${currentTrial.subscriptionCode}` })}
                            </UiText>
                            <div className={classes.serviceExpiryDate}>
                                <UiText>
                                    {translate({ id: `CLIENT_TRIAL_EXPIRY` })}
                                </UiText>
                                <div style={{ marginLeft: '8px' }}>
                                    <UiText data-cy="trial-expiry-date">
                                        <FormattedDate
                                            value={new Date(currentTrial.expiryDate * 1000)}
                                            year="numeric"
                                            month="short"
                                            day="numeric"
                                            timeZone="UTC"
                                        />
                                    </UiText>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginLeft: 'auto' }}>
                            <a
                                className={classes.contactSalesButton}
                                target="_blank"
                                rel="noreferrer"
                                href={getLocalizedLink(ContactUsLinks)}
                                data-cy={`learn-more-link-${currentTrial.subscriptionCode.toLowerCase()}`}
                                onClick={() => logEvent(TrialServiceEvent.ContactSales,
                                    { SubscriptionCode: currentTrial.subscriptionCode })}
                            >
                                {translate({ id: 'CLIENT_CONTACT_SALES' })}
                            </a>
                            {isOnPrem && <CurrentTrialActionButtons
                                currentTrial={currentTrial}
                                id={i}
                            />}
                        </div>
                    </div>
                    <Divider className={classes.divider} />
                    {currentTrialsUsage(currentTrial)}
                </div>
            ))}
        </>
    ) : null;
};
