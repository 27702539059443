import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { UiSuspensefulOutlet } from '@experiences/ui-common';
import React from 'react';

import {
    ResourceCenter,
    ResourceCenterCMSUpgradeToEnterprise,
} from '../../common/constants/RouteNames';
import { routePaths } from '../../common/constants/routePaths';
import useIsOnPrem from '../../common/hooks/useIsOnPrem';
import type { UiRouteObject } from '../../container/routeConfigs/UiRouteObject';

const ResourceCenterCMS = React.lazy(() => import('./ResourceCenterCMS'));
const ResourceCenterDefault = React.lazy(() => import('./ResourceCenter'));
const UpgradeToEnterpriseDialogComponent = React.lazy(() => import('../licensing/UpgradeToEnterpriseDialogComponent'));

const ResourceCenterComponent: React.FC = () => {
    const EnableResourceCenterCMS = !useFeatureFlagValue(Features.DisableFeatureFedRamp.name);
    const isOnPrem = useIsOnPrem();

    return EnableResourceCenterCMS && !isOnPrem ? <ResourceCenterCMS /> : <ResourceCenterDefault />;
};

export const ResourceCenterRoutes: UiRouteObject = {
    path: routePaths.resourceCenter,
    element: <UiSuspensefulOutlet />,
    children: [
        {
            path: routePaths.resourceCenter,
            redirectUrls: [ ResourceCenter ],
            element: <ResourceCenterComponent />,
            children: [
                {
                    path: routePaths.resourceCenterCMSUpgradeToEnterprise,
                    redirectUrls: [ ResourceCenterCMSUpgradeToEnterprise ],
                    element: <UpgradeToEnterpriseDialogComponent />,
                    explicitEnvironments: [ 'cloud' ],
                },
            ],
        },
    ],
};
