import type {
    IConsumable,
    IConsumptionDataResponse,
    ILAUsage,
} from '@experiences/interfaces';
import type { CancelTokenSource } from 'axios';

import type {
    IEntitlementUsageDetailsResponse,
    IGetUsageDetails,
} from '../../component/tenants/interfaces/service';
import store from '../../store/index';
import {
    post,
    put,
} from '../utility/Requests';

export const licenseUsageUrl = '/api/usage';

export function getLicenseUsageTimeline(
    clientIds: string[],
    timePeriod: number,
    usageAggregationType: string,
    source?: CancelTokenSource,
) {
    const accountGlobalId = store.getState().profile.accountUserDto.accountGlobalId.toLowerCase();
    return post<ILAUsage[]>(`${licenseUsageUrl}/getTimelineForAccount`, {
        body: {
            accountGlobalId,
            clientIds,
            timePeriod,
            usageAggregationType,
        },
        cancelTokenSource: source,
    });
}

export async function getUsageDetails({ entitlements }: { url: string; entitlements: string[] }) {
    const usageDetails: IGetUsageDetails = { entitlements };

    return put<IEntitlementUsageDetailsResponse[]>(
        `${licenseUsageUrl}/pools/details`,
        { body: usageDetails },
    );
}

export async function getConsumptionData({
    startDate, endDate, consumableCode, services, aggregationPeriod, tenantId,
}: {
    url: string;
    startDate: number;
    endDate: number;
    consumableCode: string;
    services: string[];
    aggregationPeriod: string;
    tenantId?: string;
}) {
    return put<IConsumptionDataResponse>(`${licenseUsageUrl}/services`, {
        body: {
            startDate,
            endDate,
            consumableCode,
            services,
            aggregationPeriod,
            tenantId,
        },
    });
}

export function getDefaultConsumptionPool(consumable: IConsumable) {
    if (consumable.pools.length === 1) {
        return [ consumable.pools[0].startDate, consumable.pools[0].endDate ];
    }
    const defaultPool = consumable.pools.find(pool => pool.name.toLowerCase().endsWith('default'));
    return defaultPool ? [ defaultPool.startDate, defaultPool.endDate ] : [];
}
