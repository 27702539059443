import {
    CommandTableOperationConstants,
    OperationTypeConstants,
} from '@experiences/constants';
import type {
    ITableContext,
    TenantOperation,
} from '@experiences/interfaces';
import { portalTelemetry } from '@experiences/telemetry';
import {
    commandTableOperationResult,
    useAuthContext,
    useInterval,
} from '@experiences/util';
import React, {
    createContext,
    useCallback,
    useContext,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useSWRConfig } from 'swr';

import { notificationType } from '../../common/constants/Constant';
import { triggerPortalShellRefresh } from '../../common/hooks/triggerPortalShellRefresh';
import { useUiSnackBar } from '../../common/hooks/useUiSnackBar';
import { leaseUrl } from '../../services/licensing/accountant/LeaseService';
import {
    organizationManagementTenantUri,
    tenantByIdUri,
} from '../../services/organization/TenantService';
import {
    accountGlobalId,
    accountLogicalName,
} from '../../store/selectors';
import { serviceInstanceUrl } from './TenantsContextProvider';

const TenantOperationTrackerContext = createContext<ITableContext>({
    addTenantOperation: (_operationID: string, _tenantName: string, _tenantID: string, _operationType: string) => {},
    refreshAfterComplete: (_tenantId?: string, _triggerRefresh?: boolean) => {},
    checkOperationList: (_tenantId: string) => false,
});

export const useTenantOperationTrackerContext = () => useContext(TenantOperationTrackerContext);

export const TenantOperationTrackerContextProvider: React.FC<{ children?: React.ReactNode }> = props => {

    const { formatMessage: translate } = useIntl();
    const createNotification = useUiSnackBar();
    const [ tenantOperationsList, setTenantOperationsList ] = useState([] as TenantOperation[]);
    const { token } = useAuthContext();

    const addTenantOperation = useCallback((operationID: string, tenantName: string, tenantID: string, operationType: string) => {
        setTenantOperationsList(tenantOperationsList.concat({
            operationID,
            tenantName,
            tenantID,
            operationType,
        }));
    }, [ tenantOperationsList ]);

    const checkOperationList = useCallback((tenantID: string) =>
        tenantOperationsList.some(operation => operation.tenantID === tenantID)
    , [ tenantOperationsList ]);

    const partitionGlobalId = useSelector(accountGlobalId);
    const accountName = useSelector(accountLogicalName);

    const { mutate } = useSWRConfig();

    const refreshAfterComplete = useCallback((tenantId?: string, triggerRefresh = true) => {
        if (triggerRefresh) {
            triggerPortalShellRefresh();
        }
        if (tenantId) {
            mutate({
                url: tenantByIdUri,
                id: tenantId,
            });
        }
        mutate({
            url: serviceInstanceUrl,
            organizationGuid: partitionGlobalId,
            accountName,
            includeTenantServices: true,
        });
        mutate(leaseUrl);
        mutate({
            url: organizationManagementTenantUri,
            organizationGuid: partitionGlobalId,
            accountName,
            includeTenantServices: true,
        });

    }, [ accountName, mutate, partitionGlobalId ]);

    useInterval(async () => {
        tenantOperationsList.forEach(async (currentOperation) => {
            try {
                const operationStatus = await commandTableOperationResult(currentOperation.operationID, partitionGlobalId, token);
                if (operationStatus) {
                    refreshAfterComplete(currentOperation.tenantID, currentOperation.operationType === OperationTypeConstants.TENANT);
                    currentOperation.operationType === OperationTypeConstants.ORGANIZATION
                        ? createNotification(translate({ id: `CLIENT_ORGANIZATION_${operationStatus.toUpperCase()}` }, { 0: currentOperation.tenantName }),
                            operationStatus === CommandTableOperationConstants.FAILED ? notificationType.ERROR : notificationType.SUCCESS)
                        : createNotification(translate({ id: `CLIENT_TENANT_${operationStatus.toUpperCase()}` }, { 0: currentOperation.tenantName }),
                            operationStatus === CommandTableOperationConstants.FAILED ? notificationType.ERROR : notificationType.SUCCESS);
                    setTenantOperationsList(prevList => prevList.filter((operationIterator) =>
                        operationIterator.operationID !== currentOperation.operationID));
                }
            } catch (error) {
                portalTelemetry.trackTrace(
                    { message: `Failed to retrieve information on ID - ${currentOperation.operationID} from Command Table: ${error}` },
                );
                setTenantOperationsList(prevList => prevList.filter((operationIterator) =>
                    operationIterator.operationID !== currentOperation.operationID));
            }
        });
    }, tenantOperationsList ? 10000 : null);

    return (
        <TenantOperationTrackerContext.Provider
            value={{
                addTenantOperation,
                refreshAfterComplete,
                checkOperationList,
            }}
        >
            {props.children}
        </TenantOperationTrackerContext.Provider>
    );

};
