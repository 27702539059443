import type { LanguageCulture } from '@experiences/locales';
/* eslint-disable no-restricted-imports */
import type { Moment } from 'moment';
import moment from 'moment';
/* eslint-enable no-restricted-imports */
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

export const DAYS = 24 * 60 * 60 * 1000;

export const useUserReadableTime = () => {
    const { formatMessage: translate } = useIntl();

    const userReadableTime = useCallback(
        (input?: string | Date | number, upperLimit: number = Number.MAX_SAFE_INTEGER) => {
            let time = moment();
            if (typeof input === 'number') {
                time = moment.unix(input);
            } else if (input) {
                time = moment(input);
            }

            const seconds = (Date.now() - time.valueOf()) / 1000;
            if (seconds > upperLimit) {
                return '';
            }

            // Follow Apollo guidelines for time formatting
            // https://apollo.uipath.com/598bba218/p/4669e2-date-and-time-format
            if (seconds <= 44) {
                return translate({ id: 'CLIENT_JUST_NOW' });
            } else if (seconds > 44 && seconds <= 89) {
                return translate({ id: 'CLIENT_MINUTE_AGO' });
            } else if (seconds < 44 * 60) {
                return translate({ id: 'CLIENT_MINUTES_AGO' }, { num: Math.round(seconds / 60) });
            } else if (seconds >= 45 * 60 && seconds < 90 * 60) {
                return translate({ id: 'CLIENT_HOUR_AGO' });
            } else if (seconds >= 90 * 60 && seconds < 22 * 60 * 60) {
                return translate({ id: 'CLIENT_HOURS_AGO' }, { num: Math.round(seconds / (60 * 60)) });
            } else if (seconds >= 22 * 60 * 60 && seconds < 36 * 60 * 60) {
                return translate({ id: 'CLIENT_DAY_AGO' });
            } else if (seconds >= 36 * 60 * 60 && seconds < 26 * 24 * 60 * 60) {
                return translate({ id: 'CLIENT_DAYS_AGO' }, { num: Math.round(seconds / (24 * 60 * 60)) });
            } else if (seconds >= 26 * 24 * 60 * 60 && seconds < 46 * 24 * 60 * 60) {
                return translate({ id: 'CLIENT_MONTH_AGO' });
            } else if (seconds >= 46 * 24 * 60 * 60 && seconds < 320 * 24 * 60 * 60) {
                return translate({ id: 'CLIENT_MONTHS_AGO' }, { num: Math.round(seconds / (30 * 24 * 60 * 60)) });
            } else if (seconds >= 320 * 24 * 60 * 60 && seconds < 548 * 24 * 60 * 60) {
                return translate({ id: 'CLIENT_YEAR_AGO' });
            } else if (seconds >= 548 * 24 * 60 * 60) {
                return translate({ id: 'CLIENT_YEARS_AGO' }, { num: Math.round(seconds / (365 * 24 * 60 * 60)) });
            }
            return '';
        },
        [ translate ],
    );

    return { userReadableTime };
};

export function getRelativeTime(locale: string) {
    // in milliseconds
    const units: Record<string, number> = {
        year: 24 * 60 * 60 * 1000 * 365,
        month: 24 * 60 * 60 * 1000 * 365 / 12,
        day: 24 * 60 * 60 * 1000,
    };

    const rtf = new Intl.RelativeTimeFormat(locale, { numeric: 'auto' });

    const getRelativeTimeCurried = (d1: number, d2 = new Date()) => {
        const d2Number = +d2;
        const elapsed = d1 - d2Number;

        for (const u in units) {
            if (Math.abs(elapsed) > units[u] || u === 'day') {
                if (u === 'year') {
                    return new Date(d1).toLocaleDateString(locale, {
                        month: 'short',
                        year: 'numeric',
                        day: 'numeric',
                    });
                }
                return rtf.format(Math.round(elapsed / units[u]), u as any);
            }
        }

        return '';
    };

    return getRelativeTimeCurried;
}

/**
 * Converts a timestamp to a localized date and time string.
 *
 * @param {string | Date} timestamp - The timestamp to convert. This can be a string or a Date object.
 * @param {LanguageCulture} [language] - The language culture to use for localization.
 * @returns {string} A string representing the localized date and time.
 */
export function timestampToDateTime(timestamp: string | Date, language?: LanguageCulture) {
    const lang = language === 'en' || language?.toLowerCase() === 'keys' ? 'en-US' : language;

    const date = moment.utc(timestamp).toDate();

    const dateString = date.toLocaleDateString(lang, {
        timeZone: 'UTC',
        weekday: 'short',
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    });

    let timeString = date.toLocaleTimeString(lang, {
        timeZone: 'UTC',
        timeZoneName: 'short',
        hour12: false,
    });

    // Replace "24" with "00" if the hour is exactly 24
    // NOTE: the was only needed for the unit tests, but it's harmless to keep it
    if (timeString.startsWith('24')) {
        timeString = '00' + timeString.substring(2);
    }

    return `${dateString} ${timeString}`;
}

/**
 * Converts a timestamp to a localized date string.
 *
 * @param {string | Date} timestamp - The timestamp to convert. This can be a string or a Date object.
 * @param {LanguageCulture} [language] - The language culture to use for localization.
 * @returns {string} A string representing the localized date.
 */
export function timestampToDate(timestamp: string | Date, language?: LanguageCulture) {
    const date = moment.utc(timestamp).toDate();
    const dateString = date.toLocaleDateString(
        language === 'en' || language?.toLowerCase() === 'keys' ? 'en-US' : language,
        {
            timeZone: 'UTC',
            day: 'numeric',
            month: 'short',
            year: 'numeric',
        },
    );

    return dateString;
}

/**
 * Returns date from now
 * @param {number} days Can be positive or negative number of days
 * @param {boolean} startOfDay If true, the time will be set to 00:00:00.000
 * @returns {Date} The date from now
 */
export function getDateFromNow(days: number | string, startOfDay = false) {
    if (typeof days === 'string') {
        days = parseInt(days);
    }
    const date = new Date();
    date.setDate(date.getDate() + days);
    if (startOfDay) {
        date.setHours(0, 0, 0, 0);
    }
    return date;
}

export function isBeforeGivenDate(dateToTest: string | Date, givenDate: string | Date) {
    return moment(dateToTest).isBefore(moment(givenDate));
}

export function isSameDate(dateToTest: string | Date, givenDate: string | Date) {
    return moment(dateToTest).isSame(moment(givenDate), 'days');
}

export function secondsToDays(seconds: number) {
    return String(Math.floor(seconds / 86400));
}

export function daysToSeconds(days: number) {
    return String(days * 86400);
}

export function formatDate(dateToFormat: Date | string | undefined, language: LanguageCulture) {
    if (!dateToFormat) {
        return '';
    }

    const date = (typeof dateToFormat === 'string') ? new Date(dateToFormat) : dateToFormat;

    return date.toLocaleDateString(
        language === 'en' || language?.toLowerCase() === 'keys' ? 'en-US' : language, {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        });
}

export function formatMonthDay(dateToFormat: Date | string | undefined, language: LanguageCulture) {
    if (!dateToFormat) {
        return '';
    }

    const date = (typeof dateToFormat === 'string') ? new Date(dateToFormat) : dateToFormat;

    return date.toLocaleDateString(
        language === 'en' || language?.toLowerCase() === 'keys' ? 'en-US' : language, {
            day: 'numeric',
            month: 'long',
        });
}

export function getIsoDateString(date?: Date, utc = false) {
    return moment(date).utc(utc)
        .toISOString();
}

export function getFormattedDate(input?: Date | string, format = 'YYYY-MM-DD', utc = false, locale = 'en') {
    if (utc) {
        return convertToUTCDateString(input, format, locale);
    }

    return moment(input).locale(locale)
        .format(format);
}

export function convertToDate(input?: Date | string) {
    return moment(input).toDate();
}

export function convertToUTCDateString(input?: Date | string, format?: string, locale = 'en') {
    return moment.utc(input).locale(locale)
        .format(format);
}

export function convertToTimezone(date: Moment | undefined, utc: boolean) {
    if (!date) {
        return undefined;
    }

    const clone = date.clone();

    return utc ? clone.utc() : clone.local();
}

/**
 * This function ensures that a date string has a Z suffix. This is needed, as some datetimes from
 * the server which are UTC do not have a Z suffix, which causes issues when parsing them in the browser.
 * @param dateString The date string to ensure has a Z suffix
 * @returns A date string with a Z suffix
 */
export function ensureZSuffix<T>(dateString: T | string): T | string {
    if (typeof dateString === 'string') {
        return dateString.endsWith('Z') ? dateString : `${dateString}Z`;
    }
    return dateString;
}
