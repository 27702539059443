import {
    useAuth,
    useSignoutRedirect,
} from '@uipath/auth-react';
import { useCallback } from 'react';

import { clearAuthStorage } from '../utils/ClearStorage';

export default function useAuthentication() {
    const { clearStaleState } = useAuth();
    const signoutRedirect = useSignoutRedirect();

    const logout = useCallback(({
        returnUrl = undefined, shouldClearAuthStorage = true,
    }: Partial<{
        returnUrl: string;
        shouldClearAuthStorage: boolean;
    }> = {}) => {
        returnUrl = returnUrl ?? `${window.location.origin}${window.env.postLogoutReturnPathname}`;

        clearStaleState();

        if (shouldClearAuthStorage) {
            clearAuthStorage();
        }

        signoutRedirect({ post_logout_redirect_uri: returnUrl });
    }, [ clearStaleState, signoutRedirect ]);

    return { logout };
}
