import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { IntlProvider } from '@experiences/locales';
import { ApolloThemeProvider } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import { useUserReadableTime } from '@experiences/util';
import CircleIcon from '@mui/icons-material/Circle';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Tokens from '@uipath/apollo-core';
import {
    ApDataGrid,
    elementToNode,
    PortalTooltip,
} from '@uipath/portal-shell-react';
import type {
    DataManager,
    GridHeaderButtons,
    GridRowButtons,
    IColumn,
    IGridOptions,
    ISortModel,
} from '@uipath/portal-shell-types/components/angular-elements';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import useSWR from 'swr';

import { notificationType } from '../../common/constants/Constant';
import { useUiSnackBar } from '../../common/hooks/useUiSnackBar';
import {
    getUserNotificationSubscription,
    UnsubscribeTenantNotificationSubscription,
    updateUserNotificationTopicSubscription,
    userNotificationSubscriptionUri,
} from '../../services/notification-preferences';
import type { ODataQueryOptions } from '../../services/notifications';
import {
    deleteNotification,
    getUserNotificationEntries,
    notificationEntryUri,
    updateNotificationForUnread,
} from '../../services/notifications';
import { accountGlobalId } from '../../store/selectors';
import type {
    INotificationPublisherDto,
    IUpdateUserSubscriptionsRequestData,
} from '../notificationSettings/interfaces/notificationSettings';
import { useTenantsContext } from '../tenants/TenantsContextProvider';
import {
    getFilterQuery,
    getPublisherUsingTopicId,
    getServiceList,
    getTimeTick,
    removeFilters,
} from './helper/NotificationPageHelper';
import type {
    IFilterDataType,
    IFilterQueryParamType,
    INotificationEntry,
    INotificationReadRequest,
} from './interfaces/notifications';
import {
    Duration,
    INotificationActionType,
    NotificationSeverity,
} from './interfaces/notifications';
import NotificationCellComponent from './NotificationCellComponent';
import { NotificationMoreRowActionsComponent } from './NotificationMoreRowActionsComponent';
import NotificationPageComponentV2 from './NotificationPageComponentV2';

const useStyles = makeStyles((theme) =>
    createStyles({
        tabsContainer: { marginBottom: Tokens.Padding.PadL },
        heading: {
            fontSize: Tokens.FontFamily.FontLSize,
            fontWeight: Tokens.FontFamily.FontWeightSemibold,
            paddingBottom: Tokens.Padding.PadXxxl,
        },
        showOnlyUnread: {
            marginLeft: Tokens.Padding.PadM,
            fontSize: `${Tokens.FontFamily.FontMSize} !important`,
            fontWeight: `${Tokens.FontFamily.FontWeightSemibold} !important`,
            color: `${theme.palette.semantic.colorForeground} !important`,
        },
        circleiconClass: {
            width: '.5em !important',
            height: '.5em !important',
            fontSize: '1rem !important',
            margin: '6px',
        },
        link: {
            alignSelf: 'flex-start',
            marginTop: '24px',
            fontSize: Tokens.FontFamily.FontMSize,
            fontWeight: Tokens.FontFamily.FontWeightSemibold,
            lineHeight: '20px',
            cursor: 'pointer',
        },
    }),
);
const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_INDEX = 0;
const publisherKey = 'publisherId';
const tenantKey = 'tenantId';
const topicKey = 'topicId';
const categoryKey = 'category';
const publishedOnKey = 'publishedOn';

const NotificationPageComponent: React.FC = () => {

    const [ searchParams, setSearchParams ] = useSearchParams();

    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const allFilterOption = useMemo(() => ({
        label: translate({ id: 'CLIENT_GRID_FILTER_ALL' }),
        value: translate({ id: 'CLIENT_GRID_FILTER_ALL' }),
    }), [ translate ]);
    const enableNotificationSeverityFilter = useFeatureFlagValue(
        Features.EnableNotificationSeverityFilter.name,
    );

    const partitionGlobalId = useSelector(accountGlobalId);
    const { userReadableTime } = useUserReadableTime();
    const {
        selectedTenant: { id: tenantId }, tenantsWithoutServices: tenantsData,
    } = useTenantsContext();

    const tenantName = localStorage.getItem('PORTAL_CURRENT_TENANT');
    const apGridRef = useRef<any>(null);
    const [ index, setPageIndex ] = useState(DEFAULT_PAGE_INDEX);
    const [ size, setPageSize ] = useState(DEFAULT_PAGE_SIZE);
    const [ dataMgr, setDataMgr ] = useState<DataManager<INotificationEntry>>();
    const [ sortOpts, setSortOpts ] = useState<Array<ISortModel<INotificationEntry>>>([
        {
            field: publishedOnKey,
            direction: 'desc',
            title: publishedOnKey,
        },
    ]);
    const [ loadingGrid, setloadingGrid ] = useState(false);
    const [ tenants, setTenants ] = useState<IFilterDataType[]>([ ]);
    const [ publisherTypes, setPublisherTypes ] = useState<IFilterDataType[]>([ ]);
    const [ selectedTopicGroupFilter, setSelectedTopicGroupFilter ] = useState<IFilterDataType>();
    const [ selectedTopicFilter, setSelectedTopicFilter ] = useState<IFilterDataType>();
    const [ selectedPublisherFilter, setSelectedPublisherFilter ] = useState<IFilterDataType | undefined>(undefined);
    const [ selectedTenantFilter, setSelectedTenantFilter ] = useState<IFilterDataType | undefined>(undefined);
    const [ selectedSeverityFilter, setSelectedSeverityFilter ] = useState<IFilterDataType | undefined>(undefined);
    const [ topicTypes, setTopicTypes ] = useState<IFilterDataType[]>([ ]);
    const [ topicGroupTypes, setGroupTopicTypes ] = useState<IFilterDataType[]>([ ]);
    const [ selectedPublishedOnFilter, setSelectedPublishedOnFilter ] = useState<IFilterDataType>();
    const EnableNotificationspageV2 = useFeatureFlagValue(
        Features.EnableNotificationsPageV2.name,
    );
    const publishedOn = useMemo(() => ([
        {
            label: translate({ id: 'CLIENT_LAST_HOUR' }),
            value: Duration.LastHour.toString(),
        }, {
            label: translate({ id: 'CLIENT_TOTAL_RUN_TODAY' }),
            value: Duration.Last24Hour.toString(),
        }, {
            label: translate({ id: 'CLIENT_LAST_WEEK' }),
            value: Duration.LastWeek.toString(),
        }, {
            label: translate({ id: 'CLIENT_LAST_30_DAYS' }),
            value: Duration.LastMonth.toString(),
        },
    ]), [ translate ]);
    const severityTypes: IFilterDataType[] = useMemo(() => [
        {
            label: translate({ id: 'SEVERITY_INFO' }),
            value: NotificationSeverity.Info,
        },
        {
            label: translate({ id: 'SEVERITY_SUCCESS' }),
            value: NotificationSeverity.Success,
        },
        {
            label: translate({ id: 'SEVERITY_WARN' }),
            value: NotificationSeverity.Warn,
        },
        {
            label: translate({ id: 'SEVERITY_ERROR' }),
            value: NotificationSeverity.Error,
        },
        {
            label: translate({ id: 'SEVERITY_FATAL' }),
            value: NotificationSeverity.Fatal,
        },
    ], [ translate ]);
    const [ onlyUnread, setOnlyUnread ] = useState(false);
    const [ searchTermText, setSearchTerm ] = useState('');
    const defaultOptions: ODataQueryOptions = useMemo(() => ({
        top: DEFAULT_PAGE_SIZE,
        skip: 0,
        orderBy: [ 'publishedOn desc' ],
        count: true,
    }), []);
    const [ queryOptions, setQueryOptions ] = useState(defaultOptions);
    const [ filterQueryParamType, setFilterQueryParamType ] = useState<IFilterQueryParamType[]>([]);
    const createNotification = useUiSnackBar();

    const handleProfileError = useCallback(
        (e: Error) => {
            const errorMessage = e?.message;
            createNotification(`${translate({ id: 'CLIENT_NOTIFICATION_PREFERENCES_UPDATE_FAILED' })} ${errorMessage ? ' - ' + errorMessage : ''}`, notificationType.ERROR, {
                horizontal: 'right',
                vertical: 'top',
            });
        },
        [ createNotification, translate ],
    );

    const { data: publishersData } = useSWR(
        {
            requestUri: userNotificationSubscriptionUri,
            selectedTenantId: tenantId,
        },
        getUserNotificationSubscription,
    );

    const {
        data, isValidating: notificationsLoading, mutate: notificationMutate,
    } = useSWR(
        {
            url: notificationEntryUri,
            selectedAccountId: partitionGlobalId,
            selectedTenantId: tenantId,
            options: queryOptions,
        },
        getUserNotificationEntries,
    );

    const setParamsArrayValue = useCallback((key: string, value?: string, keysToRemove?: string[]) => {
        const newSearchParams = new URLSearchParams(window.location.search);
        newSearchParams.delete(key);
        keysToRemove?.forEach((dkey) => newSearchParams.delete(dkey));
        if (value) {
            newSearchParams.set(key, value);
        }
        setSearchParams(newSearchParams);
    }, [ setSearchParams ]);

    const setTopicGroupDefaultFilter = useCallback((filterStores: IFilterQueryParamType[], topicGroup: string | null, parentPublisher: IFilterDataType) => {
        const selectedGroup = parentPublisher.childs?.find(x => x.value === topicGroup);
        if (selectedGroup) {

            filterStores = removeFilters(filterStores, [ topicKey ]);

            if (selectedGroup.childs?.length !== 0) {
                setGroupTopicTypes(parentPublisher.childs ?? []);
                setSelectedTopicGroupFilter({
                    label: selectedGroup.label,
                    value: selectedGroup.value,
                });
                setTopicTypes(selectedGroup.childs ?? []);
                const filterData: IFilterQueryParamType = {
                    compValue: '',
                    operand: topicKey, // prop,
                    operator: 'or',
                    paramList: selectedGroup.childs,
                };
                filterStores.push(filterData);
            }
        }
        return filterStores;
    }, []);

    const setTopicDefaultFilter = useCallback((filterStores: IFilterQueryParamType[], topic: string) => {
        const [ selectedPublisher, selectedGroup, selectedTopic ] = getPublisherUsingTopicId(topic, publisherTypes);
        if (selectedPublisher && selectedTopic) {
            setSelectedPublisherFilter({
                label: selectedPublisher?.label,
                value: selectedPublisher?.value,
            });
            filterStores = removeFilters(filterStores, [ publisherKey ]);
            const filterData: IFilterQueryParamType = {
                compValue: selectedPublisher?.value ?? '',
                operand: publisherKey,
                operator: 'eq',
            };
            filterStores.push(filterData);

            setGroupTopicTypes(selectedPublisher.childs ?? []);

            setSelectedTopicGroupFilter({
                label: selectedGroup.label,
                value: selectedGroup.value,
            });
            setTopicTypes(selectedGroup.childs ?? []);

            setSelectedTopicFilter({
                label: selectedTopic?.label,
                value: selectedTopic?.value,
            });
        }
        const filterData: IFilterQueryParamType = {
            compValue: topic,
            operand: topicKey,
            operator: 'eq',
        };
        filterStores.push(filterData);
        return filterStores;
    }, [ publisherTypes ]);

    const setDefaultFilterQuery = useCallback((
        publisher: string | null,
        topicGroup: string | null,
        topic: string | null,
        category: string | null,
        greaterThan: string | null,
        lessThan: string | null,
        isRead: string | null,
        messageParam: string | null,
        filtertenantId: string | null,
    ) => {
        let filterStores = [];
        const parentPublisher = publisherTypes.find(x => x.value === publisher);
        if (parentPublisher) {
            setSelectedPublisherFilter({
                label: parentPublisher?.label,
                value: parentPublisher?.value,
            });
            const filterData: IFilterQueryParamType = {
                compValue: parentPublisher?.value ?? '',
                operand: publisherKey,
                operator: 'eq',
            };
            filterStores.push(filterData);
        }
        if (topicGroup && parentPublisher) {

            const selectedGroup = parentPublisher.childs?.find(x => x.value === topicGroup);

            if (selectedGroup) {

                filterStores = setTopicGroupDefaultFilter(filterStores, topicGroup, parentPublisher);
            }
        }
        if (topic) {

            filterStores = setTopicDefaultFilter(filterStores, topic);
        }

        if (category) {
            const parentSeverity = severityTypes.find(x => x.value === category);
            setSelectedSeverityFilter({
                label: parentSeverity?.label ?? '',
                value: parentSeverity?.value ?? category,
            });
            const filterData: IFilterQueryParamType = {
                compValue: `'${parentSeverity?.value}'`,
                operand: categoryKey, // prop,
                operator: 'eq',
            };
            filterStores.push(filterData);
        }

        if (greaterThan) {
            const filterData: IFilterQueryParamType = {
                compValue: greaterThan.toString(),
                operand: publishedOnKey, // prop,
                operator: 'ge',
            };
            filterStores.push(filterData);
        }
        if (lessThan) {
            const filterData: IFilterQueryParamType = {
                compValue: lessThan.toString(),
                operand: publishedOnKey, // prop,
                operator: 'le',
            };
            filterStores.push(filterData);
        }
        if (isRead) {
            const filterData: IFilterQueryParamType = {
                compValue: isRead,
                operand: 'isRead',
                operator: 'eq',
            };
            filterStores.push(filterData);
        }
        if (messageParam) {
            const filterData: IFilterQueryParamType = {
                compValue: messageParam,
                operand: 'messageParam',
                operator: 'contains',
            };
            filterStores.push(filterData);
        }
        if (filtertenantId) {

            const filteredTenant = tenantsData.find(x => x.id === filtertenantId);

            if (filteredTenant) {
                setSelectedTenantFilter({
                    label: filteredTenant.name,
                    value: filteredTenant.id,
                });
            }
            const filterData: IFilterQueryParamType = {
                compValue: filtertenantId,
                operand: tenantKey,
                operator: 'eq',
            };
            filterStores.push(filterData);
        }
        setFilterQueryParamType(filterStores);
        const filtersString = getFilterQuery(filterStores);

        if (queryOptions.filter === filtersString) {
            return;
        }

        const changePageIndexOptions: ODataQueryOptions = {
            ...queryOptions,
            filter: filtersString,
        };
        setQueryOptions(changePageIndexOptions);
    }, [ publisherTypes, queryOptions, setTopicDefaultFilter, setTopicGroupDefaultFilter, severityTypes, tenantsData ]);

    const updateGridData = useCallback((pageIndex: number, pageSize: number, term: string) => {
        setPageIndex(pageIndex);
        setPageSize(pageSize);
        setSearchTerm(term);
        const filterStores = [ ...filterQueryParamType ];
        const itemIndex = filterStores.findIndex(x => x.operand === 'messageParam');
        if (itemIndex !== -1) {
            filterStores.splice(itemIndex, 1);
        }
        if (term !== '') {
            const filterData: IFilterQueryParamType = {
                compValue: term,
                operand: 'messageParam',
                operator: 'contains',
            };
            filterStores.push(filterData);
        }
        setParamsArrayValue('messageParam', term);

        setFilterQueryParamType(filterStores);
        const filtersString = getFilterQuery(filterStores);
        const changePageIndexOptions: ODataQueryOptions = {
            ...queryOptions,
            top: pageSize,
            skip: pageIndex * pageSize,
            orderBy: queryOptions.orderBy,
            filter: filtersString,
        };
        setQueryOptions(changePageIndexOptions);
    }, [ filterQueryParamType, queryOptions, setParamsArrayValue ]);

    const handleHeaderCheckbox = useCallback((isChecked: boolean) => {
        setOnlyUnread(isChecked);
        const filterStores = [ ...filterQueryParamType ];
        if (isChecked) {
            const filterData: IFilterQueryParamType = {
                compValue: 'false',
                operand: 'isRead',
                operator: 'eq',
            };
            filterStores.push(filterData);
            setParamsArrayValue('isRead', 'false');
        } else {
            const isReadindex = filterStores.findIndex(x => x.operand === 'isRead');
            filterStores.splice(isReadindex, 1);
            setParamsArrayValue('isRead');
        }
        setFilterQueryParamType(filterStores);
        const filtersString = getFilterQuery(filterStores);

        setPageIndex(DEFAULT_PAGE_INDEX);
        const queryCurrOptions: ODataQueryOptions = {
            ...queryOptions,
            top: size,
            skip: DEFAULT_PAGE_INDEX * size,
            filter: filtersString,
        };
        setQueryOptions(queryCurrOptions);
    }, [ filterQueryParamType, queryOptions, setParamsArrayValue, size ]);

    const showResetToDefault = useMemo(() => filterQueryParamType.length > 0
        , [ filterQueryParamType.length ]);
    const ResetToDefault = useCallback(() => {
        setOnlyUnread(false);
        setPageIndex(DEFAULT_PAGE_INDEX);
        const filterStores: IFilterQueryParamType[] = [];
        setSearchParams(new URLSearchParams());
        setSearchTerm('');
        setSelectedPublisherFilter(allFilterOption);
        setSelectedSeverityFilter(allFilterOption);
        setSelectedPublishedOnFilter(allFilterOption);
        setSelectedTopicGroupFilter(allFilterOption);
        setGroupTopicTypes([]);
        setTopicTypes([]);
        setSelectedTenantFilter(allFilterOption);
        setFilterQueryParamType(filterStores);
        setQueryOptions(defaultOptions);
    }, [ allFilterOption, defaultOptions, setSearchParams ]);

    const changeNotificationRead = useCallback(
        async (entry: {
            event: Event;
            row: any;
        }) => {
            if (!entry.row.isRead) {

                const originalRow = data?.value.find(x => x.id === entry.row.id);
                if (originalRow !== undefined) {
                    originalRow.isRead = true;
                }

                setloadingGrid(true);
                const request: INotificationReadRequest = {
                    notifications: [],
                    forceAllRead: false,
                };

                request.notifications = [
                    {
                        notificationId: entry.row.id,
                        read: true,
                    },
                ];
                await updateNotificationForUnread(request, tenantId, partitionGlobalId);
                setloadingGrid(false);
                dataMgr?.update(data?.value ?? null);
            }
            if (entry.row.redirectionUrl) {
                if ((entry.event as any).ctrlKey) {
                    window.open(entry.row.redirectionUrl);
                } else {
                    window.open(entry.row.redirectionUrl, '_self');
                }
            }
        }, [ tenantId, data?.value, dataMgr, partitionGlobalId ]);

    const setPublisherFilterData = useCallback((publisherFilter: IFilterDataType | undefined) => {

        if (publisherFilter !== undefined) {
            setSelectedPublisherFilter({
                label: publisherFilter?.label,
                value: publisherFilter?.value,
            });
            setGroupTopicTypes(publisherFilter.childs ?? []);
            setTopicTypes([]);
            setParamsArrayValue(publisherKey, publisherFilter?.value, [ topicKey, 'topicGroup' ]);
        } else {
            setSelectedPublisherFilter(allFilterOption);
            setGroupTopicTypes([]);
            setTopicTypes([]);
            setParamsArrayValue(publisherKey, undefined, [ topicKey, 'topicGroup' ]);
        }

        setSelectedTopicGroupFilter(allFilterOption);

        const filtersStore = removeFilters(filterQueryParamType, [ topicKey, publisherKey ]);

        if (publisherFilter !== undefined) {
            const filter = publisherFilter;
            if (filter.value !== '') {
                const filterData: IFilterQueryParamType = {
                    compValue: filter.value as string,
                    operand: publisherKey, // prop,
                    operator: 'eq',
                };
                filtersStore.push(filterData);
            }
        }
        setFilterQueryParamType(filtersStore);
        const filtersString = getFilterQuery(filtersStore);

        if (queryOptions.filter === filtersString) {
            return;
        }
        setPageIndex(DEFAULT_PAGE_INDEX);
        const changePageIndexOptions: ODataQueryOptions = {
            ...queryOptions,
            top: size,
            skip: DEFAULT_PAGE_INDEX * size,
            filter: filtersString,
        };
        setQueryOptions(changePageIndexOptions);

    }, [ allFilterOption, filterQueryParamType, queryOptions, setParamsArrayValue, size ]);

    const setTenantsFilterData = useCallback((tenantFilter: IFilterDataType | undefined) => {

        if (tenantFilter !== undefined) {
            setSelectedTenantFilter({
                label: tenantFilter?.label,
                value: tenantFilter?.value,
            });
            setParamsArrayValue(tenantKey, tenantFilter?.value);
        } else {
            setSelectedTenantFilter(allFilterOption);
            setParamsArrayValue(tenantKey, undefined);
        }

        const filtersStore = removeFilters(filterQueryParamType, [ tenantKey ]);

        if (tenantFilter?.value) {
            const filterData: IFilterQueryParamType = {
                compValue: tenantFilter.value as string,
                operand: tenantKey, // prop,
                operator: 'eq',
            };
            filtersStore.push(filterData);
        }
        setFilterQueryParamType(filtersStore);
        const filtersString = getFilterQuery(filtersStore);

        if (queryOptions.filter === filtersString) {
            return;
        }
        setPageIndex(DEFAULT_PAGE_INDEX);
        const changePageIndexOptions: ODataQueryOptions = {
            ...queryOptions,
            top: size,
            skip: DEFAULT_PAGE_INDEX * size,
            filter: filtersString,
        };
        setQueryOptions(changePageIndexOptions);

    }, [ allFilterOption, filterQueryParamType, queryOptions, setParamsArrayValue, size ]);

    const setSeverityFilterData = useCallback((severityFilter: IFilterDataType | undefined) => {
        if (severityFilter !== undefined) {
            setSelectedSeverityFilter({
                label: severityFilter?.label,
                value: severityFilter?.value,
            });
            setParamsArrayValue(categoryKey, severityFilter?.value);
        } else {
            setSelectedSeverityFilter(allFilterOption);
            setParamsArrayValue(categoryKey);
        }
        setPageIndex(DEFAULT_PAGE_INDEX);
        const filtersStore = [ ...filterQueryParamType ];
        const severityIndex = filtersStore.findIndex(x => x.operand === categoryKey);
        if (severityIndex !== -1) {
            filtersStore.splice(severityIndex, 1);
        }

        if (severityFilter !== undefined) {
            const filter = severityFilter;
            if (filter.value !== '') {
                const filterData: IFilterQueryParamType = {
                    compValue: `'${filter.value}'`,
                    operand: categoryKey, // prop,
                    operator: 'eq',
                };
                filtersStore.push(filterData);
            }
        }
        setFilterQueryParamType(filtersStore);
        const filtersString = getFilterQuery(filtersStore);

        if (queryOptions.filter === filtersString) {
            return;
        }
        const changePageIndexOptions: ODataQueryOptions = {
            ...queryOptions,
            top: size,
            skip: DEFAULT_PAGE_INDEX * size,
            filter: filtersString,
        };
        setQueryOptions(changePageIndexOptions);

    }, [ allFilterOption, filterQueryParamType, queryOptions, setParamsArrayValue, size ]);

    const setTopicGroupFilterData = useCallback((topicGroupFilter: IFilterDataType | undefined) => {

        if (topicGroupFilter) {
            setSelectedTopicGroupFilter({
                label: topicGroupFilter.label,
                value: topicGroupFilter.value,
            });
            setSelectedTopicFilter(allFilterOption);
            setTopicTypes(topicGroupFilter.childs ?? []);
            setParamsArrayValue(topicKey);
            setParamsArrayValue('topicGroup', topicGroupFilter.value);
        } else {
            setSelectedTopicGroupFilter(allFilterOption);
            setSelectedTopicFilter(allFilterOption);
            setTopicTypes([]);
            setParamsArrayValue(topicKey);
            setParamsArrayValue('topicGroup');
        }

        const filtersStore = removeFilters(filterQueryParamType, [ topicKey ]);

        if (topicGroupFilter !== undefined) {
            const filter = topicGroupFilter;

            if (filter.childs?.length !== 0) {
                const filterData: IFilterQueryParamType = {
                    compValue: '',
                    operand: topicKey, // prop,
                    operator: 'or',
                    paramList: filter.childs,
                };
                filtersStore.push(filterData);
            }
        }
        setFilterQueryParamType(filtersStore);
        const filtersString = getFilterQuery(filtersStore);

        if (queryOptions.filter === filtersString) {
            return;
        }
        setPageIndex(DEFAULT_PAGE_INDEX);
        const changePageIndexOptions: ODataQueryOptions = {
            ...queryOptions,
            top: size,
            skip: DEFAULT_PAGE_INDEX * size,
            filter: filtersString,
        };
        setQueryOptions(changePageIndexOptions);

    }, [ allFilterOption, filterQueryParamType, queryOptions, setParamsArrayValue, size ]);

    const setTopicFilterData = useCallback((topicFilter: IFilterDataType | undefined) => {

        if (topicFilter !== undefined) {
            setSelectedTopicFilter({
                label: topicFilter?.label,
                value: topicFilter?.value,
            });
            setParamsArrayValue(topicKey, topicFilter?.value);
        } else {
            setSelectedTopicFilter(allFilterOption);
            setParamsArrayValue(topicKey);
        }
        setPageIndex(DEFAULT_PAGE_INDEX);
        const filtersStore = removeFilters(filterQueryParamType, [ topicKey ]);

        if (topicFilter !== undefined) {
            const filter = topicFilter;
            if (filter.value !== '') {
                const filterData: IFilterQueryParamType = {
                    compValue: filter.value as string,
                    operand: topicKey, // prop,
                    operator: 'eq',
                };
                filtersStore.push(filterData);
            }
        } else {
            if (selectedTopicGroupFilter !== undefined) {
                const selectedTopicGroup = topicGroupTypes.find(x => x.value === selectedTopicGroupFilter?.value);
                const filterData: IFilterQueryParamType = {
                    compValue: '',
                    operand: topicKey, // prop,
                    operator: 'or',
                    paramList: selectedTopicGroup?.childs ?? [],
                };
                filtersStore.push(filterData);
            }
        }
        setFilterQueryParamType(filtersStore);
        const filtersString = getFilterQuery(filtersStore);

        if (queryOptions.filter === filtersString) {
            return;
        }

        const changePageIndexOptions: ODataQueryOptions = {
            ...queryOptions,
            top: size,
            skip: DEFAULT_PAGE_INDEX * size,
            filter: filtersString,
        };
        setQueryOptions(changePageIndexOptions);

    }, [ allFilterOption, filterQueryParamType, queryOptions, selectedTopicGroupFilter, setParamsArrayValue, size, topicGroupTypes ]);

    const setPublishedFilterData = useCallback((publishedFilter: IFilterDataType | undefined) => {

        const filterStores = [ ...filterQueryParamType ];
        const itemIndex = filterStores.findIndex(x => x.operand === publishedOnKey);
        if (itemIndex !== -1) {
            filterStores.splice(itemIndex, 1);
        }
        if (publishedFilter !== undefined) {
            setSelectedPublishedOnFilter({
                label: publishedFilter?.label,
                value: publishedFilter?.value,
            });
            const filter = publishedFilter;
            if (filter.value !== '') {
                const showTillDate = getTimeTick(filter.value);
                const filterData: IFilterQueryParamType = {
                    compValue: showTillDate.toString(),
                    operand: publishedOnKey, // prop,
                    operator: 'ge',
                };
                filterStores.push(filterData);
                setParamsArrayValue('gt', showTillDate?.toString());
            }

        } else {
            setParamsArrayValue('gt');
            setSelectedPublishedOnFilter(allFilterOption);
        }
        setPageIndex(DEFAULT_PAGE_INDEX);
        setFilterQueryParamType(filterStores);
        const filtersString = getFilterQuery(filterStores);

        const queryCurrOptions: ODataQueryOptions = {
            ...queryOptions,
            top: size,
            skip: DEFAULT_PAGE_INDEX * size,
            filter: filtersString,
        };
        setQueryOptions(queryCurrOptions);

    }, [ allFilterOption, filterQueryParamType, queryOptions, setParamsArrayValue, size ]);

    const sortGridData = useCallback((sort: ISortModel<INotificationEntry>) => {
        if (!sort.field) {
            return ;
        }
        const oldOpt = sortOpts.find(opt => opt.field === sort.field);
        const sortingOldString = oldOpt ? `${oldOpt.field} ${oldOpt.direction}` : '';
        const noDirection = sort.direction === '' ? 'desc' : sort.direction;
        const sortingString = `${sort.field} ${noDirection}`;

        if (sortingOldString === sortingString) {
            return ;
        }
        const sortOptions: ODataQueryOptions = {
            ...queryOptions,
            orderBy: [ sortingString ],
        };

        setSortOpts([ sort ]);
        setQueryOptions(sortOptions);
        return true;
    }, [ queryOptions, sortOpts ]);

    const unreadMessageDecoration = useCallback((text: string, isRead: boolean, isMain: boolean, isSeverity: boolean) =>
        elementToNode(<ApolloThemeProvider>
            <IntlProvider>
                <NotificationCellComponent
                    isMain={isMain}
                    isRead={isRead}
                    text={text}
                    severity={isSeverity} />
            </IntlProvider>
        </ApolloThemeProvider>)
    , [ ]);

    const rowAction = useCallback(async (row: INotificationEntry, actionType: INotificationActionType, event: any) => {
        event.stopPropagation();
        event.preventDefault();
        switch (actionType) {
            case INotificationActionType.UnsubscribeTopic:
            {
                setloadingGrid(true);
                const topicState: IUpdateUserSubscriptionsRequestData = {
                    topicId: row.topicId,
                    isSubscribed: false,
                };
                try {
                    await updateUserNotificationTopicSubscription([ topicState ], tenantId);
                    setloadingGrid(false);
                    createNotification(translate({ id: 'CLIENT_NOTIFICATION_MUTED' }, { 0: row.topicName }),
                        notificationType.SUCCESS, {
                            horizontal: 'right',
                            vertical: 'top',
                        });
                } catch (e) {
                    handleProfileError(e as Error);
                    setloadingGrid(false);
                }
                break;
            }
            case INotificationActionType.UnsubscribeTenant:
            {
                setloadingGrid(true);
                try {
                    await UnsubscribeTenantNotificationSubscription(tenantId);
                    setloadingGrid(false);
                    createNotification(translate({ id: 'CLIENT_NOTIFICATION_MUTED' }, { 0: tenantName }),
                        notificationType.SUCCESS, {
                            horizontal: 'right',
                            vertical: 'top',
                        });
                } catch (e) {
                    handleProfileError(e as Error);
                    setloadingGrid(false);
                }
                break;
            }
            case INotificationActionType.Delete:
            {
                try {
                    setloadingGrid(true);
                    await deleteNotification(row.id, tenantId, partitionGlobalId);
                    createNotification(translate({ id: 'CLIENT_NOTIFICATION_CLEAR' }), notificationType.SUCCESS, {
                        horizontal: 'right',
                        vertical: 'top',
                    });
                    if (data !== undefined) {
                        data.value = data.value.filter(x => x.id !== row.id);
                        data['@odata.count'] = data['@odata.count'] - 1;
                        dataMgr?.update(data.value ?? null);
                    }
                    setloadingGrid(false);
                } catch (e) {
                    handleProfileError(e as Error);
                }
                break;
            }
            case INotificationActionType.ToggleRead:
                {
                    setloadingGrid(true);
                    const originalRow = data?.value.find(x => x.id === row.id);
                    if (originalRow !== undefined) {
                        originalRow.isRead = !row.isRead;
                    }

                    const request: INotificationReadRequest = {
                        notifications: [],
                        forceAllRead: false,
                    };

                    request.notifications = [
                        {
                            notificationId: row.id,
                            read: !row.isRead,
                        },
                    ];
                    await updateNotificationForUnread(request, tenantId, partitionGlobalId);
                    notificationMutate();
                    setloadingGrid(false);
                }
                break;
        }

    }, [ createNotification, data, dataMgr, handleProfileError, notificationMutate, partitionGlobalId, tenantId, tenantName, translate ]);

    useEffect(() => {
        if (publishersData !== undefined) {
            const publishers = publishersData.publishers as INotificationPublisherDto[];
            const services = getServiceList(publishers, allFilterOption.label);
            setPublisherTypes(services);
        }
        const tenantsFilter: IFilterDataType[] = tenantsData.map((tenant) => ({
            value: tenant.id,
            label: tenant.name,
        }));

        setTenants(tenantsFilter);
    }, [
        allFilterOption.label,
        allFilterOption.value,
        publishersData,
        tenantsData,
    ]);

    useEffect(() => {
        setDefaultFilterQuery(searchParams.get(publisherKey), searchParams.get('topicGroup'),
            searchParams.get(topicKey), searchParams.get(categoryKey),
            searchParams.get('gt'), searchParams.get('lt'), searchParams.get('isRead'), searchParams.get('messageParam'), searchParams.get('tenantId'));
    }, [ searchParams, setDefaultFilterQuery ]);

    const columnDef: Array<IColumn<INotificationEntry>> = useMemo(() => [
        {
            property: categoryKey,
            id: 'priorityColumn',
            title: translate({ id: 'CLIENT_NOTIFICATION_ENTRY_SEVERITY' }),
            sortable: true,
            resizable: false,
            sort: sortOpts[0].field === categoryKey ? sortOpts[0].direction : '',
            width: 9,
            customCell: (entry) => unreadMessageDecoration(entry.category, false, true, true),
        },
        {
            property: tenantKey,
            title: translate({ id: 'CLIENT_TENANT' }),
            visible: false,
            resizable: false,
            dropdownFilterOpts: {
                value: {
                    value: selectedTenantFilter?.value ?? translate({ id: 'CLIENT_GRID_FILTER_ALL' }),
                    label: selectedTenantFilter?.label ?? translate({ id: 'CLIENT_GRID_FILTER_ALL' }),
                },
                items: tenants.map((valueType) => ({
                    value: valueType.value,
                    label: valueType.label,
                })),
                filterChange: (current) => {

                    const parentPublisher = tenants.find(x => x.value === current?.value);
                    setTenantsFilterData(parentPublisher);
                },
            },
        },
        {
            property: publisherKey,
            title: translate({ id: 'CLIENT_NOTIFICATION_ENTRY_PUBLISHER_NAME' }),
            visible: false,
            resizable: false,
            dropdownFilterOpts: {
                value: {
                    value: selectedPublisherFilter?.value ?? translate({ id: 'CLIENT_GRID_FILTER_ALL' }),
                    label: selectedPublisherFilter?.label ?? translate({ id: 'CLIENT_GRID_FILTER_ALL' }),
                },
                items: publisherTypes.map((valueType) => ({
                    value: valueType.value,
                    label: valueType.label,
                })),
                filterChange: (current) => {

                    const parentPublisher = publisherTypes.find(x => x.value === current?.value);
                    setPublisherFilterData(parentPublisher);
                },
            },
        },
        {
            property: 'publisherName',
            title: translate({ id: 'CLIENT_NOTIFICATION_ENTRY_PUBLISHER_NAME' }),
            sortable: false,
            resizable: false,
            width: 10,
            customCell: function customCell(entry) {
                return unreadMessageDecoration(entry.publisherName, entry.isRead, false, false);
            },
        },
        {
            property: 'topicgroupId',
            title: translate({ id: 'CLIENT_NOTIFICATION_ENTRY_TOPIC_GROUP_NAME' }),
            visible: false,
            resizable: false,
            dropdownFilterOpts: {
                value: {
                    value: selectedTopicGroupFilter?.value ?? translate({ id: 'CLIENT_GRID_FILTER_ALL' }),
                    label: selectedTopicGroupFilter?.label ?? translate({ id: 'CLIENT_GRID_FILTER_ALL' }),
                },
                visible: topicGroupTypes.length > 0,
                items:
                topicGroupTypes.map((valueType) => ({
                    value: valueType.value,
                    label: valueType.label,
                })),
                filterChange: (current) => {
                    const parentTopicGroup = topicGroupTypes.find(x => x.value === current?.value);
                    setTopicGroupFilterData(parentTopicGroup);
                },
            },
        },
        {
            property: topicKey,
            title: translate({ id: 'CLIENT_NOTIFICATION_ENTRY_TOPIC_NAME' }),
            visible: false,
            resizable: false,
            dropdownFilterOpts: {
                value: {
                    value: selectedTopicFilter?.value ?? translate({ id: 'CLIENT_GRID_FILTER_ALL' }),
                    label: selectedTopicFilter?.label ?? translate({ id: 'CLIENT_GRID_FILTER_ALL' }),
                },
                visible: topicTypes.length > 0,
                items:
                    topicTypes.map((valueType) => ({
                        value: valueType.value,
                        label: valueType.label,
                    })),
                filterChange: (current) => {
                    const parentTopic = topicTypes.find(x => x.value === current?.value);
                    setTopicFilterData(parentTopic);
                },
            },
        },
        {
            property: 'topicName',
            title: translate({ id: 'CLIENT_NOTIFICATION_ENTRY_TOPIC_NAME' }),
            sortable: false,
            resizable: false,
            width: 20,
            customCell: function customCell(entry) {
                return unreadMessageDecoration(entry.topicName, entry.isRead, false, false);
            },
        },
        {
            property: categoryKey,
            id: 'priorityFilter',
            title: translate({ id: 'CLIENT_NOTIFICATION_ENTRY_SEVERITY' }),
            visible: false,
            resizable: false,
            dropdownFilterOpts: {
                value: {
                    value: selectedSeverityFilter?.value ?? translate({ id: 'CLIENT_GRID_FILTER_ALL' }),
                    label: selectedSeverityFilter?.label ?? translate({ id: 'CLIENT_GRID_FILTER_ALL' }),
                },
                visible: enableNotificationSeverityFilter,
                items: severityTypes,
                filterChange: (current) => {

                    const parentSeverity = severityTypes.find(x => x.value === current?.value);
                    setSeverityFilterData(parentSeverity);
                },
            },
        },
        {
            property: 'message',
            title: translate({ id: 'CLIENT_NOTIFICATION_ENTRY_MESSAGE' }),
            sortable: false,
            resizable: false,
            searchable: true,
            width: 51,
            customCell: function customCell(entry) {
                return unreadMessageDecoration(entry.message, entry.isRead, true, false);
            },
        },
        {
            property: publishedOnKey,
            id: 'publishedOnColumn',
            title: translate({ id: 'CLIENT_NOTIFICATION_ENTRY_TIMESTAMP' }),
            sortable: true,
            sort: sortOpts[0].field === publishedOnKey ? sortOpts[0].direction : '',
            width: 10,
            resizable: false,
            customCell: function customCell(entry) {
                return unreadMessageDecoration(userReadableTime(parseInt(entry.publishedOn, 10)), entry.isRead, false, false);
            },
        },
        {
            property: publishedOnKey,
            id: 'publishedOnFilter',
            title: translate({ id: 'CLIENT_NOTIFICATION_ENTRY_TIMESTAMP' }),
            visible: false,
            resizable: false,
            dropdownFilterOpts: {
                value: {
                    value: selectedPublishedOnFilter?.value ?? translate({ id: 'CLIENT_GRID_FILTER_ALL' }),
                    label: selectedPublishedOnFilter?.label ?? translate({ id: 'CLIENT_GRID_FILTER_ALL' }),
                },
                visible: publishedOn.length > 0,
                items:
                    publishedOn.map((valueType) => ({
                        value: valueType.value,
                        label: valueType.label,
                    })),
                filterChange: (current) => {
                    const parentTopic = publishedOn.find(x => x.value === current?.value);
                    setPublishedFilterData(parentTopic);
                },
            },
        },
    ], [
        translate,
        sortOpts,
        selectedTenantFilter?.value,
        selectedTenantFilter?.label,
        tenants,
        selectedPublisherFilter?.value,
        selectedPublisherFilter?.label,
        publisherTypes,
        selectedTopicGroupFilter?.value,
        selectedTopicGroupFilter?.label,
        topicGroupTypes,
        selectedTopicFilter?.value,
        selectedTopicFilter?.label,
        topicTypes,
        selectedSeverityFilter?.value,
        selectedSeverityFilter?.label,
        enableNotificationSeverityFilter,
        severityTypes,
        selectedPublishedOnFilter?.value,
        selectedPublishedOnFilter?.label,
        publishedOn,
        unreadMessageDecoration,
        setTenantsFilterData,
        setPublisherFilterData,
        setTopicGroupFilterData,
        setTopicFilterData,
        setSeverityFilterData,
        userReadableTime,
        setPublishedFilterData,
    ]);

    const extraActionHeaderButtons: Array<GridHeaderButtons<INotificationEntry>> = useMemo(() => [
        {
            id: 'addProperty',
            type: 'main',
            buttonType: 'mat-flat-button',
            color: 'primary',
            customTemplate: () => elementToNode(
                <FormControlLabel
                    control={<Checkbox
                        className={classes.showOnlyUnread}
                        checked={onlyUnread}
                        onChange={(e) => handleHeaderCheckbox(e.target.checked)} />}
                    label={<UiText className={classes.showOnlyUnread}>
                        {translate({ id: 'CLIENT_NOTIFICATION_PAGE_UNREAD_ONLY' })}
                    </UiText>} />
            ),
        },
        {
            id: 'resetDefaultProperty',
            type: 'inline',
            buttonType: 'mat-flat-button',
            color: 'primary',
            customTemplate: () => elementToNode(
                <Link
                    align="left"
                    underline='none'
                    visibility={showResetToDefault ? 'visible' : 'hidden'}
                    className={classes.link}
                    target="_blank"
                    onClick={() => ResetToDefault()}
                    rel="noopener noreferrer">
                    {translate({ id: 'CLIENT_NOTIFICATIONS_FILTER_RESET_DEFAULT' })}
                </Link>
            ),
        },
    ], [ ResetToDefault, classes.link, classes.showOnlyUnread, handleHeaderCheckbox, onlyUnread, showResetToDefault, translate ]);

    const extraActionRowButtons: Array<GridRowButtons<INotificationEntry>> = useMemo(() => [
        {
            id: 'circle',
            label: translate({ id: 'CLIENT_EDIT' }),
            size: '20px',
            customTemplate: (row: INotificationEntry) => (
                elementToNode(<PortalTooltip text={row.isRead ? translate({ id: 'CLIENT_NOTIFICATION_UNREAD' }) :
                    translate({ id: 'CLIENT_NOTIFICATION_READ' })}>
                    <IconButton
                        size='small'
                        aria-label={row.isRead ? translate({ id: 'CLIENT_NOTIFICATION_UNREAD' }) :
                            translate({ id: 'CLIENT_NOTIFICATION_READ' })}
                        color={`${row.isRead ? 'inherit' : 'primary'}`}
                        onClick={(event) => rowAction(row, INotificationActionType.ToggleRead, event)}>
                        <CircleIcon className={classes.circleiconClass} />
                    </IconButton>
                </PortalTooltip>
                )
            ),
        },
        {
            id: 'Menu',
            label: translate({ id: 'CLIENT_ACTION' }),
            size: '8px',
            customTemplate: (row: INotificationEntry) => (
                elementToNode(<ApolloThemeProvider>
                    <IntlProvider>
                        <NotificationMoreRowActionsComponent {...{
                            notification: row,
                            action: rowAction,
                        }} />
                    </IntlProvider>
                </ApolloThemeProvider>)
            ),
        },
    ], [ classes.circleiconClass, rowAction, translate ]);

    const configuration: IGridOptions<any> = useMemo(() => ({
        loading: notificationsLoading || loadingGrid,
        columns: columnDef,
        collapseFiltersCount: 5,
        headerOptions: {
            search: true,
            searchValue: searchTermText,
            searchTerm: (term: string) => updateGridData(DEFAULT_PAGE_INDEX, size, term),
            gridHeaderButtons: extraActionHeaderButtons,

        },
        disabled: false,
        selectable: false,
        refreshable: true,
        refresh: notificationMutate,
        gridRowButtons: extraActionRowButtons,
        data: data?.value ?? [],
        sortChange: function sortChange(current) {
            sortGridData(current);
            return true;
        },
        footerOptions: {
            length: data?.['@odata.count'] ?? 0,
            pageSizes: [ 5, 10, 25, 50 ],
            pageIndex: index,
            pageSize: size,
            pageChange: ({
                pageIndex, pageSize,
            }: {
                pageIndex: number; pageSize: number;
            }) => {
                if (index !== pageIndex || size !== pageSize) {
                    updateGridData(pageIndex, pageSize, searchTermText);
                }
            },
        },
        rowClick: function rowClick(entry) {
            changeNotificationRead(entry);

        },
        toggleColumns: false,
        onGridApi: ({ dataManager }: {
            dataManager: DataManager<INotificationEntry>;
        }) => {
            setDataMgr(dataManager);
        },
    }), [
        notificationsLoading,
        loadingGrid,
        columnDef,
        extraActionHeaderButtons,
        notificationMutate,
        extraActionRowButtons,
        data,
        index,
        size,
        updateGridData,
        sortGridData,
        searchTermText,
        changeNotificationRead,
    ]);

    useEffect(() => {
        if (apGridRef.current && configuration) {
            apGridRef.current.configuration = configuration;
        }
    }, [ configuration ]);

    return (
        <div style={{
            padding: '24px',
            width: '100%',
        }}>
            <UiText
                className={classes.heading}
                role="heading"
                aria-level={1}>
                {translate({ id: 'CLIENT_NOTIFICATIONS' })}
            </UiText>

            { !EnableNotificationspageV2
                ? (
                    <ApDataGrid
                        data-cy="ap-data-grid-notification"
                        ref={apGridRef}
                    />
                ) : (
                    <NotificationPageComponentV2 />
                )}

        </div>
    );
};

export default NotificationPageComponent;
