import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    type IUserAuthPDPModel,
    ResourceGroup,
    ResourceType,
} from '@experiences/interfaces';
import type { PropsWithChildren } from 'react';
import React, {
    createContext,
    useEffect,
} from 'react';
import {
    useDispatch,
    useSelector,
} from 'react-redux';
import useSWR from 'swr';

import useUserInfo from '../../auth/hooks/UserInfo';
import useIsOnPrem from '../../common/hooks/useIsOnPrem';
import { post } from '../../services/utility/Requests';
import { updateAdminStatus } from '../../store/action/UserProfileAction';
import {
    accountGlobalId,
    isOrgAdminSelector,
    isTenantAdminSelector,
} from '../../store/selectors';

const UserAuthPDPModelContext = createContext<IUserAuthPDPModel | undefined>(undefined);

async function fetchUserAuthPDP({
    url, payload,
}: { url: string; payload: any }) {
    return post<IUserAuthPDPModel>(url, { body: payload });
}

// type AuthAction = 'EDIT' | 'VIEW' | 'DELETE' | 'CREATE' | 'UPDATE';

// TODO: DO NOT DELETE. This will be used in the future to get user permissions across admin
// export const useGetUserPermissions = ({
//     resource, resourceType, action, tenantId = '',
// }: { resource: string; resourceType: string; action: AuthAction; tenantId?: string }) => {
//     const permissions = React.useContext(UserAuthPDPModelContext);

//     const filteredPermission = permissions?.actions?.filter((actionIterator) =>
//         actionIterator.ResourceGroup.toLowerCase() === resource.toLowerCase()
//         && actionIterator.ResourceType.toLowerCase() === resourceType.toLowerCase()
//         && actionIterator.ResourceAction.toUpperCase() === action.toUpperCase()
//         && actionIterator.Scope.toLowerCase().includes(tenantId.toLowerCase()));

//     if (!filteredPermission) {
//         return false;
//     }

//     return filteredPermission.length > 0;
// };

export const UserAuthPDPModelProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const EnableTenantAdminControl = useFeatureFlagValue(Features.EnableTenantAdminControl.name);

    const { token } = useUserInfo();

    const isOrgAdmin = useSelector(isOrgAdminSelector);
    const isTenantAdmin = useSelector(isTenantAdminSelector);
    const userId = useSelector(accountGlobalId);

    const isOnPrem = useIsOnPrem();
    const dispatch = useDispatch();

    const {
        data: userAuthPDPModel, isLoading, error,
    } = useSWR(
        EnableTenantAdminControl && !isOnPrem && token && userId ? {
            url: `/api/centralizedaccess/getallactions`,
            payload: {
                RequestParams: {
                    Scope: '/',
                    SecurableId: userId,
                },
            },
        } : null,
        fetchUserAuthPDP
    );

    useEffect(() => {
        // If user is not an org admin or tenant admin, and userAuthPDPModel is not null, then dispatch updateAdminStatus
        if (!isOrgAdmin && !isTenantAdmin && userAuthPDPModel?.actions?.some(action =>
            action.ResourceGroup.toLowerCase() === ResourceGroup.CentralizedAccess
            && action.ResourceType.toLowerCase() === ResourceType.AdministrationPage
            && action.ResourceAction.toLowerCase() === 'view')) {
            dispatch(updateAdminStatus('TENANT_ADMIN'));
        // if data is no longer loading and userAuthPDPModel is still null, then dispatch updateAdminStatus
        } else if (!isLoading && !userAuthPDPModel && error) {
            dispatch(updateAdminStatus());
        }
    }, [ dispatch, error, isLoading, isOrgAdmin, isTenantAdmin, userAuthPDPModel ]);

    // If error, short circuit and return children
    return <UserAuthPDPModelContext.Provider value={userAuthPDPModel}>
        {isOrgAdmin || isTenantAdmin || isOnPrem || error
            ? children
            : (
                isLoading && isTenantAdmin == null
                    ? undefined
                    : children
            )}
    </UserAuthPDPModelContext.Provider>;
};
