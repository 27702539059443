import type { SourceFilter } from '@uipath/portal-shell/loader';

import type { IDirectoryEntry } from '../../common/interfaces/cis/directory';
import { DirectoryEntityType } from '../../common/interfaces/cis/directory';
import {
    get,
    post,
} from '../utility/Requests';

const userPartitionUrl = `/api/identity/Directory`;

export function getDirectoryEntities(
    {
        url: _url, partitionGlobalId, sourceFilter, startsWith,
    }: {
        url: string;
        partitionGlobalId: string;
        sourceFilter: SourceFilter[];
        startsWith: string;
    }) {
    return get<IDirectoryEntry[]>(`${userPartitionUrl}/Search/${partitionGlobalId}`, {
        urlParams: {
            sourceFilter,
            startsWith,
        },
    });
}

export function resolveByName(
    {
        url: _url, partitionGlobalId, entityName, entityType,
    }: {
        url: string;
        partitionGlobalId: string;
        entityName: string;
        entityType: DirectoryEntityType;
    }) {
    return post<IDirectoryEntry>(`${userPartitionUrl}/ResolveByName/${partitionGlobalId}`, {
        body: {
            entityName,
            entityType: entityType === DirectoryEntityType.user ? 'user' : 'group',
        },
    });
}

export function resolveById(
    {
        url: _url, partitionGlobalId, entityId, entityType, groupIds,
    }: {
        url?: string;
        partitionGlobalId: string;
        entityId: string;
        entityType: DirectoryEntityType;
        groupIds?: string[];
    }) {
    return post<IDirectoryEntry>(`${userPartitionUrl}/ResolveById/${partitionGlobalId}`, {
        body: {
            entityId,
            entityType: entityType === DirectoryEntityType.user ? 'user' : 'group',
            groupIds,
        },
    });
}

export async function bulkResolveByName(
    {
        url = userPartitionUrl, partitionGlobalId, entityNames, entityType,
    }: {
        url?: string;
        partitionGlobalId: string;
        entityNames: string[];
        entityType: DirectoryEntityType;
    }) {

    const chunkSize = 20; // max chunk size for identity BulkResolveByName API is 20
    const chunks = [];

    for (let i = 0; i < entityNames.length; i += chunkSize) {
        chunks.push(entityNames.slice(i, i + chunkSize));
    }

    const resolvedChunks = await Promise.all(chunks.map(chunk =>
        post<Record<string, IDirectoryEntry>>(`${url}/BulkResolveByName/${partitionGlobalId}`, {
            body: {
                entityNames: chunk,
                entityType: entityType === DirectoryEntityType.user ? 'user' : 'group',
            },
        })
    ));

    return resolvedChunks.flatMap(chunk => Object.values(chunk));
}
