import type {
    TMOData,
    TMTestExecution,
} from '@experiences/interfaces';
import { get } from '@experiences/util';

export function getTMExecutions(accessToken: string, tenantName: string, status: string, descOrderBy: keyof TMTestExecution, top: number) {
    const url = `/${tenantName}/testmanager_/api/v2/testexecutionsfromtenant?executionStatus=${status}&orderby=${descOrderBy}%20desc&top=${top}`;

    return get<TMOData<TMTestExecution>>(url, {
        accessToken,
        stripPortalPath: true,
    });
}
