import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { UiHomePage } from '@experiences/home-page';
import { GlobalStyles } from '@experiences/theme';
import { UiAlertBanner } from '@experiences/ui-common';
import LaunchIcon from '@mui/icons-material/Launch';
import { makeStyles } from '@mui/styles';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import useCheckAuthTypes from '../../auth/hooks/CheckAuthType';
import useUserInfo from '../../auth/hooks/UserInfo';
import { hyperLink } from '../../common/constants/Constant';
import { useCheckSSOEnabled } from '../../common/hooks/useCheckSSOEnabled';
import useIsDedicated from '../../common/hooks/useIsDedicated';
import useIsOnPrem from '../../common/hooks/useIsOnPrem';
import useProfileStateName from '../../common/hooks/useProfileStateName';
import {
    accountLogicalName,
    isUnlicensedSelector,
    profile,
} from '../../store/selectors';
import Carousel from '../carousel/CarouselComponent';
import { UiLicenseExpiredBanner } from '../common/UiLicenseExpiredBanner';
import UiLicenseInGracePeriodBanner from '../common/UiLicenseInGracePeriodBanner';
import { UiPanel } from '../common/UiPanel/UiPanel';
import { UiTrialPerSkuLicenseInGracePeriodBanners } from '../common/UiTrialPerSkuLicenseInGracePeriodBanners';
import { HomePageLicensesComponent } from './HomePageLicensesComponent';
import { HomePageSidebarLinksComponent } from './HomePageSidebarLinksComponent';

const useStyles = makeStyles((theme) => GlobalStyles(theme));

const HomePageComponent = () => {
    const classes = useStyles();

    const [ firstName, lastName ] = useProfileStateName();
    const { formatMessage: translate } = useIntl();
    const { token } = useUserInfo();

    const organizationName = useSelector(accountLogicalName);
    const userProfile = useSelector(profile);
    const isUnlicensedMode = useSelector(isUnlicensedSelector);
    const isOnPrem = useIsOnPrem();
    const { checkTokenTypeIsAuth0: isAuth0 } = useCheckAuthTypes();

    const EnableHomePageCarousel = useFeatureFlagValue(
        Features.EnableHomePageCarousel.name,
    );

    const isDedicated = useIsDedicated();

    const isProTrial = userProfile.accountUserDto.accountType === 'PRO-TRIAL';

    const userName = useMemo(() => firstName + ' ' + lastName, [ firstName, lastName ]);

    const greeting = useMemo((): string => {
        const currentHour = new Date().getHours();

        if (currentHour >= 3 && currentHour < 12) {
            return translate(
                { id: 'CLIENT_GOOD_MORNING_USER' },
                { 0: userName || translate({ id: 'CLIENT_USER' }) },
            );
        } else if (currentHour >= 12 && currentHour < 18) {
            return translate(
                { id: 'CLIENT_GOOD_AFTERNOON_USER' },
                { 0: userName || translate({ id: 'CLIENT_USER' }) },
            );
        }
        return translate(
            { id: 'CLIENT_GOOD_EVENING_USER' },
            { 0: userName || translate({ id: 'CLIENT_USER' }) },
        );
    }, [ translate, userName ]);

    const isSSOConnectionEnabled = useCheckSSOEnabled();

    return (
        <UiPanel
            header={{
                title: greeting,
                fontSize: '18px',
                fontWeight: 700,
                isGreeting: true,
            }}
            sideBar={<HomePageSidebarLinksComponent />}
            licenseInGracePeriodBanner={<UiLicenseInGracePeriodBanner />}
            licenseExpiredBanner={<UiLicenseExpiredBanner />}
            trialPerSkuLicenseInGracePeriodBanners={
                <UiTrialPerSkuLicenseInGracePeriodBanners />
            }
            banner={
                <>
                    {EnableHomePageCarousel && !isProTrial && <Carousel />}
                    {isSSOConnectionEnabled && isAuth0 && (
                        <UiAlertBanner
                            type="info"
                            closeable={false}>
                            {translate(
                                { id: 'CLIENT_AZURE_AUTH0_LOGIN' },
                                {
                                    0: (
                                        <a
                                            className={classes.a}
                                            href={`${window.location.origin}/${organizationName}`}
                                        >
                                            {`${window.location.origin}/${organizationName}`}
                                        </a>
                                    ),
                                },
                            )}
                            <a
                                className={classes.a}
                                target="_blank"
                                rel="noreferrer"
                                href={hyperLink.USER_MANAGEMENT}
                                style={{
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    marginLeft: '8px',
                                }}
                            >
                                {translate({ id: 'CLIENT_AZURE_AD_CHECK_LINK_EMAILS_TOOLTIP_LEARN_MORE' })}
                                <LaunchIcon
                                    style={{
                                        fontSize: '14px',
                                        marginLeft: '4px',
                                    }}
                                />
                            </a>
                        </UiAlertBanner>
                    )}
                </>
            }
        >
            {isOnPrem && !isDedicated && isUnlicensedMode && <HomePageLicensesComponent />}
            <UiHomePage
                profile={userProfile}
                token={token} />
        </UiPanel>
    );
};

export default HomePageComponent;
