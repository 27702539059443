import { UiSuspensefulOutlet } from '@experiences/ui-common';
import type { UiRouteObject } from '@experiences/util';
import React from 'react';

import {
    HostOrganizationLicense,
    HostOrganizationSettings,
    OrganizationHome,
} from '../../../common/constants/RouteNames';
import { routePaths } from '../../../common/constants/routePaths';

const OrganizationHomeComponent = React.lazy(() => import('../../organizationsettings/OrganizationHome'));
const HostOrganizationLicenseComponent = React.lazy(() => import('./OrganizationLicense'));
const HostOrganizationSettingsComponent = React.lazy(() => import('./OrganizationSettings'));

export const HostOrganizationRoutes: UiRouteObject = {
    path: routePaths.adminHostOrganizationHome,
    element: <UiSuspensefulOutlet />,
    children: [
        {
            index: true,
            redirectUrls: [ OrganizationHome ],
            element: <OrganizationHomeComponent />,
        },
        {
            path: routePaths.adminHostOrganizationLicense,
            redirectUrls: [ HostOrganizationLicense ],
            element: <HostOrganizationLicenseComponent />,
        },
        {
            path: routePaths.adminHostOrganizationSettings,
            redirectUrls: [ HostOrganizationSettings ],
            element: <HostOrganizationSettingsComponent />,
        },
    ],
};
