import { getEnvVariableValue } from '@experiences/util';
import { useMemo } from 'react';
import {
    matchPath,
    useLocation,
} from 'react-router-dom';

import { useIsSettingsRouteMatched } from '../../util/RouteUtil';
import * as RouteNames from '../constants/RouteNames';
import useIsProfileRoute from './useIsProfileRoute';

const useProductName = () => {

    const isProfileRoute = useIsProfileRoute();
    const isSettingsRoute = useIsSettingsRouteMatched();
    const { pathname } = useLocation();
    const isTenantCreateRoute = matchPath({ path: RouteNames.TenantCreate }, pathname);

    const showTrademark = useMemo(
        () => !isProfileRoute && !isSettingsRoute && !isTenantCreateRoute,
        [ isProfileRoute, isSettingsRoute, isTenantCreateRoute ],
    );

    const productName = useMemo<string>(
        () => {
            if (isProfileRoute) {
                return '';
            }
            return ((isSettingsRoute || isTenantCreateRoute) && 'Administration') ||
                getEnvVariableValue('PRODUCT_NAME');
        },
        [ isProfileRoute, isSettingsRoute, isTenantCreateRoute ],
    );

    return {
        productName,
        showTrademark,
    };
};

export default useProductName;
