import type { IResource } from '../common/interfaces/resource';
import {
    get,
    put,
} from './utility/Requests';

export const resourceCenterUrl = '/api/resourceCenter';

export function getLatestStudioVersion() {
    return get<IResource>(`${resourceCenterUrl}/getLatestVersion`);
}

export function updateMarketoWithDownloadEdition(payload: any) {
    return put(`${resourceCenterUrl}/updateMarketoWithDownloadEdition`, { body: payload });
}
