export interface ICreateEditTenantPayload {
    name: string;
    services: string[];
    color: string;
    region: any;
    isDefaultTenant?: boolean;
    isCanaryTenant?: boolean;
    environment?: keyof typeof TenantEnvironment;
    customProperties?: ITenantPayloadCustomProperties;
}

export enum TenantEnvironment {
    Production = 'Production',
    NonProduction = 'NonProduction',
}

export interface IEditTenantPayload extends Omit<ICreateEditTenantPayload, 'services' | 'region'> {
    services: { [key: string]: boolean };
}

export interface ITenantPayloadCustomProperties {
    allocatedProductLicenses: { [ key: string ]: IProductRequested[] };
    isCanaryTenant: boolean;
}

export interface IProductRequested {
    code: string;
    quantity: number;
}

export interface IEditServicesPayload {
    name: string;
    services: string[];
}

export interface IVpnGatewayPayload {
    name: string;
    addressSpace: string;
    gatewaySku: string;
}
