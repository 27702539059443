import type {
    IMigrationRequest,
    IMigrationResponse,
    IMigrationStatus,
    IRegionsPerService,
} from '../../common/interfaces/gws';
import {
    axiosDelete,
    get,
    post,
} from '../utility/Requests';

const orgMigrationUrl = '/api/move/organizations';

export function scheduleOrganizationMove(scheduleMoveRequest: IMigrationRequest): Promise<IMigrationResponse> {
    return post<IMigrationResponse>(`${orgMigrationUrl}/scheduleOrgMove`, { body: scheduleMoveRequest });
}

export function cancelOrganizationMove() {
    return axiosDelete(`${orgMigrationUrl}/cancelOrgMove`);
}

export function getOrganizationMigrationStatus(): Promise<IMigrationStatus> {
    return get<IMigrationStatus>(`${orgMigrationUrl}/getScheduledOrgMigrationStatus`);
}

export function getAvailableRegionsPerService() {
    return get<IRegionsPerService>(`${orgMigrationUrl}/availableRegionsPerService`);
}
