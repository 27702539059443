import {
    axiosDelete,
    get,
    post,
} from '../utility/Requests';

export const robotUnitsApiPath = '/api/hypervisor/robotUnit';
const initialApiPath = '/api/hypervisor/vpnGateways';
export const vpnGatewaysUri = initialApiPath + '/';
const createVpnGatewayUri = initialApiPath + '/CreateVpnGateway';
const editVpnGatewayUri = initialApiPath + '/EditVpnGateway';
const createVpnGatewayConnectionUri = initialApiPath + '/CreateConnection';
const editVpnGatewayConnectionUri = initialApiPath + '/EditConnection';

const getHeaders = (selectedTenantId: string) => ({
    extendRequestHeaders: 'true',
    'X-UiPath-Internal-TenantId': selectedTenantId,
});

export interface DnsServer {
    dnsServer: string;
}

export interface IVpnGatewayData {
    Key: string;
    Name: string;
    Description: string;
    VnetAddressSpace: string;
    VnetGatewaySubnetAddressSpace: string;
    PublicIp: string;
    ResourceId: string;
    State: string;
    Sku: string;
    Location: string;
    InsertedDateTime: string;
    DnsServers: string[];
    Connections: IVpnGatewayConnectionPeering[];
    Peerings: IVpnGatewayConnectionPeering[];
    MaxUserConnections: number;
    Freezing: FreezingMetadata;
    GatewayType: string;
}

interface FreezingMetadata {
    Frozen: boolean;
    DaysToFreeze: number;
}

export interface IVpnGatewaysResponse {
    value: IVpnGatewayData[];
}

export interface ICreateEditVpnGatewayData {
    Name: string;
    VnetAddressSpace?: string;
    DnsServers?: DnsServer[];
    Sku?: string;
    Type?: string;
}

export interface ICreateEditVpnGatewayPayload {
    Name: string;
    VnetAddressSpace?: string;
    DnsServers?: string[];
    Sku?: string;
    Type?: string;
}

export interface IVpnGatewayConnectionPeering {
    Key: string;
    Name: string;
    TargetIpAddress: string;
    TargetAddressSpaces: string[];
    State: string;
    Internal?: boolean;
    ExpressRouteTargetResourceId?: string;
    IpSecPolicy?: IpSecPolicy;
}

export interface IVpnGatewayConnectionCreateEditPayload {
    SharedKey?: string;
    Name: string;
    TargetIpAddress?: string;
    TargetAddressSpaces?: string[];
    Internal?: boolean;
    ExpressRouteTargetResourceId?: string;
    IpSecPolicy?: IpSecPolicy;
}

export interface IpSecPolicy {
    SaLifeTimeSeconds: number;
    SaDataSizeKilobytes: number;
    IPsecEncryption: string;
    IPsecIntegrity: string;
    IkeEncryption: string;
    IkeIntegrity: string;
    DhGroup: string;
    PfsGroup: string;
}

export interface IRobotUnitResponse {
    Available: number;
    Expired: boolean;
    Reusable: number;
    Total: number;
    Used: number;
}

export function getAllVpnGateways({
    requestUri, accountLogicalName, selectedTenantId,
}: { requestUri: string; accountLogicalName: string; selectedTenantId: string }) {
    return get<IVpnGatewaysResponse>(requestUri, {
        urlParams: {
            accountLogicalName,
            selectedTenantId,
        },
        headers: getHeaders(selectedTenantId),
    });
}

export function redeployVpnGateway(accountLogicalName: string, selectedTenantId: string, vpnGatewayKey: string) {
    return post<IVpnGatewaysResponse>(vpnGatewaysUri + 'RedeployGateway', {
        urlParams: {
            accountLogicalName,
            selectedTenantId,
            vpnGatewayKey,
        },
        headers: getHeaders(selectedTenantId),
    });
}

export function getVpnGateway({
    requestUri, accountLogicalName, selectedTenantId, vpnGatewayKey, expand,
}: {
    requestUri: string; accountLogicalName: string;
    selectedTenantId: string; vpnGatewayKey: string; expand?: string; }) {
    return get<IVpnGatewaysResponse>(requestUri, {
        urlParams: {
            accountLogicalName,
            selectedTenantId,
            vpnGatewayKey,
            expand,
        },
        headers: getHeaders(selectedTenantId),
    });
}

export function createVpnGateway(body: ICreateEditVpnGatewayPayload, accountLogicalName: string, selectedTenantId: string) {
    return post<ICreateEditVpnGatewayPayload>(createVpnGatewayUri, {
        urlParams: {
            accountLogicalName,
            selectedTenantId,
        },
        body: { request: { ...body } },
    });
}

export function editVpnGateway(body: ICreateEditVpnGatewayPayload,
    accountLogicalName: string, selectedTenantId: string, vpnGatewayKey: string) {
    return post<ICreateEditVpnGatewayPayload>(editVpnGatewayUri, {
        urlParams: {
            accountLogicalName,
            selectedTenantId,
            vpnGatewayKey,
        },
        body: { request: { ...body } },
    });
}

export function deleteVpnGateway(requestUri: string, accountLogicalName: string, selectedTenantId: string, vpnGatewayKey: string) {
    return axiosDelete(requestUri, {
        urlParams: {
            accountLogicalName,
            selectedTenantId,
            vpnGatewayKey,
        },
        headers: getHeaders(selectedTenantId),
    });
}

export function createVpnGatewayConnection(body: IVpnGatewayConnectionCreateEditPayload,
    accountLogicalName: string, selectedTenantId: string, vpnGatewayKey: string) {
    return post<IVpnGatewayConnectionCreateEditPayload>(createVpnGatewayConnectionUri, {
        urlParams: {
            accountLogicalName,
            selectedTenantId,
            vpnGatewayKey,
        },
        body: { request: { ...body } },
    });
}

export function deleteVpnGatewayConnection(accountLogicalName: string, selectedTenantId: string, vpnGatewayConnectionKey: string) {
    return axiosDelete(vpnGatewaysUri + 'DeleteConnection', {
        urlParams: {
            accountLogicalName,
            selectedTenantId,
            vpnGatewayConnectionKey,
        },
        headers: getHeaders(selectedTenantId),
    });
}

export function getVpnGatewayConnection({
    accountLogicalName, selectedTenantId, vpnGatewayConnectionKey,
}: { url: string; accountLogicalName: string; selectedTenantId: string; vpnGatewayConnectionKey?: string }) {
    return get<IVpnGatewayConnectionPeering>(vpnGatewaysUri + 'GetConnection', {
        urlParams: {
            accountLogicalName,
            selectedTenantId,
            vpnGatewayConnectionKey,
        },
        headers: getHeaders(selectedTenantId),
    });
}

export function editVpnGatewayConnection(body: IVpnGatewayConnectionCreateEditPayload,
    accountLogicalName: string, selectedTenantId: string, vpnGatewayConnectionKey: string) {
    return post<IVpnGatewayConnectionCreateEditPayload>(editVpnGatewayConnectionUri, {
        urlParams: {
            accountLogicalName,
            selectedTenantId,
            vpnGatewayConnectionKey,
        },
        body: { request: { ...body } },
    });
}

export function refreshVpnGatewayConnection(accountLogicalName: string, selectedTenantId: string, vpnGatewayConnectionKey: string) {
    return post<IVpnGatewayConnectionPeering>(vpnGatewaysUri + 'RefreshConnection', {
        urlParams: {
            accountLogicalName,
            selectedTenantId,
            vpnGatewayConnectionKey,
        },
        headers: getHeaders(selectedTenantId),
    });
}

export function getRobotUnits({
    accountLogicalName, selectedTenantId,
}: { url: string; accountLogicalName: string; selectedTenantId: string }) {
    return get<IRobotUnitResponse>(robotUnitsApiPath, {
        urlParams: {
            accountLogicalName,
            selectedTenantId,
        },
        headers: getHeaders(selectedTenantId),
    });
}
