import type {
    Allocation,
    Apps,
    Automation,
    FREData,
    Idea,
    ITask,
    ITenant,
    Recommendation,
    Runs,
    TemplateItem,
    TMProject,
} from '@experiences/interfaces';

import { ActionsWidget } from './widgets/ActionsWidget';
import { AppsWidget } from './widgets/AppsWidget';
import { AutomationWidget } from './widgets/AutomationWidget';
import { FREWidget } from './widgets/FreWidget';
import { LicenseAllocationWidget } from './widgets/LicenseAllocationWidget';
import { RecommendationTemplatesWidget } from './widgets/RecommendationTemplatesWidget';
import { RecommendationWidget } from './widgets/RecommendationWidget';
import { RunsWidget } from './widgets/RunsWidget';
import { TemplatesWidget } from './widgets/TemplatesWidget';
import { TenantsWidget } from './widgets/TenantsWidget';
import { TMExecutionsWidget } from './widgets/TMExecutionsWidget';
import { TMProjectsWidget } from './widgets/TMProjectsWidget';

export const Widgets = [
    TenantsWidget,
    TemplatesWidget,
    AppsWidget,
    AutomationWidget,
    ActionsWidget,
    RunsWidget,
    FREWidget,
    RecommendationWidget,
    RecommendationTemplatesWidget,
    LicenseAllocationWidget,
    TMProjectsWidget,
    TMExecutionsWidget,
];

export type WidgetResources = Apps | ITask | Idea | Automation | Runs | TemplateItem | ITenant
| FREData | Recommendation | Allocation | TMProject;
