import { UiSuspensefulOutlet } from '@experiences/ui-common';
import React from 'react';

import type { UiRouteObject } from '../container/routeConfigs/UiRouteObject';
import { AdminRoutes } from './admin/AdminRoutes';

export const PrivateRootRoutes: UiRouteObject = {
    element: <UiSuspensefulOutlet />,
    children: [ AdminRoutes ],
};
