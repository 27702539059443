import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { UiSuspensefulOutlet } from '@experiences/ui-common';
import type { PropsWithChildren } from 'react';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { routePaths } from '../../common/constants/routePaths';
import { useOrganizationName } from '../../common/hooks/useOrganizationName';
import BreadcrumbProvider from '../../common/providers/BreadcrumbProvider';
import CheckGuard from '../../container/helpers/CheckGuard';
import type { UiRouteObject } from '../../container/routeConfigs/UiRouteObject';

const OrgManageAccessPageComponent = React.lazy(() => import('./OrgManageAccessPageComponent'));

const ManageAccessProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { formatMessage: translate } = useIntl();

    const EnableOrgLevelManageAccess = useFeatureFlagValue(Features.EnableOrgLevelManageAccess.name);

    const organizationName = useOrganizationName();

    const breadcrumbs = useMemo(() => [
        {
            index: 0,
            link: routePaths.admin,
            name: organizationName,
        },
        {
            index: 1,
            link: routePaths.adminManageAccess,
            name: translate({ id: 'CLIENT_MANAGE_ACCESS' }),
        },
    ], [ organizationName, translate ]);

    return <BreadcrumbProvider breadcrumbs={breadcrumbs}>
        {CheckGuard(EnableOrgLevelManageAccess, <>
            {children}
        </>)}
    </BreadcrumbProvider>;
};

export const OrgManageAccessRoutes: UiRouteObject = {
    path: routePaths.adminManageAccess,
    element: <ManageAccessProvider>
        <UiSuspensefulOutlet />
    </ManageAccessProvider>,
    children: [
        {
            index: true,
            element: <OrgManageAccessPageComponent />,
        },
    ],
};
