import type { IFeature } from '@experiences/feature-flags';
import {
    getAllFeatureFlags,
    setLocalFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    SpacingToken,
    UiStack,
    UiText,
} from '@experiences/ui-common';
import FlagIcon from '@mui/icons-material/Flag';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { FontVariantToken } from '@uipath/apollo-core';
import React, {
    useCallback,
    useState,
} from 'react';

import { UiDrawer } from '../common/UiDrawer';
import { FloatingButton } from './FloatingButton';

const useStyles = makeStyles((theme) =>
    ({
        button: {
            position: 'fixed',
            bottom: theme.spacing(2),
            left: theme.spacing(2),
            zIndex: 1000,
        },
        root: {
            height: 'calc(100vh - 200px)',
            overflow: 'hidden',
            overflowY: 'auto',
        },
    })
);

type FlagOverrideArray = Array<IFeature & { overridden: boolean; description: string; value: any }>;

export const FeatureFlagOverrideDrawer = () => {
    const [ open, setOpen ] = useState(false);
    const [ searchTerm, setSearchTerm ] = useState('');
    const [ featureFlags, setFeatureFlags ] = useState<FlagOverrideArray>(getAllFeatureFlags());

    const classes = useStyles();

    const handleToggleChange = useCallback((name: string, value: boolean) => {
        setLocalFeatureFlagValue(name, value.toString());
        setFeatureFlags(getAllFeatureFlags());
    }, []);

    const reset = useCallback(() => {
        featureFlags.filter(flag => flag.overridden).forEach(flag => {
            if (typeof flag.defaultValue === 'boolean') {
                setLocalFeatureFlagValue(flag.name, flag.defaultValue.toString());
            } else if (typeof flag.defaultValue === 'string') {
                setLocalFeatureFlagValue(flag.name, flag.defaultValue);
            }
        });
        setFeatureFlags(getAllFeatureFlags());
    }, [ featureFlags ]);

    return (
        <>
            <FloatingButton
                Icon={FlagIcon}
                tooltipContent="Feature Flags"
                onClick={() => setOpen(true)}
                badgeCount={featureFlags.filter(flag => flag.overridden).length} />
            <UiDrawer
                title="Feature Flags"
                drawerProps={{
                    anchor: 'right',
                    open,
                    onClose: () => setOpen(false),
                }}
                width="medium"
            >
                <UiStack
                    direction="column"
                    gap={SpacingToken.Micro}>

                    <UiText variant={FontVariantToken.fontSizeXs}>
                        Changes to flags, may require a page refresh to take effect
                    </UiText>
                    <TextField
                        label="Search"
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />

                    <UiStack
                        className={classes.root}
                        gap={SpacingToken.XS}
                        direction="column">
                        {featureFlags.filter(({ name }) => name.toLowerCase().includes(searchTerm.toLowerCase())).map(({
                            name, description, value, overridden,
                        }) => (
                            <UiStack
                                key={name}>
                                <Switch
                                    checked={value}
                                    onChange={(e, checked) => handleToggleChange(name, checked)}
                                    color="primary"
                                />
                                <UiStack
                                    direction="column"
                                    gap={SpacingToken.XS}>
                                    <UiText
                                        variant={FontVariantToken.fontSizeMBold}
                                        color={overridden ? 'red' : ''}>
                                        {name}
                                    </UiText>
                                    <UiText variant={FontVariantToken.fontSizeXs}>
                                        {description}
                                    </UiText>
                                </UiStack>

                            </UiStack>
                        ))}
                    </UiStack>

                    <UiStack justify='end'>
                        <Button
                            variant="outlined"
                            onClick={reset}>
                                Clear Local Overrides
                        </Button>
                    </UiStack>
                </UiStack>
            </UiDrawer>
        </>
    );
};
