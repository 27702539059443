import type { INotificationPublisherDto } from '../../notificationSettings/interfaces/notificationSettings';
import type {
    IFilterDataType,
    IFilterQueryParamType,
} from '../interfaces/notifications';
import { Duration } from '../interfaces/notifications';

export const getTimeTick = (filterValue: string) => {
    const showTillDate = new Date();
    switch (filterValue) {
        case Duration.LastHour.toString():
            showTillDate.setHours(showTillDate.getHours() - Duration.LastHour);
            break;
        case Duration.Last24Hour.toString():
            showTillDate.setHours(showTillDate.getHours() - Duration.Last24Hour);
            break;
        case Duration.LastWeek.toString():
            showTillDate.setDate(showTillDate.getDate() - Duration.LastWeek);
            break;
        case Duration.LastMonth.toString():
            showTillDate.setDate(showTillDate.getDate() - Duration.LastMonth);
            break;
    }
    showTillDate.setMinutes(0, 0, 0);
    return Math.floor(showTillDate.getTime() / 1000);
};

export const getFilterQuery = (filterStores: IFilterQueryParamType[]) => {
    let filtersString = '';
    if (filterStores.length > 0) {
        filtersString = '(';
        filterStores.forEach((filterQuery) => {
            if (filterQuery.operator === 'contains') {
                filtersString += 'contains(' + filterQuery.operand + ',\'' + filterQuery.compValue + '\') and ';
            } else
            if (filterQuery.operator === 'or' && filterQuery.paramList) {
                filtersString += '(';
                filtersString += filterQuery.paramList.map((child) => filterQuery.operand + ' eq ' + child.value + ' or ').join('');
                filtersString = filtersString.substring(0, filtersString.length - 4);
                filtersString += ') and ' ;
            } else {
                filtersString += '(' + filterQuery.operand + ' ' + filterQuery.operator + ' ' + filterQuery.compValue + ') and ';
            }
        });
        filtersString = filtersString.substring(0, filtersString.length - 5);
        filtersString += ')';
    }
    return filtersString;
};

export const getPublisherUsingTopicId = (topicId: string, publisherTypes: IFilterDataType[]) => {
    for (const publisher of publisherTypes) {
        for (const group of publisher.childs ?? []) {
            const topic = group.childs?.find(x => x.value === topicId);
            if (topic) {
                return [ publisher, group, topic ];
            }
        }
    }
    return [];
};

export const removeFilters = (filters: IFilterQueryParamType[], operands: string[]) => {
    const result: IFilterQueryParamType[] = [];
    for (let i = 0; i < filters.length; i++) {
        if (!operands.includes(filters[i].operand)) {
            result.push(filters[i]);
        }
    }
    return result;
};

export const getServiceList = (publishers: INotificationPublisherDto[], allValue: string) => {
    const services: IFilterDataType[] = [];
    publishers.forEach((service) => {
        const pubGroups: IFilterDataType[] = [];
        const groupedPublisher: IFilterDataType = {
            value: service.id,
            label: service.displayName,
        };

        service.topics.forEach((topic) => {
            const topicData: IFilterDataType = {
                value: topic.id,
                label: topic.displayName,
            };
            const groupIndex = pubGroups.findIndex(tg => tg.value === topic.group);
            if (groupIndex === -1) {
                const topicGroup: IFilterDataType = {
                    value: topic.group ?? allValue,
                    label: topic.group ?? allValue,
                    childs: [ topicData ],
                };
                pubGroups.push(topicGroup);
            } else {
                pubGroups[groupIndex].childs?.push(topicData);
            }
        });
        groupedPublisher.childs = pubGroups;
        services.push(groupedPublisher);
    });

    return services;

};

