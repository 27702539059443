import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    getDeploymentVersion,
    getEnvironment,
} from '@experiences/util';
import type { Integration } from '@sentry/types';
import { useEffect } from 'react';
import {
    createBrowserRouter,
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from 'react-router-dom';

const filteredErrors = [
    'Invariant Violation',
    'Invocation canceled due to the underlying connection being closed',
    'Server returned an error on close: Connection closed with an error.',
];

export async function initializeSentry() {

    const enableSentry = getFeatureFlagValue(Features.EnableSentry.name);

    if (!enableSentry) {
        return false;
    }

    if (process.env.NODE_ENV === 'test' || 'Cypress' in window) {
        return false;
    }

    if (window.env.name === 'k8s') {
        return false;
    }

    try {
        const isDevelopment = getEnvironment() === 'local';
        const isAlphaOrStaging = getEnvironment() === 'alp' || getEnvironment() === 'stg';

        const Sentry = await import('@sentry/react');
        const integrations: Integration[] = [
            Sentry.rewriteFramesIntegration({
                iteratee: (frame) => ({
                    ...frame,
                    filename: frame.filename?.replace(document.baseURI, ''),
                }),
            }),
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
            Sentry.replayIntegration({
                maskAllInputs: !isAlphaOrStaging,
                maskAllText: !isAlphaOrStaging,
                blockAllMedia: !isAlphaOrStaging,
                networkDetailAllowUrls: [ window.location.origin ],
            }),
        ];

        Sentry.init({
            dsn: 'https://05e584eda90d589761db3a5ed99779fd@o222862.ingest.sentry.io/4506627244032000',
            integrations,
            ignoreErrors: filteredErrors,
            environment: getEnvironment(),
            release: getDeploymentVersion(),

            tracesSampleRate: isDevelopment ? 0 : 0.1,

            // Never capture replays unless there's an error
            // plus for 100% of sessions with an error
            replaysSessionSampleRate: 0,
            replaysOnErrorSampleRate: isDevelopment ? 0 : 1.0,
        });
    } catch (e) {
        return false;
    }

    return true;
}

export async function createSentryBrowserRouter() {
    const Sentry = await import('@sentry/react');
    return Sentry.wrapCreateBrowserRouter(createBrowserRouter);
}
