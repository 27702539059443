import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    type AuthConfiguration,
    UberClientId,
} from '@uipath/auth-react';

import { getAuthDomain } from './AuthDomainResolver';

const getEnablePortalUberClient = () => getFeatureFlagValue(Features.EnablePortalUberClient.name);

export const getCISClientId = () => getEnablePortalUberClient() ? UberClientId : '6e80a74c-0878-4517-9b94-5283859df42f';

export const getAuthConfiguration: () => AuthConfiguration = () => {
    const { CISAuthority } = getAuthDomain();
    const EnableInsightsScope = getFeatureFlagValue(Features.EnableInsightsScope.name);
    const EnableStudioWebScope = getFeatureFlagValue(Features.EnableStudioWebScope.name);
    const EnableSRSScope = getFeatureFlagValue(Features.EnableSRSScope.name);
    const EnableTestManagerScope = getFeatureFlagValue(Features.EnableTestManagerScope.name);
    const EnableSRSRealTimeEventsScope = getFeatureFlagValue(Features.EnableSRSRealTimeEventsCollection.name);
    const EnableConnectionServiceUserScope = getFeatureFlagValue(Features.EnableConnectionServiceUserScope.name);
    const EnableGlobalAppRegistrations = getFeatureFlagValue(Features.EnableGlobalAppRegistrations.name);

    const accountName = window.location.pathname.split('/')[1];
    const isValidAccountName = accountName && !accountName.includes('portal_');

    const scopes = [
        'openid',
        'profile',
        'email',
        'IdentityServerApi',
        'OrchestratorApiUserAccess',
        'JamJamApi',
        'ReferenceToken',
        'offline_access',
        'RCS.FolderAuthorization',
        'RCS.TagsManagement',
        ...(EnableSRSScope ? [ 'SRS.Recommendations' ] : []),
        ...(EnableTestManagerScope ? [ 'TM.TestExecutions', 'TM.Projects' ] : []),
        ...(EnableSRSRealTimeEventsScope ? [ 'SRS.Events' ] : []),
        ...(EnableInsightsScope ? [ 'Insights' ] : []),
        ...(EnableStudioWebScope ? [ 'StudioWebBackend', 'StudioWebTypeCacheService' ] : []),
        ...(EnableConnectionServiceUserScope ? [ 'ConnectionServiceUser' ] : []),
        ...(EnableGlobalAppRegistrations ? [ 'GlobalClientManagement.Internal' ] : []),
    ];

    const redirectUri = `${window.location.origin}/${isValidAccountName ? accountName + '/' : ''}portal_/loginsuccess`;

    return {
        authority: CISAuthority,
        redirect_uri: redirectUri,
        silent_redirect_uri: `${window.location.origin}/portal_/loginsuccess`,
        post_logout_redirect_uri: `${window.location.origin}/portal_/cloudrpa`,
        overrides: !getEnablePortalUberClient() ? {
            client_id: getCISClientId(),
            scope: scopes.join(' '),
        } : undefined,
        usePortalSilentRedirectUri: !window.location.origin.includes('localhost'),
        // To test PKCE, set accessTokenExpiringNotificationTime: 300
    } satisfies AuthConfiguration;
};
