import type {
    TMTestExecution,
    WidgetContext,
    WidgetItem,
    WidgetTemplate,
} from '@experiences/interfaces';

import { getTMExecutions } from '../services/getTMExecutions';

export const TMExecutionsWidget: WidgetTemplate<TMTestExecution> = {
    name: 'TMExecutions',
    titleKey: 'CLIENT_TESTMANAGER_EXECUTIONS',
    emptyMsgKey: () => 'CLIENT_NO_TESTMANAGER_EXECUTIONS',
    tableColumns: 4,
    tableHeaders: [
        {
            nameKey: 'CLIENT_TABLE_HEADER_PROJECT_NAME',
            colStyle: 'detail',
        },
        {
            nameKey: 'CLIENT_TABLE_HEADER_EXECUTION_NAME',
            colStyle: 'headerClickable',
        },
        {
            nameKey: 'CLIENT_TABLE_HEADER_END_TIME',
            colStyle: 'subDetail',
        },
        {
            nameKey: 'CLIENT_TABLE_HEADER_EXECUTION_STATUS',
            colStyle: 'statusText',
        },
    ],
    fetch: async (ctx: WidgetContext) => {
        const executionsLimit = 15;
        const runningExecutions = await getTMExecutions(ctx.accessToken, ctx.tenant, 'running', 'executionStart', executionsLimit);
        let executionsToDisplay = runningExecutions?.items || [];
        const remaining = executionsLimit - executionsToDisplay.length;
        if (remaining > 0) {
            const finishedExecutions = await getTMExecutions(ctx.accessToken, ctx.tenant, 'finished', 'executionFinished', remaining);
            const finishedIds = new Set((finishedExecutions.items || []).map(exec => exec.id));
            executionsToDisplay = executionsToDisplay.filter(exec => !finishedIds.has(exec.id));
            executionsToDisplay = executionsToDisplay.concat(finishedExecutions?.items || []);
        }
        return Promise.resolve(executionsToDisplay);
    },
    type: 'Table',
    transform: (data: TMTestExecution[]): Array<WidgetItem<TMTestExecution>> => data.map(item => {
        const statusKey = resolveStatus(item.status);
        return {
            data: item,
            clickable: true,
            header: item.name,
            description: `${item.project?.name} (${item.project?.projectPrefix})`,
            isSubtitleTime: !!item.executionFinished,
            subtitle: item.executionFinished?.toString(),
            statusKey,
        };
    }),
    clickHandler: (data: TMTestExecution, ctx: WidgetContext) => {
        window.location.assign(getTestExecutionUrl(ctx, data));
    },
    emptyStateButtonHandler: (ctx: WidgetContext) => {
        window.location.assign(`${window.location.origin}/${ctx.accountLogicalName}/${ctx.tenant}/testmanager_`);
    },
};

export function getTestExecutionUrl(ctx: WidgetContext, data: TMTestExecution): string {
    return `${window.location.origin}/${ctx.accountLogicalName}/${ctx.tenant}/testmanager_/${data.project.projectPrefix}/testexecutions/${data.id}`;
}

export function resolveStatus(status: string): string {
    return `TESTEXECUTIONSTATUS_${status?.toUpperCase()}`;
}
