import type { IConsumable } from '@experiences/interfaces';

import type {
    ILicenseAndKey,
    IRobotAndServicesLicenses,
} from '../../../common/interfaces/licenses';
import store from '../../../store';
import { setUserProfile } from '../../../store/action/UserProfileAction';
import { get } from '../../utility/Requests';

export const licenseManagementAccountUrl = '/api/license/management/account';
export const licenseManagementAccountConsumablesUrl = '/api/license/management/account/consumables';

export function getRobotsAndServices({
    accountUserId, accountGlobalId,
}: { url: string; accountUserId: string; accountGlobalId: string }) {
    return get<IRobotAndServicesLicenses>(`${licenseManagementAccountUrl}/available`, {
        urlParams: {
            accountUserId,
            accountGlobalId,
        },
    });
}

export async function fetchAccountLicenseWithMlKeysAndUpdateAccountType() {
    const profileState = store.getState().profile;
    const accountUserId = profileState.userGlobalId;
    const accountGlobalId = profileState.accountUserDto.accountGlobalId.toLowerCase();
    const accountType = profileState.accountUserDto.accountType;

    const result = await get<ILicenseAndKey>(licenseManagementAccountUrl, {
        urlParams: {
            accountUserId,
            accountGlobalId,
        },
    });

    // Sets the accountType based on the value in LRM
    const lrmAccountType = result?.accountLicense?.accountType;
    if (lrmAccountType && lrmAccountType !== accountType) {
        setUserProfile({
            ...profileState,
            accountUserDto: {
                ...profileState.accountUserDto,
                accountType: lrmAccountType,
            },
        });
    }

    return result;
}

export async function getConsumables({
    accountGlobalId, includeTrialConsumables,
}: { url: string; accountGlobalId: string; tenantGlobalId?: string; includeTrialConsumables?: boolean }) {
    const queryParams: { [key: string]: any } = {
        accountGlobalId,
        includeTrialConsumables,
    };
    return await get<IConsumable[]>(licenseManagementAccountConsumablesUrl, { urlParams: queryParams });
}
