import { UiSuspensefulOutlet } from '@experiences/ui-common';
import React from 'react';

import {
    HostOrganizationAdd,
    OrganizationAdminHome,
} from '../../common/constants/RouteNames';
import { routePaths } from '../../common/constants/routePaths';
import type { UiRouteObject } from '../../container/routeConfigs/UiRouteObject';
import { HostAuditRoutes } from '../audit/HostAuditRoutes';
import { HostLicenseRoutes } from '../licensing/HostLicenseRoutes';
import { HostMailSettingsRoutes } from '../mailSettings/HostMailSettingsRoutes';
import { HostSettingsRoutes } from '../organizationsettings/HostSettingsRoutes';
import { HostSecurityRoutes } from '../securitySettings/HostSecurityRoutes';
import { HostIdentitiesRoutes } from '../users/HostIdentitiesRoutes';
import { HostOrganizationRoutes } from './HostOrganization/HostOrganizationRoutes';

const OrganizationAdminHomeComponent = React.lazy(() => import('../organizationsettings/OrganizationAdminHome'));
const HostOrganizationAddComponent = React.lazy(() => import('./HostOrganization/OrganizationAdd'));

export const HostAdminRoutes: UiRouteObject = {
    path: routePaths.admin,
    element: <UiSuspensefulOutlet />,
    children: [
        {
            index: true,
            redirectUrls: [ OrganizationAdminHome ],
            element: <OrganizationAdminHomeComponent />,
        },
        {
            path: routePaths.adminHostOrganizationsAdd,
            redirectUrls: [ HostOrganizationAdd ],
            element: <HostOrganizationAddComponent />,
        },
        HostLicenseRoutes,
        HostIdentitiesRoutes,
        HostSecurityRoutes,
        HostMailSettingsRoutes,
        HostAuditRoutes,
        HostSettingsRoutes,
        HostOrganizationRoutes,
    ],
};

