import type { IEmailSettingsData } from '../../common/interfaces/emailSetting';
import type { IKeyValuePair } from '../../common/interfaces/keyValuePair';
import {
    axiosDelete,
    get,
    post,
    put,
} from '../utility/Requests';

export const settingUrl = `/api/identity/Setting`;

export interface ISetting {
    id: number;
    key: string;
    value: string;
    partitionGlobalId: string;
    userId: string;
}

export interface ISettingPayload {
    settings: IKeyValuePair[];
    partitionGlobalId: string;
    userId?: string;
}

export function getSetting({
    key, partitionGlobalId, userId,
}: { url: string; key: string[]; partitionGlobalId: string; userId?: string }) {
    return get<ISetting[]>(settingUrl, {
        urlParams: {
            key,
            partitionGlobalId,
            userId,
        },
    });
}

export function saveSetting(url: string, payload: ISettingPayload) {
    return put<ISetting[]>(settingUrl, { body: payload });
}

export function deleteSettings(key: string[], partitionGlobalId: string) {
    return axiosDelete(settingUrl, {
        body: {
            settings: key,
            partitionGlobalId,
        },
    });
}

export function verifySmtpSettings(recipient: string, smtpSettings: IEmailSettingsData) {
    return post(`${settingUrl}/VerifySmtpSettings`, {
        body: {
            recipient,
            smtpSettings,
        },
    });
}
