import {
    buildSkuPackageFromData,
    BuyProCheckout,
    DIRECT_BUY_DATA,
    useIsEcommerceEnabled,
} from '@experiences/ecommerce';
import { useGetErrorInfo } from '@experiences/error';
import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    portalTelemetry,
    SeverityLevel,
} from '@experiences/telemetry';
import { UiLoader } from '@experiences/ui-common';
import {
    getBooleanValue,
    UiStorage,
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import {
    generatePath,
    Navigate,
    useLocation,
    useParams,
} from 'react-router-dom';

import ProtectedRoute from '../../auth/ProtectedRoute';
import * as RouteNames from '../../common/constants/RouteNames';
import useCheckLicense from '../../common/hooks/useCheckLicense';
import useIsOnPrem from '../../common/hooks/useIsOnPrem';
import {
    Platform,
    usePlatformHost,
} from '../../common/hooks/usePlatformHost';
import {
    saveSetting,
    settingUrl,
} from '../../services/identity/SettingService';
import { fetchHistoryStatus } from '../../services/licensing/BillingService';
import {
    createTenant,
    getTenants,
} from '../../services/organization/TenantService';
import {
    accountGlobalId,
    accountLogicalName,
    groupIdsForUser,
    isAdminSelector,
    isHostModeSelector,
    userGlobalId,
} from '../../store/selectors';
import { logFREEnabled } from '../../telemetry';
import { UserGroup } from '../common/UserGroups';

export const AccountAdmin: React.FC = () => {
    const EnableRecreateDefaultTenant = getFeatureFlagValue(Features.EnableRecreateDefaultTenant.name);
    const { getErrorObject } = useGetErrorInfo();
    const {
        isAccountLicenseEnterpriseOrPro, isUnpaidLicense, isCommunity,
    } = useCheckLicense();

    const navigate = useNavigateWithParams();
    const location = useLocation();
    const getRoute = useRouteResolver();

    const [ loading, setLoading ] = useState(true);

    const userId = useSelector(userGlobalId);
    const partitionGlobalId = useSelector(accountGlobalId);
    const accountName = useSelector(accountLogicalName);
    const currentGroupIds = useSelector(groupIdsForUser);
    const isAdmin = useSelector(isAdminSelector);
    const host = usePlatformHost();

    const isEcommerceEnabled = useIsEcommerceEnabled();

    const {
        isFirstLogin,
        isInvitedUser,
        ecommerceRedirectParams,
        redirectPath,
    } = useMemo(() => {
        const search = new URLSearchParams(location.search);

        return {
            isFirstLogin: getBooleanValue(search.get('firstLogin')),
            isInvitedUser: getBooleanValue(search.get('firstLoginForInvite')),
            ecommerceRedirectParams: isEcommerceEnabled && getBooleanValue(search.get('ecommerceRedirect')),
            redirectPath: search.get('redirectPath') ?? UiStorage.getItem('redirectPath'),
        };
    }, [ location.search, isEcommerceEnabled ]);

    const createDefaultTenant = useCallback(async () => {
        try {
            return await createTenant({
                name: '',
                color: '',
                region: undefined,
                isDefaultTenant: true,
                services: [ 'orchestrator', 'du' ],
            } as any);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
        }
    }, []);

    const redirectPathNavigate = useCallback(async () => {
        let parsedPath = decodeURIComponent(redirectPath ?? '');
        parsedPath = parsedPath.replace(':tenantName', UiStorage.getItem('PORTAL_CURRENT_TENANT') as string);
        window.location.href = `/${accountName}/${parsedPath}`;
        setLoading(false);
    }, [ accountName, redirectPath ]);

    const ecommerceRedirect = useCallback(async () => {
        if (!isEcommerceEnabled) {
            setLoading(false);
        }

        const shouldRedirectToManagePlan = await fetchHistoryStatus({
            url: '',
            accountName,
        });
        if (shouldRedirectToManagePlan?.hasHistoricalData && isAccountLicenseEnterpriseOrPro()) {
            navigate(getRoute(RouteNames.ManagePlan));
        } else {
            navigate(getRoute(RouteNames.BuyProPresets));
        }
    }, [ isEcommerceEnabled, accountName, isAccountLicenseEnterpriseOrPro, navigate, getRoute ]);

    const directBuyData = useMemo(() => {
        const dataAsString = UiStorage.getItem(DIRECT_BUY_DATA);
        if (dataAsString) {
            try {
                const parsedData = JSON.parse(dataAsString);
                if (parsedData?.productQuantities) {
                    parsedData.productQuantities = JSON.parse(parsedData.productQuantities);
                }

                return parsedData;
            } catch (ex) {
                return null;
            }
        }
    }, []);

    const ecommerceCheckoutRedirect = useCallback(
        async () => {
            try {
                if (isEcommerceEnabled) {
                    const skuPackage = await buildSkuPackageFromData(directBuyData);
                    navigate(getRoute(BuyProCheckout), {
                        state: {
                            skuPackage,
                            selectedCurrency: skuPackage?.currency,
                        },
                    });
                }
            } catch (error) {
                const errorObject = await getErrorObject(error);
                console.error(errorObject);
            } finally {
                setLoading(false);
            }
            return;
        },
        [ isEcommerceEnabled, directBuyData, navigate, getRoute, getErrorObject ],
    );

    const recreateDefaultTenant = useCallback(async () => {
        try {
            const tenants = await (getTenants({
                url: '',
                organizationGuid: partitionGlobalId,
                accountName,
            }));
            if (tenants.length === 0) {
                portalTelemetry.trackTrace({
                    message: `No default tenant found, recreating for user ${userId}`,
                    severityLevel: SeverityLevel.Info,
                });

                await createDefaultTenant();
                localStorage.setItem('defaultTenantRecreated', 'true');

                portalTelemetry.trackTrace({
                    message: `Default tenant recreated for user ${userId}`,
                    severityLevel: SeverityLevel.Info,
                });
            }
        } catch (error) {
            const errorObject = await getErrorObject(error);
            const message = `Recreating default tenant failed for ${userId}`;
            portalTelemetry.trackTrace({
                message,
                severityLevel: SeverityLevel.Major,
                properties: { error: errorObject },
            });
        }
    }, [ partitionGlobalId, accountName, userId, createDefaultTenant, getErrorObject ]);

    const setFRESettings = useCallback(async () => {
        const userIsAutomationDev = currentGroupIds.indexOf(UserGroup.AutomationDevelopers) > -1;
        await saveSetting(settingUrl, {
            settings: [
                {
                    key: 'displayFREBanner',
                    value: (host === Platform.Windows && userIsAutomationDev).toString(),
                },
            ],
            partitionGlobalId,
            userId,
        });

        if (host === Platform.Windows && userIsAutomationDev) {
            await logFREEnabled();
        }
    }, [ currentGroupIds, host, partitionGlobalId, userId ]);

    const firstTimeLogin = useCallback(async () => {
        try {
            await setFRESettings();
        } catch (error) {
            console.error('Tenant Provisioning failed.');
        }
    }, [ setFRESettings ]);

    useEffect(() => {
        (async () => {
            const defaultTenantAlreadyRecreated = localStorage.getItem('defaultTenantRecreated');

            if (isFirstLogin || isInvitedUser) {
                await firstTimeLogin();
            } else if (
                !isFirstLogin &&
                isCommunity &&
                EnableRecreateDefaultTenant &&
                !defaultTenantAlreadyRecreated
            ) {
                await recreateDefaultTenant();
            }

            if (ecommerceRedirectParams && isAccountLicenseEnterpriseOrPro()) {
                await ecommerceRedirect();
            } else if (
                directBuyData &&
                isAdmin &&
                isUnpaidLicense
            ) {
                await ecommerceCheckoutRedirect();
            } else if (redirectPath) {
                await redirectPathNavigate();
            } else {
                setLoading(false);
            }
        })();
        // eslint-disable-next-line
    }, []);

    return loading ? (
        <UiLoader type="full" />
    ) : (
        <Navigate to={getRoute(RouteNames.Home)} />
    );
};

export const AccountAdminK8s = () => {
    const isHostMode = useSelector(isHostModeSelector);
    const currentAccountName = useSelector(accountLogicalName);
    const { accountName: paramAccountName } = useParams() as { accountName?: string };
    const hostPath = useMemo(() => generatePath(RouteNames.OrganizationAdminHome, { accountName: 'host' }), []);

    const getOrgPath = useCallback((accountName: string) => generatePath(RouteNames.Home, { accountName }), []);

    const path = useMemo(() => {
        if (paramAccountName) {
            return paramAccountName === 'host' ? hostPath : getOrgPath(paramAccountName);
        }
        return isHostMode ? hostPath : getOrgPath(currentAccountName);
    }, [ getOrgPath, currentAccountName, hostPath, isHostMode, paramAccountName ]);

    return <Navigate to={path} />;
};

export default (props: any) => {
    const isOnPrem = useIsOnPrem();

    return (
        <ProtectedRoute>
            {isOnPrem ? <AccountAdminK8s {...props} /> : <AccountAdmin {...props} />}
        </ProtectedRoute>
    );
};
