import type { IUserLicenses } from '@experiences/interfaces';

import type { IGroupUser } from '../../../common/interfaces/cis/group';
import type { IUserCIS } from '../../../common/interfaces/cis/user';

export enum ProfileTabs {
    MEMBERS = 'members',
    GROUP_MEMBERSHIPS = 'group-memberships',
    LICENSES = 'licenses',
    ACCESS = 'access',
    INFO = 'info',
}

export type IUserWithLicenses = IUserCIS & Partial<IUserLicenses>;
export type IGroupUserWithLicenses = IGroupUser & Partial<IUserLicenses> & { id: string };

export type ProfileLicense = { id: string; groupName?: string; groupId?: string };
