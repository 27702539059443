import {
    BuyProCheckout,
    BuyProConfirmation,
    BuyProIndividualProducts,
    BuyProLoading,
    EcommerceCheckoutWrapperComponent,
    EcommerceIndividualProducts,
    EcommerceOrderConfirmationComponent,
    EcommerceOrderLoadingComponent,
    EcommercePresets,
    useIsEcommerceEnabled,
} from '@experiences/ecommerce';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import * as RouteNames from '../../common/constants/RouteNames';
import useCheckLicense from '../../common/hooks/useCheckLicense';
import EcommerceComponent from '../../component/ecommerce/EcommerceComponent';
import EcommerceManagePlanComponent from '../../component/ecommerce/EcommerceManagePlanComponent';
import { EcommerceUpdateLicenseQuantityWrapper } from '../../component/ecommerce/EcommerceUpdateLicenseQuantityWrapper';
import {
    accountLogicalName,
    accountType as accountTypeSelector,
} from '../../store/selectors';
import CheckGuard from '../helpers/CheckGuard';

export const useEcommerceRoutesDefault = () => {
    const EnableEcommerceIntegration = useFeatureFlagValue(Features.EnableEcommerceIntegration.name);
    const EnableEcommercePlanSelectionRevampM0 = useFeatureFlagValue(Features.EnableEcommercePlanSelectionRevampM0.name);

    const accountName = useSelector(accountLogicalName);
    const accountType = useSelector(accountTypeSelector);

    const isEcommerceEnabled = useIsEcommerceEnabled();

    const { isAccountLicenseEnterpriseOrPro } = useCheckLicense();

    return useMemo(() => [
        {
            path: BuyProCheckout,
            element: CheckGuard(isEcommerceEnabled,
                <EcommerceCheckoutWrapperComponent />,
            ),
        },
        {
            path: BuyProLoading,
            element: CheckGuard(isEcommerceEnabled,
                <EcommerceOrderLoadingComponent />,
            ),
        },
        {
            path: BuyProConfirmation,
            element:
                CheckGuard(EnableEcommerceIntegration,
                    <EcommerceOrderConfirmationComponent />,
                ),
        },
        {
            path: RouteNames.BuyPro,
            element:
                CheckGuard(isEcommerceEnabled,
                    EnableEcommercePlanSelectionRevampM0
                        ? <Navigate to={RouteNames.BuyProPresets.replace(':accountName', accountName)} />
                        : CheckGuard(!isAccountLicenseEnterpriseOrPro(), <EcommerceComponent />)
                ),
        },
        {
            path: RouteNames.BuyEnterprise,
            element:
                CheckGuard(isEcommerceEnabled,
                    EnableEcommercePlanSelectionRevampM0
                        ? <Navigate to={RouteNames.BuyProPresets.replace(':accountName', accountName)} />
                        : CheckGuard(!isAccountLicenseEnterpriseOrPro(), <EcommerceComponent />)
                ),
        },
        {
            path: RouteNames.BuyProPresets,
            element:
                CheckGuard(isEcommerceEnabled,
                    !EnableEcommercePlanSelectionRevampM0
                        ? <Navigate to={RouteNames.BuyPro.replace(':accountName', accountName)} />
                        : CheckGuard(!isAccountLicenseEnterpriseOrPro(), <EcommercePresets isDirectBuyFlow={false} />)
                ),
        },
        {
            path: RouteNames.UpdateLicenseQuantity,
            element:
                CheckGuard(
                    isAccountLicenseEnterpriseOrPro(accountType),
                    <EcommerceUpdateLicenseQuantityWrapper />
                    ,
                ),
        },
        {
            path: BuyProIndividualProducts,
            element:
                CheckGuard(EnableEcommerceIntegration && !isAccountLicenseEnterpriseOrPro() && EnableEcommercePlanSelectionRevampM0,
                    <EcommerceIndividualProducts isDirectBuyFlow={false} />),
        },
        {
            path: RouteNames.ManagePlan,
            element:
                CheckGuard(
                    isAccountLicenseEnterpriseOrPro(),
                    <EcommerceManagePlanComponent />,
                ),
        },
    ], [
        EnableEcommerceIntegration,
        EnableEcommercePlanSelectionRevampM0,
        accountName,
        accountType,
        isAccountLicenseEnterpriseOrPro,
        isEcommerceEnabled,
    ]);
};
