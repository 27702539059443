import type { IPagination } from '@experiences/interfaces';

import type { IUserWithExplicitAllocations } from '../../../common/interfaces/licenses';
import type {
    IGroupAllocationsPagedResult,
    IUserWithExplicitAllocationsPagedResult,
} from '../../../component/licensing/interfaces/license';
import type {
    IAvailableUserLicense,
    IGroupAllocationRules,
    ILicenseInheritance,
    IUserAllocationsInGroupResponse,
    IUserLicenseAllocation,
} from '../../../component/users/interfaces/userLicense';
import {
    axiosDelete,
    get,
    post,
    put,
} from '../../utility/Requests';

export const userLicenseUrl = '/api/license/accountant/UserLicense';

export interface ISubmitGroupAllocations {
    groupId: string;
    licenses: string[];
    useExternalLicense: boolean;
}

export interface ISubmitUsersAllocations {
    userIds: Array<string | undefined>;
    licenseCodes: string[];
    useExternalLicense: boolean;
}

export function getAccountLicenseAllocation() {
    return get<IUserLicenseAllocation[]>(userLicenseUrl);
}

export function putUserLicenseAllocation(payload: ISubmitUsersAllocations) {
    return put<IUserLicenseAllocation[]>(userLicenseUrl, { body: { ...payload } });
}

export function getGroupLicenseAllocation({ id }: { url?: string; id: string }) {
    return get<IGroupAllocationRules>(`${userLicenseUrl}/group`, { urlParams: { id } });
}

export function putGroupLicenseAllocation(payload: ISubmitGroupAllocations) {
    return put(`${userLicenseUrl}/group`, {
        body: {
            ubls: payload.licenses,
            id: payload.groupId,
            useExternalLicense: payload.useExternalLicense,
        },
    });
}

export function deleteGroupLicenseAllocation(groupId: string) {
    return axiosDelete(`${userLicenseUrl}/group`, { body: { id: groupId } });
}

export function deleteUserExplicitAllocations(userIds: string[]) {
    return axiosDelete(`${userLicenseUrl}`, { body: { userIds } });
}

export function getUsersExplicitAllocationsPage({
    pagination, selectedUserId, licenseCodes,
}: {
    pagination?: IPagination;
    selectedUserId?: string;
    licenseCodes?: string[];
}) {
    return get<IUserWithExplicitAllocationsPagedResult>(`${userLicenseUrl}/user/page`, {
        urlParams: {
            userToRequestAllocationFor: selectedUserId,
            userBundleLicenseCodes: licenseCodes,
        },
        pagination,
    });
}

export function retrieveExplicitAllocationsForUserIds(userIds: Array<string | undefined>) {
    return post<IUserWithExplicitAllocations[]>(`${userLicenseUrl}/users`, { body: { userIds } });
}

export function getEditableUserLicense({ userGlobalId }: { url: string; userGlobalId: string }) {
    return get<IAvailableUserLicense>(`${userLicenseUrl}/availableUserBundles`, { urlParams: { userGlobalId } });
}

export function getGroupsAllocationsPage({
    pagination, selectedGroupId, licenseCodes,
}: {
    pagination?: IPagination;
    selectedGroupId?: string;
    licenseCodes?: string[];
}) {
    return get<IGroupAllocationsPagedResult>(`${userLicenseUrl}/group/page`, {
        urlParams: {
            groupToRequestAllocationFor: selectedGroupId,
            userBundleLicenseCodes: licenseCodes,
        },
        pagination,
    });
}

export function getUserAllocationsForGroup({
    pagination, groupId,
}: { pagination: IPagination; groupId: string }) {
    return get<IUserAllocationsInGroupResponse>(`${userLicenseUrl}/group/${groupId}/allocations`, { pagination });
}

export async function releaseUserLicensesFromGroup(groupId: string, userId: string) {
    return await axiosDelete(`${userLicenseUrl}/group/${groupId}/user/${userId}`);
}

export async function getLicenseInheritance({ userId }: { url?: string; userId: string }) {
    return get<ILicenseInheritance>(`${userLicenseUrl}/licenseInheritance/${userId}`);
}

export function getExplicitUserBundleCodes() {
    return get<string[]>(`${userLicenseUrl}/ublCodes/explicit`);
}

export function getGroupRuleUserBundleCodes() {
    return get<string[]>(`${userLicenseUrl}/ublCodes/group`);
}
