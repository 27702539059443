export enum ProfileTab {
    GeneralSettings = 'GeneralSettings',
    ThemeSettings = 'ThemeSettings',
    AccessibilitySettings = 'AccessibilitySettings',
    NotificationSettings = 'NotificationSettings',
    PrivacyAndSecuritySettings = 'PrivacyAndSecuritySettings',
    PersonalAccessTokenSettings = 'PersonalAccessTokenSettings',
}

export type ProfileTabType = typeof ProfileTab[keyof typeof ProfileTab];
