import { AuthGuard } from '@uipath/auth-react';
import type { PropsWithChildren } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';

import useIsOnPrem from '../common/hooks/useIsOnPrem';
import { TenantOperationTrackerContextProvider } from '../component/tenants/TenantOperationTrackerContextProvider';
import { TenantsContextProvider } from '../component/tenants/TenantsContextProvider';
import { isHostModeSelector } from '../store/selectors';
import useAuthParams from './hooks/AuthParams';
import PortalInitializationProtector from './PortalInitializationProtector';
import IdentityAuthProvider from './token/IdentityAuthProvider';

const ProtectedRoute = ({ children }: PropsWithChildren<any>) => {
    const params = useAuthParams();
    const isHost = useSelector(isHostModeSelector);

    const isOnPrem = useIsOnPrem();

    return (
        <AuthGuard extraSigninRequestArgs={params}>
            <IdentityAuthProvider>
                <PortalInitializationProtector>
                    {
                        isOnPrem && isHost ?
                            <>
                                {children}
                            </>
                            :
                            <TenantOperationTrackerContextProvider>
                                <TenantsContextProvider>
                                    {children}
                                </TenantsContextProvider>
                            </TenantOperationTrackerContextProvider>
                    }

                </PortalInitializationProtector>
            </IdentityAuthProvider>
        </AuthGuard>
    );
};

export default ProtectedRoute;
