import { GlobalStyles } from '@experiences/theme';
import { UiAlertBanner } from '@experiences/ui-common';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import React, { useMemo } from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { AboutLicensingLink } from '../../../common/constants/documentation/DocumentationLinksDefault';
import { AboutLicensingLinkK8s } from '../../../common/constants/documentation/DocumentationLinksK8s';
import { useDocumentationLinks } from '../../../common/hooks/useDocumentationLink';
import useIsOnPrem from '../../../common/hooks/useIsOnPrem';
import type { ILicenseAndKey } from '../../../common/interfaces/licenses';
import {
    fetchAccountLicenseWithMlKeysAndUpdateAccountType,
    licenseManagementAccountUrl,
} from '../../../services/licensing/management/AccountService';
import {
    isAdminSelector,
    isUnlicensedSelector,
} from '../../../store/selectors';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    learnMoreLink: { marginLeft: '1ch' },
}));

export const UiLicenseInGracePeriodBanner: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const getLocalizedLink = useDocumentationLinks();
    const isAdmin = useSelector(isAdminSelector);

    const isUnlicensedMode = useSelector(isUnlicensedSelector);

    const isOnPrem = useIsOnPrem();

    const {
        data, isValidating,
    } = useSWR<ILicenseAndKey, Error>(
        isAdmin && !isUnlicensedMode ? licenseManagementAccountUrl : null,
        fetchAccountLicenseWithMlKeysAndUpdateAccountType,
    );

    const isLicenseInGracePeriod = useMemo(
        () => !isValidating && data && data.accountLicense.licenseStatus === 'GRACE_PERIOD',
        [ data, isValidating ]
    );

    return (
        <>
            {isAdmin && isLicenseInGracePeriod && (
                <div data-cy="ui-license-in-grace-period-banner">
                    <UiAlertBanner
                        type="warning"
                        closeable={false}>
                        <FormattedMessage
                            id="CLIENT_LICENSE_GRACE_PERIOD_WARNING_BANNER"
                            values={{
                                a: (msg: React.ReactNode[]) =>
                                    (
                                        <a
                                            className={classes.a}
                                            href="https://www.uipath.com/company/contact-us"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {msg}
                                        </a>
                                    ),
                            }}
                        />
                        <Link
                            className={classes.learnMoreLink}
                            target="_blank"
                            rel="noreferrer"
                            href={getLocalizedLink({ articleSlug: isOnPrem ? AboutLicensingLinkK8s : AboutLicensingLink })}
                            underline="hover"
                        >
                            {translate({ id: 'CLIENT_LEARN_MORE' })}
                        </Link>
                    </UiAlertBanner>
                </div>
            )}
        </>
    );
};
