import {
    getDominantPersona,
    PERSONA_PRIORITY_ORDER,
} from '@experiences/util';
import { useCallback } from 'react';

import useUserInfo from '../../auth/hooks/UserInfo';
import { getPersona } from '../../services/organization/Persona';
import { getUserOrganizationInfo } from '../../services/organization/UserOrganizationInfoService';
import { setUserProfile } from '../../store/action/UserProfileAction';
import { UserRole } from '../constants/Constant';

export const useUpdateUserProfilePersona = () => {
    const { token } = useUserInfo();

    const updateUserProfilePersonaPersona = useCallback(
        async () => {

            const [ userOrganizationInfo, personaFit ] = await Promise.all([
                getUserOrganizationInfo(token),
                getPersona(token),
            ]);
            if (userOrganizationInfo && personaFit) {
                userOrganizationInfo.persona = getDominantPersona(
                    personaFit,
                    userOrganizationInfo?.accountRoleType !== UserRole.DEFAULT_USER,
                    userOrganizationInfo?.accountUserDto?.accountType,
                    PERSONA_PRIORITY_ORDER);
                await setUserProfile(userOrganizationInfo);
            }
        },
        [ token ],
    );

    return updateUserProfilePersonaPersona;
};
