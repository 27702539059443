import {
    getDateFromNow,
    getFormattedDate,
} from '@experiences/util';
import type { CancelTokenSource } from 'axios';

import {
    LicenseType,
    productCodeToLicenseTypeMap,
    UsageAggregationType,
    UsageConstants,
} from '../../../common/constants/Constant';
import store from '../../../store';
import { getLicenseTimelineData } from '../../../store/action/RobotUsageAction';

interface IUsageMap {
    [key: string]: number | undefined;
}

interface IUsageParsedResponse {
    [x: string]: number | string;
    date: string;
}

interface IUsageData {
    data: IUsageParsedResponse[];
    present: boolean;
}

interface IProductUsageTimeline {
    clientId: string;
    productCode: string;
    productUsage: IUsageMap;
}
export const getInitialUsageData = (duration: number, language: string) => ({
    data: getDefaultLicenseMap(duration).map(map => ({
        ...map,
        date: map.date.toLocaleDateString(language === 'en' ? 'en-US' : language, {
            day: 'numeric',
            month: 'numeric',
        }),
    })),
    present: false,
});

const getDefaultLicenseMap = (duration: number) => Array.from({ length: duration }, (_: any, i) => {
    const date = getDateFromNow(-i);

    return {
        date,
        [LicenseType.ATTENDED_NAMED_USER]: 0,
        [LicenseType.ATTENDED_CONCURRENT_USER]: 0,
        [LicenseType.UNATTENDED_ROBOT]: 0,
        [LicenseType.TA_UNATTENDED_ROBOT]: 0,
        [LicenseType.NON_PRODUCTION_ROBOT]: 0,
        [LicenseType.APP_TESTER_ROBOT]: 0,
        [LicenseType.STUDIO_NAMED_USER]: 0,
        [LicenseType.STUDIO_X_NAMED_USER]: 0,
        [LicenseType.STUDIO_PRO_NAMED_USER]: 0,
        [LicenseType.STUDIO_CONCURRENT_USER]: 0,
        [LicenseType.STUDIO_X_CONCURRENT_USER]: 0,
        [LicenseType.STUDIO_PRO_CONCURRENT_USER]: 0,
        [LicenseType.AI_ROBOT]: 0,
        [LicenseType.AI_GPU]: 0,
        [LicenseType.AI_DOCUMENT_UNDERSTANDING]: 0,
        [LicenseType.ROBOT_UNITS]: 0,
        [LicenseType.AI_UNITS]: 0,
    };
}).reverse();

export const aggregateUsageData = async (
    {
        duration, language, usageAggregationType, tenantIds, source,
    }: {
        url: string;
        duration: number;
        language: string;
        usageAggregationType: string;
        tenantIds?: string[];
        source?: CancelTokenSource;
    }) => {
    const accountGlobalId = store.getState().profile.accountUserDto.accountGlobalId.toLowerCase();
    const clientIds = tenantIds ?? [];
    if (usageAggregationType === UsageAggregationType.ORGANIZATION) {
        clientIds.push(accountGlobalId);
    }
    return (await getTimelineData(duration, language, clientIds, usageAggregationType, source)) as IUsageData;
};

const getTimelineData = async (
    day: number,
    language: string,
    clientIds: string[],
    usageAggregationType: string,
    source?: CancelTokenSource,
) => {
    const result = await getLicenseTimelineData(clientIds, UsageConstants.MAX_DAYS_DATA, usageAggregationType, source);
    return parseLicenseTimelineData(result as IProductUsageTimeline[], language, day);
};

const parseLicenseTimelineData = (data: IProductUsageTimeline[], language: string, duration: number) => {
    let present = false;

    const dateLicenseMap = getDefaultLicenseMap(duration);

    data.filter(productTimeline => !!productTimeline.productUsage).forEach(productTimeline => {
        Object.keys(productTimeline.productUsage).forEach(key => {
            const licenseEntry = Object.values(dateLicenseMap).find(map => key.includes(getFormattedDate(map.date)));
            if (licenseEntry) {
                present = true;
                (licenseEntry[toLicenseType(productTimeline.productCode) as string] as number) +=
                    productTimeline.productUsage[key] ?? 0;
            }
        });
    });

    return {
        data: dateLicenseMap.map(map => ({
            ...map,
            date: map.date.toLocaleDateString(language === 'en' ? 'en-US' : language, {
                day: 'numeric',
                month: 'numeric',
            }),
        })),
        present,
    };
};

const toLicenseType = (productCode: string) => productCodeToLicenseTypeMap.has(productCode) ? productCodeToLicenseTypeMap.get(productCode) : '';
