import WebAssetOffIcon from '@mui/icons-material/WebAssetOff';
import React from 'react';

import { FloatingButton } from './FloatingButton';

export const MicrofrontendsDisabler = () => <FloatingButton
    badgeCount={0}
    Icon={WebAssetOffIcon}
    iconIndex={2}
    tooltipContent="Disable Microfrontends"
    onClick={() => {
        // remove the microfrontends cookie by setting it to expire in the past
        document.cookie = 'microfrontends=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        window.location.reload();
    }} />;
