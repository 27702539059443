import { Features } from '@experiences/feature-flags';
import { ProfileTab } from '@experiences/interfaces';
import { UiSuspensefulOutlet } from '@experiences/ui-common';
import React from 'react';

import {
    AccessibilitySettings,
    AddNotificationSettingsProfile,
    AddPersonalAccessToken,
    AddUserGroupProfile,
    ChangePassword,
    NotificationSettings,
    PersonalAccessToken,
    PrivacyAndSecuritySettings,
    Profile,
    ThemeSettings,
} from '../../common/constants/RouteNames';
import { routePaths } from '../../common/constants/routePaths';
import { CheckGuardComponent } from '../../container/helpers/CheckGuardComponent';
import type { UiRouteObject } from '../../container/routeConfigs/UiRouteObject';

const ProfilePageComponent = React.lazy(() => import('./ProfilePageComponent'));
const CreatePersonalAccessTokenDrawerComponent = React.lazy(() => import('./subcomponents/CreatePersonalAccessTokenDrawerComponent'));
const AddUserGroupModal = React.lazy(() => import('../notificationSettings/Admin/AddUserGroupModal'));
const ResetPasswordComponent = React.lazy(() => import('../users/subcomponents/ResetPasswordComponent'));

export const ProfileRoutes: UiRouteObject = {
    path: routePaths.profile,
    element: <UiSuspensefulOutlet />,
    children: [
        {
            index: true,
            redirectUrls: [ Profile ],
            element: <ProfilePageComponent type={ProfileTab.GeneralSettings} />,
        },
        {
            path: routePaths.profileThemeSettings,
            redirectUrls: [ ThemeSettings ],
            element: <CheckGuardComponent flag={Features.EnableHighContrastLightTheme.name}>
                <ProfilePageComponent type={ProfileTab.ThemeSettings} />
            </CheckGuardComponent>,
        },
        {
            path: routePaths.profileAccessibilitySettings,
            redirectUrls: [ AccessibilitySettings ],
            element:
            <CheckGuardComponent flag={Features.EnableAlertDuration.name}>
                <ProfilePageComponent type={ProfileTab.AccessibilitySettings} />
            </CheckGuardComponent>,
        },
        {
            path: routePaths.profilePrivacyAndSecuritySettings,
            redirectUrls: [ PrivacyAndSecuritySettings ],
            element: <CheckGuardComponent flag={Features.EnablePrivacyAndSecuritySettings.name}>
                <ProfilePageComponent type={ProfileTab.PrivacyAndSecuritySettings} />
            </CheckGuardComponent>,
            children: [
                {
                    path: routePaths.profilePrivacyAndSecuritySettingsChangePassword,
                    redirectUrls: [ ChangePassword ],
                    element: <ResetPasswordComponent />,
                    explicitEnvironments: [ 'k8s' ],
                },
            ],
        },
        {
            path: routePaths.profilePersonalAccessToken,
            redirectUrls: [ PersonalAccessToken ],
            element: <CheckGuardComponent flag={Features.EnableReferenceTokens.name}>
                <ProfilePageComponent type={ProfileTab.PersonalAccessTokenSettings} />
            </CheckGuardComponent>,
            children: [
                {
                    path: routePaths.profilePersonalAccessTokenAdd,
                    redirectUrls: [ AddPersonalAccessToken ],
                    element: <CreatePersonalAccessTokenDrawerComponent />,
                },
            ],
        },
        {
            path: routePaths.profileNotificationSettings,
            redirectUrls: [ NotificationSettings ],
            element: <CheckGuardComponent flag={Features.EnableNotificationSettings.name}>
                <ProfilePageComponent type={ProfileTab.NotificationSettings} />
            </CheckGuardComponent>,
            children: [
                {
                    path: routePaths.profileNotificationSettingsAddUserGroup,
                    redirectUrls: [ AddUserGroupProfile ],
                    element: <AddUserGroupModal />,
                },
                {
                    path: routePaths.profileNotificationSettingsEditProfile,
                    redirectUrls: [ AddNotificationSettingsProfile ],
                },
            ],
        },
    ],
};
