import { IntlProvider } from '@experiences/locales';
import { ApolloThemeProvider } from '@experiences/theme';
import { getEnvVariableValue } from '@experiences/util';
import React, {
    useEffect,
    useState,
} from 'react';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router';
import { SWRConfig } from 'swr';

import { getRouter } from './Router';
import store from './store';

const Router = React.memo(() => {
    const [ router, setRouter ] = useState<any>(undefined);

    useEffect(() => {
        getRouter().then(setRouter);
    }, []);

    return router ? <RouterProvider router={router} /> : null;
});

export const App = () => {
    const SWR_DEDUPE_INTERVAL = getEnvVariableValue('SWR_DEDUPE_INTERVAL');

    return (
        <Provider store={store}>
            <IntlProvider>
                <ApolloThemeProvider>
                    <SWRConfig
                        value={{
                            revalidateOnFocus: false,
                            dedupingInterval: ((window as any).Cypress ? 0 : SWR_DEDUPE_INTERVAL) ?? 2000,
                        }}
                    >
                        <Router />
                    </SWRConfig>
                </ApolloThemeProvider>
            </IntlProvider>
        </Provider>
    );
};
