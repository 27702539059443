import type {
    ITenant,
    WidgetContext,
    WidgetItem,
    WidgetTemplate,
} from '@experiences/interfaces';

import { getTenants } from '../services/getTenants';

export const TenantsWidget: WidgetTemplate<ITenant> = {
    name: 'Tenants',
    titleKey: 'CLIENT_ORCHESTRATOR_SERVICES',
    emptyMsgKey: (ctx: WidgetContext) => ctx.isAdmin ? 'CLIENT_NO_SERVICES' : 'CLIENT_NO_SERVICES_USER',
    clickHandler: (data: ITenant, ctx: WidgetContext) => {
        window.location.assign(`${window.location.origin}/${ctx.accountLogicalName}/${data.name}/orchestrator_`);
    },
    cardRows: 4,
    cardHeight: 64,
    fetch: (ctx: WidgetContext): Promise<ITenant[]> => getTenants(ctx.organizationGuid, ctx.accessToken, ctx.accountLogicalName, true),
    type: 'Card',
    transform: (data: ITenant[]): Array<WidgetItem<ITenant>> => {
        data = data.filter(
            (tenant) => (tenant.status?.toUpperCase() === 'ENABLED' || tenant.status?.toUpperCase() === 'UPDATING')
                && tenant.tenantServiceInstances?.some(service => service.serviceType === 'orchestrator'),
        );
        return data.map((tenant) => ({
            data: tenant,
            clickable: true,
            header: tenant.name,
            description: '',
            category: '',
            isSubtitleTime: true,
            subtitleTimePrefixKey: 'CLIENT_LAST_UPDATED',
            subtitle: tenant.updatedOn,
        }));
    },
    showEmptyStateButton: (ctx: WidgetContext) => ctx.isAdmin,
    emptyStateButtonText: 'CLIENT_CREATE_NEW',
    emptyStateButtonHandler: (ctx: WidgetContext) => {
        window.location.assign(`${window.location.origin}/${ctx.accountLogicalName}/portal_/admin`);
    },
};
