import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import type { IDirectoryEntry } from '@experiences/interfaces';
import {
    UiProgressButton,
    UiText,
} from '@experiences/ui-common';
import {
    getSortedUserBundleConfigurations,
    useModalState,
    useNavigateWithParams,
    useShowDialog,
} from '@experiences/util';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import type { SourceFilter } from '@uipath/portal-shell/loader';
import { PortalPeoplePicker } from '@uipath/portal-shell-react';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useSWR, { useSWRConfig } from 'swr';

import useUserInfo from '../../../auth/hooks/UserInfo';
import { notificationType } from '../../../common/constants/Constant';
import * as RouteNames from '../../../common/constants/RouteNames';
import { useOrchRoleAssignmentForAX } from '../../../common/hooks/useOrchRoleAssignmentForAX';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import type { IGroupPickerAllocations } from '../../../common/interfaces/licenses';
import { groupUrl } from '../../../services/identity/GroupService';
import type { ISubmitGroupAllocations } from '../../../services/licensing/accountant/UserLicenseService';
import {
    deleteGroupLicenseAllocation,
    getGroupLicenseAllocation,
    putGroupLicenseAllocation,
    userLicenseUrl,
} from '../../../services/licensing/accountant/UserLicenseService';
import {
    getUserLicenseAllocation,
    userLicenseUri,
} from '../../../services/licensing/UserLicenseService';
import {
    accountGlobalId,
    EnableUserLicensingSelector,
} from '../../../store/selectors';
import { decodeSanitizedHtml } from '../../../util/DecodeSanitizedHtml';
import { UiDrawer } from '../../common/UiDrawer';
import UiForm from '../../common/UiForm';
import { LicensingAllocationFormComponent } from './LicensingAllocationFormComponent';
import { sendPendoTrackEventForAKit } from './LicensingHelpers';

const useStyles = makeStyles(theme =>
    createStyles({
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        body: { margin: '0px 24px' },
        cancelButton: { marginRight: '10px' },
        groupText: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            lineHeight: '20px',
            style: 'normal',
        },
        groupAllocationMessage: { marginTop: '24px' },
        groupPicker: { marginTop: '15px' },
        heading: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontWeight: 600,
            marginTop: '15px',
            marginBottom: '2px',
        },
    }),
);

const AddEditGroupAllocationComponent: React.FC<{ type: 'add' | 'edit' }> = ({ type }) => {
    const EnableExternalUserLicense = useFeatureFlagValue(Features.EnableExternalUserLicense.name);

    const classes = useStyles();
    const createDialog = useShowDialog();
    const { formatMessage: translate } = useIntl();
    const createNotification = useUiSnackBar();
    const setErrorMessage = useCentralErrorSetter();
    const accountGlobalGuid = useSelector(accountGlobalId);
    const { token } = useUserInfo();

    const navigate = useNavigateWithParams();
    const { state } = useLocation();
    const group = useMemo(() => state?.group, [ state ]);
    const previousLocation = useMemo(() => state?.previousLocation!, [ state ]);

    const EnableUserLicensing = useSelector(EnableUserLicensingSelector);

    const { getErrorMessage } = useGetErrorInfo();

    const {
        open, close,
    } = useModalState(previousLocation ?? RouteNames.Licensing);

    const {
        showUseOrchRoleAssignmentDialogForAX, isLoadingTenantOrchRoleInfo,
    } = useOrchRoleAssignmentForAX();

    const [ loading, setLoading ] = useState(false);

    const defaultPeoplePickerGroup: IGroupPickerAllocations = useMemo(() => ({
        id: '',
        name: '',
        group: undefined,
        userBundleLicenses: [],
        useExternalLicense: false,
    }), []);

    const methods = useForm<IGroupPickerAllocations>({
        mode: 'onChange',
        defaultValues: defaultPeoplePickerGroup,
    });

    const isEdit = useMemo(() => type === 'edit', [ type ]);
    const showGroupPicker = useMemo(() => !isEdit && group == null, [ isEdit, group ]);
    const {
        handleSubmit, getValues, setValue, formState, reset, control, clearErrors, setError, watch, formState: { errors },
    } = methods;

    const { isDirty } = formState;

    const title = useMemo(
        () =>
            isEdit
                ? 'CLIENT_GROUPS_EDIT_GROUP_ALLOCATION_RULE'
                : showGroupPicker
                    ? 'CLIENT_CREATE_GROUP_ALLOCATION_RULE'
                    : 'CLIENT_GROUPS_CREATE_GROUP_ALLOCATION_RULE',
        [ isEdit, showGroupPicker ],
    );

    const { mutate } = useSWRConfig();

    const {
        data: editGroupData, isValidating: editGroupLoading,
    } = useSWR(
        isEdit && group ? {
            url: `${userLicenseUrl}/group`,
            id: group.id,
        } : null,
        getGroupLicenseAllocation,
    );

    const {
        data: addGroupData, isValidating: addGroupLoading,
    } = useSWR(
        !isEdit ? {
            url: `${userLicenseUri}/api/account/${accountGlobalGuid}/user-license`,
            accountGlobalId: accountGlobalGuid,
        } : null,
        getUserLicenseAllocation,
    );

    const validUserBundleCodes = useMemo(() => getSortedUserBundleConfigurations().map(s => s.code), []);

    const [ allocatedUserBundles, availableUserBundles, useExternalLicense ] = useMemo(
        () =>
            isEdit
                ? [
                    editGroupData?.allocatedUserBundles,
                    editGroupData?.availableUserBundles.filter(p => validUserBundleCodes.includes(p.code)),
                    editGroupData?.useExternalLicense,
                ]
                : [
                    [],
                    addGroupData?.filter(ubl => ubl.total > 0).filter(p => validUserBundleCodes.includes(p.code)),
                    false,
                ],
        [ editGroupData, addGroupData, isEdit, validUserBundleCodes ],
    );

    useEffect(() => {
        if (!EnableUserLicensing || (!group && isEdit)) {
            console.warn('Feature not enabled or missing data. Closing...');
            navigate('/invalidurl');
        }
    }, [ isEdit, EnableUserLicensing, group, navigate ]);

    useEffect(() => {
        if (allocatedUserBundles) {
            reset(
                merge(defaultPeoplePickerGroup, {
                    userBundleLicenses: allocatedUserBundles,
                    useExternalLicense,
                }),
            );
        }
    }, [ allocatedUserBundles, useExternalLicense, reset, defaultPeoplePickerGroup ]);

    const confirmAllocationOverrideDialog = useCallback(
        async (groupName: string, licenseSelectionIsEmpty: boolean) => await createDialog({
            title: `${translate({ id: 'CLIENT_CREATE_GROUP_ALLOCATION_RULE' })}`,
            body: (
                <div>
                    <p>
                        {translate({ id: 'CLIENT_OVERRIDE_GROUP_ALLOCATION' }, { 0: groupName })}
                    </p>
                    <p>
                        {translate({ id: 'CLIENT_OVERRIDE_GROUP_ALLOCATION_CONTINUE' })}
                    </p>
                    {licenseSelectionIsEmpty && (
                        <p data-cy="delete-warning">
                            {translate({ id: 'CLIENT_DELETE_EXISTING_GROUP_ALLOCATION_RULE_DETAILS' })}
                        </p>
                    )}
                </div>
            ),
            icon: 'warning',
            primaryButtonText: `${translate({ id: 'CLIENT_SAVE' })}`,
            showCancel: true,
        }),
        [ translate, createDialog ],
    );

    const shouldShowWarningDialog = useCallback(
        (payload: ISubmitGroupAllocations) => {
            const licenseAllocationIsDowngraded = allocatedUserBundles?.some(
                userBundle => payload.licenses.indexOf(userBundle) === -1,
            );
            const allocationRuleAlreadyExists = allocatedUserBundles?.length !== 0 || useExternalLicense;
            return (
                isEdit &&
                allocationRuleAlreadyExists &&
                (licenseAllocationIsDowngraded || useExternalLicense !== payload.useExternalLicense)
            );
        },
        [ allocatedUserBundles, isEdit, useExternalLicense ],
    );

    const onSubmit = useCallback(
        async (data: IGroupPickerAllocations) => {
            setLoading(true);
            try {
                const licenses = data.useExternalLicense ? [] : data.userBundleLicenses!;
                const externalLicense = data.useExternalLicense ?? false;
                const emptyLicenseSelection = licenses.length === 0 && !externalLicense;
                const peoplePickerGroup = data.group satisfies IDirectoryEntry;

                let allocationRuleAlreadyExists;
                if (showGroupPicker) {
                    if (!data.group) {
                        setError('group', { type: 'required' });
                        setLoading(false);
                        return;
                    } else if (peoplePickerGroup?.identifier == null) {
                        setError('group', { type: 'invalid' });
                        setLoading(false);
                        return;
                    }
                    const groupLicense = await getGroupLicenseAllocation({ id: peoplePickerGroup.identifier });
                    allocationRuleAlreadyExists =
                        groupLicense.allocatedUserBundles.length !== 0 || groupLicense.useExternalLicense;
                    if (allocationRuleAlreadyExists) {
                        const proceed = await confirmAllocationOverrideDialog(
                            peoplePickerGroup.displayName,
                            emptyLicenseSelection,
                        );
                        if (!proceed) {
                            setLoading(false);
                            return;
                        }
                    }
                }
                if (emptyLicenseSelection && !isEdit && !allocationRuleAlreadyExists) {
                    setError('userBundleLicenses', { type: 'invalid' });
                    setLoading(false);
                    return;
                }
                const groupId = showGroupPicker ? peoplePickerGroup.identifier : group!.id;
                const payload: ISubmitGroupAllocations = {
                    groupId,
                    licenses,
                    useExternalLicense: externalLicense,
                };

                if (!emptyLicenseSelection) {
                    if (shouldShowWarningDialog(payload)) {
                        const proceed = await createDialog({
                            title: translate({ id: 'CLIENT_SAVE_CHANGES' }),
                            body: `${translate({ id: 'CLIENT_EDIT_GROUP_ALLOCATION_RULE_WARNING' })}`,
                            icon: 'warning',
                            showCancel: true,
                            primaryButtonText: translate({ id: 'CLIENT_SAVE' }),
                        });

                        if (!proceed) {
                            setLoading(false);
                            return;
                        }
                    }
                    await putGroupLicenseAllocation(payload);

                    sendPendoTrackEventForAKit(allocatedUserBundles, licenses);
                    if (!allocatedUserBundles?.some(bundle => bundle === 'AKIT') && licenses.includes('AKIT')) {
                        showUseOrchRoleAssignmentDialogForAX();
                    }

                    createNotification(
                        translate(
                            { id: 'CLIENT_GROUP_UPDATED' },
                            { 0: decodeSanitizedHtml(showGroupPicker ? peoplePickerGroup.displayName : group!.name) },
                        ),
                        notificationType.SUCCESS,
                    );
                    await mutate({
                        url: `${groupUrl}/licenses?partitionGlobalId=${accountGlobalGuid}`,
                        partitionGlobalId: accountGlobalGuid,
                    });
                } else {
                    if (isEdit) {
                        const proceed = await createDialog({
                            title: translate({ id: 'CLIENT_GROUPS_DELETE_GROUP_ALLOCATION_RULE' }),
                            body: `${translate({ id: 'CLIENT_GROUPS_DELETE_GROUP_ALLOCATION_RULE_DETAILS' })}`,
                            icon: 'warning',
                            showCancel: true,
                            primaryButtonText: translate({ id: 'CLIENT_DELETE' }),
                        });
                        if (!proceed) {
                            setLoading(false);
                            return;
                        }
                    }
                    await deleteGroupLicenseAllocation(payload.groupId);
                    createNotification(
                        translate(
                            { id: 'CLIENT_GROUPS_DELETE_GROUP_ALLOCATION_RULE_SUCCESS' },
                            { 0: showGroupPicker ? peoplePickerGroup.displayName : group!.name },
                        ),
                        notificationType.SUCCESS,
                    );
                    await mutate(`${userLicenseUrl}/users`);
                    await mutate({
                        url: `${groupUrl}/licenses?partitionGlobalId=${accountGlobalGuid}`,
                        partitionGlobalId: accountGlobalGuid,
                    });
                }
            } catch (error) {
                setLoading(false);
                setErrorMessage(await getErrorMessage(error));
                close();
                return;
            }
            setLoading(false);
            close(true);
        },
        [
            close,
            createNotification,
            group,
            setErrorMessage,
            translate,
            createDialog,
            confirmAllocationOverrideDialog,
            isEdit,
            setError,
            showGroupPicker,
            shouldShowWarningDialog,
            getErrorMessage,
            accountGlobalGuid,
            allocatedUserBundles,
            mutate,
            showUseOrchRoleAssignmentDialogForAX,
        ],
    );

    const orgHasUserLicenses = useMemo(
        () =>
            EnableExternalUserLicense || (availableUserBundles?.length ?? 0) + (allocatedUserBundles?.length ?? 0) > 0,
        [ EnableExternalUserLicense, availableUserBundles?.length, allocatedUserBundles?.length ],
    );

    const peoplePickerChangedCallback = useCallback((event: any) => {
        const prevWithoutChip = omit(getValues('group'), [ 'optionId', 'chipType' ]);
        const curWithoutChip = omit(event.detail.data, [ 'optionId', 'chipType' ]);
        const valuesChanged = !isEqual(prevWithoutChip, curWithoutChip);
        setValue('group', event.detail.data, {
            shouldDirty: valuesChanged,
            shouldValidate: valuesChanged,
        });
    }, [ getValues, setValue ]);

    const peoplePickerErrorCallback = useCallback((event: any) => {
        if (event.detail) {
            setError('group', { type: 'invalid' });
        } else {
            clearErrors('group');
        }
    }, [ clearErrors, setError ]);

    const peoplePickerLoadingCallback = useCallback((event: any) => setLoading(event.detail), []);

    return (
        <UiDrawer
            title={translate({ id: title }, { 0: group?.name })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(),
            }}
            loading={editGroupLoading || addGroupLoading || isLoadingTenantOrchRoleInfo}
        >
            <FormProvider {...methods}>
                <UiForm
                    onSubmit={handleSubmit(onSubmit)}
                    actions={
                        <>
                            <div className={classes.actions}>
                                <Button
                                    className={classes.cancelButton}
                                    onClick={() => close()}
                                    color="primary">
                                    {translate({ id: 'CLIENT_CANCEL' })}
                                </Button>
                                <UiProgressButton
                                    loading={loading}
                                    disabled={
                                        !isDirty ||
                                        (type === 'add' && watch('userBundleLicenses')?.length === 0 && !watch('useExternalLicense'))
                                    }
                                    onClick={handleSubmit(onSubmit)}
                                    variant="contained"
                                    data-cy="save-submit-button"
                                >
                                    {translate({ id: 'CLIENT_SAVE' })}
                                </UiProgressButton>
                            </div>
                        </>
                    }
                    isDrawer
                >
                    {orgHasUserLicenses && (
                        <>
                            {showGroupPicker && (
                                <div
                                    className={classes.groupPicker}
                                    data-cy="group-picker">
                                    <Controller
                                        control={control}
                                        name="group"
                                        render={({ field }) => (
                                            <>
                                                <PortalPeoplePicker
                                                    token={token || undefined}
                                                    sourceFilters={[
                                                        'localGroups',
                                                        'directoryGroups',
                                                    ] satisfies SourceFilter[]}
                                                    value={field.value ? [ field.value ] : undefined}
                                                    singleMode
                                                    onPeoplePickerChanged={peoplePickerChangedCallback}
                                                    onPeoplePickerError={peoplePickerErrorCallback}
                                                    onPeoplePickerLoading={peoplePickerLoadingCallback}
                                                    onPeoplePickerResolving={peoplePickerLoadingCallback}
                                                />
                                                {errors.group?.type === 'required' &&
                                                <FormHelperText
                                                    error
                                                    id="my-helper-text">
                                                    {translate(
                                                        { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                                        { 0: translate({ id: 'CLIENT_GROUPS' }) },
                                                    )}
                                                </FormHelperText>}
                                            </>
                                        )}
                                    />
                                </div>
                            )}
                            <div className={showGroupPicker ? '' : classes.groupAllocationMessage}>
                                <UiText className={classes.heading}>
                                    {translate({ id: 'CLIENT_SELECT_LICENSES' })}
                                </UiText>
                                <UiText className={classes.groupText}>
                                    {translate({ id: 'CLIENT_GROUPS_ALLOCATION_RULE_MESSAGE' })}
                                </UiText>
                            </div>
                        </>
                    )}
                    <LicensingAllocationFormComponent
                        availableUserBundles={availableUserBundles}
                        allocatedUserBundles={allocatedUserBundles}
                        isAllocationForAGroup
                    />
                </UiForm>
            </FormProvider>
        </UiDrawer>
    );
};

export default AddEditGroupAllocationComponent;
