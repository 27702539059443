import { PORTAL_PREFIX } from '@experiences/constants';
import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import { Entity } from '@experiences/interfaces';
import { GlobalStyles } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import type { IProductConfiguration } from '@experiences/util';
import {
    getSortedUserBundleConfigurations,
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import {
    makeStyles,
    useTheme,
} from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useCallback,
    useMemo,
} from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import {
    useLocation,
    useMatch,
} from 'react-router-dom';

import { ConcurrentUserLicenses } from '../../../common/constants/Constant';
import * as RouteNames from '../../../common/constants/RouteNames';
import { routePaths } from '../../../common/constants/routePaths';
import type {
    IGroupInheritance,
    IUserLicenseAllocation,
} from '../../users/interfaces/userLicense';
import type { IUserLicenseExplicitAllocation } from '../interfaces/license';
import { LicenseAllocationMethod } from '../interfaces/license';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        checkboxLabel: {
            marginTop: '2px',
            marginBottom: 0,
            alignItems: 'flex-start',
        },
        checkBox: { marginTop: '-8px' },
        checkboxText: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            marginTop: '2px',
        },
        switchLabel: {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        tooltipIcon: {
            padding: '3px',
            marginLeft: '4px',
        },
        explicitAllocationSection: { marginLeft: '28px' },
        radioButtonLabel: { marginBottom: 0 },
        viewDetails: {
            color: theme.palette.semantic.colorForegroundLink,
            cursor: 'pointer',
            fontSize: '12px',
            '&:hover': { textDecoration: 'underline' },
        },
        inheritedSublabel: {
            marginLeft: '32px',
            lineHeight: '12px',
        },
        overallocatedInfo: {
            color: theme.palette.semantic.colorForegroundDisable,
            lineHeight: '16px',
            fontSize: '12px',
        },
        overallocatedWarning: {
            color: theme.palette.semantic.colorErrorText,
            lineHeight: '16px',
            fontSize: '12px',
        },
        overallocatedIcon: {
            color: theme.palette.semantic.colorInfoForeground,
            paddingLeft: '3px',
            margin: '1px',
        },
        overallocatedContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        userBundleLicensesError: {
            color: theme.palette.semantic.colorErrorText,
            marginTop: '4px',
            fontSize: '12px',
        },
        groupColumn: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        noLicensesAvailable: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontWeight: 600,
            marginTop: '24px',
            marginBottom: '6px',
        },
        switchMargin: { marginLeft: '12px' },
    }),
}));

type ILicenseGenericWithCode = { code: string } & Partial<IUserLicenseAllocation>;

export const LicensingAllocationFormComponent: React.FC<{
    availableUserBundles?: ILicenseGenericWithCode[];
    allocatedUserBundles?: string[];
    groupLicenseInheritance?: IGroupInheritance[];
    userId?: string;
    withRadioGroup?: boolean;
    isAllocationForAGroup?: boolean;
}> = ({
    availableUserBundles,
    allocatedUserBundles,
    groupLicenseInheritance,
    userId,
    withRadioGroup,
    isAllocationForAGroup,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigateWithParams();
    const location = useLocation();

    const getRoute = useRouteResolver();

    const sortedAndValidUserBundleCodes = useMemo(() => getSortedUserBundleConfigurations().map(s => s.code), []);
    const EnableExternalUserLicense = getFeatureFlagValue(Features.EnableExternalUserLicense.name);

    const sortingFunction = useCallback(
        (a: ILicenseGenericWithCode, b: ILicenseGenericWithCode) =>
            sortedAndValidUserBundleCodes.indexOf(a.code) - sortedAndValidUserBundleCodes.indexOf(b.code),
        [ sortedAndValidUserBundleCodes ],
    );

    const { formatMessage: translate } = useIntl();
    const {
        getValues,
        watch,
        control,
        formState: { errors },
    } = useFormContext<IUserLicenseExplicitAllocation>();
    const allUserBundles = useMemo(() => {
        const availableUserBundleCodes = availableUserBundles?.map(u => u.code);
        const unavailableUserBundleCodes = allocatedUserBundles?.filter(
            x => availableUserBundleCodes?.indexOf(x) === -1,
        );
        const unavailableLicenses = unavailableUserBundleCodes?.map(
            l => ({
                code: l,
                allocated: 0,
                total: 0,
            } as ILicenseGenericWithCode),
        );

        if (availableUserBundles && unavailableLicenses) {
            const unsortedBundles = [ ...availableUserBundles, ...unavailableLicenses ];
            return unsortedBundles.sort(sortingFunction);
        }
        return availableUserBundles?.sort(sortingFunction);
    }, [ availableUserBundles, allocatedUserBundles, sortingFunction ]);
    const handleCheck = useCallback(
        (checkedId: string) => {
            const { userBundleLicenses: ids } = getValues();
            return ids.indexOf(checkedId) > -1 ? ids.filter(id => id !== checkedId) : [ ...(ids ?? []), checkedId ];
        },
        [ getValues ],
    );

    const userProducts = useMemo(() => getSortedUserBundleConfigurations(), []) as IProductConfiguration[];

    const [ watchedLicenseAllocation, watchedUseExternalLicense ] = watch([ 'licenseAllocationMethod', 'useExternalLicense' ]);

    const [ renderExplicitAllocations, renderGroupInheritanceText ] = useMemo(() => [
        watchedLicenseAllocation === LicenseAllocationMethod.EXPLICIT || !withRadioGroup,
        watchedLicenseAllocation === LicenseAllocationMethod.GROUP,
    ], [ watchedLicenseAllocation, withRadioGroup ]);

    const disableCheckbox = useCallback(
        (userBundle: ILicenseGenericWithCode) =>
            (EnableExternalUserLicense && watchedUseExternalLicense ||
            (!isAllocationForAGroup &&
                !(ConcurrentUserLicenses.indexOf(userBundle.code) > -1) &&
                userBundle.allocated !== undefined &&
                userBundle.total !== undefined &&
                userBundle.allocated >= userBundle.total &&
                allocatedUserBundles!.indexOf(userBundle.code) === -1)),
        [ allocatedUserBundles, watchedUseExternalLicense, isAllocationForAGroup ]);

    const licenseInheritanceText = useMemo(
        () =>
            groupLicenseInheritance?.length
                ? groupLicenseInheritance
                    .map(license => {
                        const names = license.useExternalLicense
                            ? translate({ id: 'CLIENT_GROUPS_EXTERNAL_LICENSE' })
                            : license.userBundleCodes.map(code => translate({ id: `CLIENT_${code}` })).join(', ');
                        return translate({ id: 'CLIENT_LICENSE_INHERITED_FROM' }, {
                            0: names,
                            1: license.group,
                        });
                    })
                    .join('; ')
                : undefined,
        [ groupLicenseInheritance, translate ],
    );

    const orgHasUserLicenses = useMemo(
        () => (allUserBundles?.length ?? 0) + (allocatedUserBundles?.length ?? 0) > 0,
        [ allUserBundles, allocatedUserBundles ],
    );

    const isProfileLicenses = useMatch({
        path: PORTAL_PREFIX + routePaths.adminIdentitiesProfileLicenses,
        end: false,
    });

    const getViewDetailsRoute = useCallback(() => {
        if (isProfileLicenses) {
            return routePaths.adminIdentitiesProfileLicensesInheritance.replace(':entity', Entity.USERS).replace(':id', userId ?? '');
        }

        return getRoute(
            location.pathname ===
                    getRoute(RouteNames.UsersUserEditLicenseAllocation)
                ? RouteNames.UsersUserLicenseInheritance
                : RouteNames.LicensingUserLicenseInheritance);
    }, [ getRoute, isProfileLicenses, location.pathname, userId ]);

    return (
        <div>
            {withRadioGroup && (
                <Controller
                    name="licenseAllocationMethod"
                    control={control}
                    render={({ field }) => (
                        <RadioGroup
                            {...field}
                            aria-labelledby="licenseAllocationMethodLabel"
                            style={{ color: theme.palette.semantic.colorForeground }}
                        >
                            <FormControlLabel
                                value={LicenseAllocationMethod.GROUP}
                                control={<Radio color="primary" />}
                                label={
                                    <div
                                        className={classes.switchLabel}
                                        id="licenseAllocationMethodLabel">
                                        {translate({ id: 'CLIENT_GROUP_ALLOCATION' })}
                                        <Tooltip title={translate({ id: 'CLIENT_USE_GROUP_ALLOCATION_RULE' })}>
                                            <InfoOutlined className={clsx(classes.tooltipIcon, classes.icon)} />
                                        </Tooltip>
                                    </div>
                                }
                                data-cy="invite-edit-group-allocation"
                                className={classes.radioButtonLabel}
                            />
                            {renderGroupInheritanceText && groupLicenseInheritance && (
                                <div className={classes.inheritedSublabel}>
                                    <UiText
                                        display="inline"
                                        style={{ fontSize: '12px' }}>
                                        {groupLicenseInheritance.length > 0
                                            ? licenseInheritanceText
                                            : translate({ id: 'CLIENT_NO_LICENSE_INHERITANCE' })}
                                    </UiText>
                                    &nbsp;
                                    <UiText
                                        display="inline"
                                        className={classes.viewDetails}
                                        onClick={() => navigate(getViewDetailsRoute(), { state: { userId } })}
                                        data-cy="view-details">
                                        {translate({ id: 'CLIENT_VIEW_DETAILS' })}
                                    </UiText>
                                </div>
                            )}
                            <FormControlLabel
                                value={LicenseAllocationMethod.EXPLICIT}
                                control={<Radio color="primary" />}
                                label={
                                    <div className={classes.switchLabel}>
                                        {translate({ id: 'CLIENT_ALLOCATE_EXPLICITLY' })}
                                        <Tooltip title={translate({ id: 'CLIENT_USE_EXPLICIT_ALLOCATION_RULE' })}>
                                            <InfoOutlined className={classes.tooltipIcon} />
                                        </Tooltip>
                                    </div>
                                }
                                data-cy="invite-edit-explicit-allocation"
                                className={classes.radioButtonLabel}
                            />
                        </RadioGroup>
                    )}
                />
            )}
            {renderExplicitAllocations && (
                <>
                    {orgHasUserLicenses || EnableExternalUserLicense ? (
                        <div className={withRadioGroup ? classes.explicitAllocationSection : ''}>
                            <FormGroup>
                                {orgHasUserLicenses && (
                                    <>
                                        {errors.userBundleLicenses && (
                                            <UiText
                                                data-cy="userBundleLicenses-error"
                                                className={classes.userBundleLicensesError}>
                                                {translate({ id: `CLIENT_USER_BUNDLE_LICENSES_REQUIRED_ERROR` })}
                                            </UiText>
                                        )}
                                        <Controller
                                            name="userBundleLicenses"
                                            control={control}
                                            render={({ field }) =>
                                                allUserBundles?.map(userBundle => (
                                                    <div
                                                        key={userBundle.code}
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                        }}
                                                    >
                                                        <FormControlLabel
                                                            key={`label-${userBundle.code}`}
                                                            className={classes.checkboxLabel}
                                                            data-cy="group-allocation-item"
                                                            control={
                                                                <Checkbox
                                                                    className={classes.checkBox}
                                                                    checked={field.value?.indexOf(userBundle.code) > -1}
                                                                    onChange={() =>
                                                                        field.onChange(handleCheck(userBundle.code))}
                                                                    size="small"
                                                                    data-cy="group-allocation-ubl"
                                                                    disabled={disableCheckbox(userBundle)}
                                                                />
                                                            }
                                                            label={
                                                                <div>
                                                                    <UiText
                                                                        className={classes.checkboxText}
                                                                        data-cy="license-name"
                                                                    >
                                                                        {translate({ id: `CLIENT_${userBundle.code}` })}
                                                                    </UiText>

                                                                    {userBundle.allocated !== undefined &&
                                                                        userBundle.total !== undefined &&
                                                                        (userBundle.allocated > userBundle.total ? (
                                                                            <div className={classes.overallocatedContainer}>
                                                                                <UiText
                                                                                    data-cy={`warning-${userBundle.code}`}
                                                                                    className={
                                                                                        classes.overallocatedWarning
                                                                                    }>
                                                                                    {translate(
                                                                                        { id: `CLIENT_PRODUCTS_OVER_ALLOCATED` },
                                                                                        {
                                                                                            0:
                                                                                                userBundle.allocated -
                                                                                                userBundle.total,
                                                                                        },
                                                                                    )}
                                                                                </UiText>
                                                                                <Tooltip
                                                                                    title={translate({ id: 'CLIENT_PRODUCTS_OVER_ALLOCATED_TOOLTIP' })}
                                                                                    arrow
                                                                                    data-cy="overallocation-tooltip"
                                                                                >
                                                                                    <InfoIcon
                                                                                        className={
                                                                                            classes.overallocatedIcon
                                                                                        }
                                                                                    />
                                                                                </Tooltip>
                                                                            </div>
                                                                        ) : (
                                                                            userProducts.find(p => p.code === userBundle.code)?.hideQuantity
                                                                                ? null :
                                                                                (<UiText
                                                                                    data-cy={`info-${userBundle.code}`}
                                                                                    className={classes.overallocatedInfo}>
                                                                                    {userBundle.total -
                                                                                            userBundle.allocated > 1 ?
                                                                                        translate(
                                                                                            { id: `CLIENT_PRODUCTS_AVAILABLE_PLURAL` },
                                                                                            {
                                                                                                0:
                                                                                                    userBundle.total -
                                                                                                    userBundle.allocated,
                                                                                            },
                                                                                        ) : translate(
                                                                                            { id: `CLIENT_PRODUCTS_AVAILABLE_SINGLE` },
                                                                                            {
                                                                                                0:
                                                                                                userBundle.total -
                                                                                                userBundle.allocated,
                                                                                            },
                                                                                        )}
                                                                                </UiText>)
                                                                        ))}
                                                                </div>
                                                            }
                                                        />
                                                    </div>
                                                )) as any}
                                        />
                                    </>
                                )}
                            </FormGroup>
                            {EnableExternalUserLicense && (
                                <Controller
                                    name="useExternalLicense"
                                    control={control}
                                    render={({ field }) => (
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    className={classes.switchMargin}
                                                    checked={field.value}
                                                    onChange={e => field.onChange(e.target.checked)}
                                                    color="primary"
                                                    data-cy="external-license-switch"
                                                />
                                            }
                                            label={
                                                <div className={classes.switchLabel}>
                                                    {translate({ id: 'CLIENT_USE_EXTERNAL_LICENSE' })}
                                                    <Tooltip
                                                        data-cy="external-license-tooltip"
                                                        title={translate({ id: 'CLIENT_ALLOW_USERS_EXTERNAL_LICENSE' })}>
                                                        <InfoOutlined className={classes.tooltipIcon} />
                                                    </Tooltip>
                                                </div>
                                            }
                                        />
                                    )}
                                />
                            )}
                        </div>
                    ) : (
                        <div className={classes.groupColumn}>
                            <UiText
                                className={classes.noLicensesAvailable}
                                data-cy="case-no-license-available">
                                {translate({ id: 'CLIENT_NO_LICENSE_AVAILABLE' })}
                            </UiText>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
