import {
    UiBackground,
    UiLogo,
    UiSessionId,
    UiText,
} from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import CancelIcon from '@mui/icons-material/Cancel';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import useStaticLogout from '../../auth/hooks/StaticLogout';
import * as RouteNames from '../../common/constants/RouteNames';
import { isAdminSelector } from '../../store/selectors';

const useStyles = makeStyles(theme =>
    createStyles({
        paperDefault: {
            width: '425px',
            minHeight: '370px',
            overflow: 'auto',
            marginTop: '100px',
            backgroundColor: theme.palette.semantic.colorBackgroundRaised,
        },
        headerContainer: {
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
            marginTop: '42px',
        },
        headerIcon: {
            height: '48px',
            width: '48px',
            marginTop: '16px',
            color: theme.palette.semantic.colorErrorIcon,
        },
        headerText: {
            marginTop: '16px',
            fontWeight: 600,
            fontSize: '18px',
        },
        headerSubText: {
            marginTop: '8px',
            width: '280px',
            textAlign: 'center',
        },
        button: { width: '100%' },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            width: '280px',
            marginTop: '32px',
            textAlign: 'center',
            marginBottom: '32px',
        },
    }),
);

/**
 * Display page when user enters wrong url
 */
const InvalidUrl: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const isAdmin = useSelector(isAdminSelector);
    const logout = useStaticLogout();
    const getRoute = useRouteResolver();
    const navigate = useNavigateWithParams();

    const login = useCallback(() => {
        logout();
    }, [ logout ]);

    const handleGoToProfile = useCallback(() => {
        navigate(getRoute(RouteNames.Profile));
    }, [ getRoute, navigate ]);

    return (
        <UiBackground center>
            <Paper className={classes.paperDefault}>
                <div className={classes.headerContainer}>
                    <UiLogo />
                    <CancelIcon className={classes.headerIcon} />
                    <UiText
                        className={classes.headerText}
                        data-cy="invalid-url-header">
                        {translate({ id: 'CLIENT_INVALID_BODY_HEADER' })}
                    </UiText>
                    <UiText className={classes.headerSubText}>
                        {translate({ id: 'CLIENT_INVALID_URL_BODY' })}
                    </UiText>
                    <div className={classes.buttonContainer}>
                        {!isAdmin && window.env.enableInvalidUrlGoToProfile && (
                            <Button
                                variant="contained"
                                className={classes.button}
                                onClick={handleGoToProfile}>
                                {translate({ id: 'CLIENT_GO_TO_PROFILE' })}
                            </Button>
                        )}
                        <Button
                            variant="contained"
                            className={classes.button}
                            onClick={login}>
                            {translate({ id: 'CLIENT_SIGN_IN' })}
                        </Button>
                    </div>
                    <UiSessionId style={{ marginBottom: '20px' }} />
                </div>
            </Paper>
        </UiBackground>
    );
};

export default InvalidUrl;
