import { resolveHostUsingEnvironmentInfo } from '@experiences/util';

export const getAuthDomain = () => {
    const identityBaseRoute = window.env.identityBaseRoute as string;

    const CISHostWithAPIPrefix =
        window.env?.CIS_HOST_PREFIX || `${resolveHostUsingEnvironmentInfo(window.location.host)}${identityBaseRoute}`;

    const CISAuthority = `https://${CISHostWithAPIPrefix}`;

    const CISHostConnect = `${CISAuthority}/connect`;

    const CISOpenIdConfiguration = `${CISAuthority}/.well-known/openid-configuration`;

    return {
        CISHostWithAPIPrefix,
        CISAuthority,
        CISHostConnect,
        CISOpenIdConfiguration,
    };
};
