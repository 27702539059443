import {
    UiErrorBoundary,
    UiLoader,
    UiText,
} from '@experiences/ui-common';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';

import PrivateShell from '../component/container/PrivateShell';
import { accountRoleType } from '../store/selectors';
import usePrivateShellRoutes from './routeConfigs/usePrivateShellRoutes';

const useStyles = makeStyles(theme =>
    createStyles({
        errorBoundary: {
            backgroundColor: theme.palette.semantic.colorBackground,
            width: '100%',
            height: '100%',
            margin: 'auto',
            padding: '24px',
        },
    }),
);

const ShellRouteWrapper: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const accountRole = useSelector(accountRoleType);
    const privateShellRoutes = usePrivateShellRoutes();
    const privateShellRoutesComponent = useRoutes(privateShellRoutes);

    const errorComponent = useMemo(() => <UiText className={classes.errorBoundary}>
        {translate({ id: 'CLIENT_GENERIC_ERROR_MESSAGE' })}
    </UiText>, [ classes.errorBoundary, translate ]);

    return (
        <PrivateShell>
            <UiErrorBoundary
                errorComponent={errorComponent}>
                {accountRole ? privateShellRoutesComponent : <UiLoader type="full" />}
            </UiErrorBoundary>
        </PrivateShell>
    );
};

export default ShellRouteWrapper;
