import { createBrowserRouter } from 'react-router-dom';

import getRoutes from './container/routeConfigs/routes';
import {
    createSentryBrowserRouter,
    initializeSentry,
} from './util/SentryUtil';

/**
 * Get the router
 * @returns The router for the application
 */
export async function getRouter() {

    const sentryInitialized = await initializeSentry();
    const routes = getRoutes();

    if (sentryInitialized) {
        const sentryWrappedBrowserRouter = await createSentryBrowserRouter();
        return sentryWrappedBrowserRouter(routes);
    }

    return createBrowserRouter(routes);
}
