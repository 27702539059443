import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { UiSuspensefulOutlet } from '@experiences/ui-common';
import type { PropsWithChildren } from 'react';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
    AuditLogDialog,
    AuditLogs,
} from '../../common/constants/RouteNames';
import { routePaths } from '../../common/constants/routePaths';
import BreadcrumbProvider from '../../common/providers/BreadcrumbProvider';
import type { UiRouteObject } from '../../container/routeConfigs/UiRouteObject';

const AuditPageComponentLegacy = React.lazy(() => import('./AuditPageComponentLegacy'));
const AuditPage = React.lazy(() => import('./AuditPage'));
const AuditDialogComponentLegacy = React.lazy(() => import('./AuditDialogComponentLegacy'));

const HostAuditProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { formatMessage: translate } = useIntl();

    const breadCrumbLinks = useMemo(() => [
        {
            index: 0,
            link: routePaths.admin,
            name: translate({ id: 'CLIENT_HOST' }),
        },
        {
            index: 1,
            link: routePaths.adminAudit,
            name: translate({ id: 'CLIENT_AUDIT_LOG' }),
        },
    ], [ translate ]);

    return <BreadcrumbProvider breadcrumbs={breadCrumbLinks}>
        {children}
    </BreadcrumbProvider>;
};

const AuditPageSwitcherComponent: React.FC<PropsWithChildren> = () => {
    const EnableUnifiedAuditExperience = useFeatureFlagValue(Features.EnableUnifiedAuditExperience.name);

    return EnableUnifiedAuditExperience
        ? <AuditPage />
        : <AuditPageComponentLegacy />;
};

export const HostAuditRoutes: UiRouteObject = {
    path: routePaths.adminHostAudit,
    element: <HostAuditProvider>
        <UiSuspensefulOutlet />
    </HostAuditProvider>,
    children: [
        {
            path: routePaths.adminHostAudit,
            redirectUrls: [ AuditLogs ],
            element: <AuditPageSwitcherComponent />,
            children: [
                {
                    path: routePaths.adminHostAuditDialog,
                    redirectUrls: [ AuditLogDialog ],
                    element: <AuditDialogComponentLegacy />,
                },
            ],
        },
    ],
};

// export const TenantAuditRoutes: UiRouteObject = {
//     path: routePaths.adminTenantAudit,
//     element: <AuditProvider isTenant>
//         <UiSuspensefulOutlet />
//     </AuditProvider>,
//     children: [
//         {
//             path: routePaths.adminTenantAudit,
//             redirectUrls: [ TenantAudit ],
//             element: <AuditPage />,
//         },
//     ],
// };
