import { PORTAL_PREFIX } from '@experiences/constants';
import { getEnvVariableValue } from '@experiences/util';
import React from 'react';
import urljoin from 'url-join';

import { NavigateWithParams } from './NavigateWithParams';
import type { UiRouteObject } from './UiRouteObject';

function fixNestedRoute(originalRoute: string, parentRoute: string) {
    return originalRoute.replace(parentRoute + '/', '');
}

export function recursiveRouteReplace(routeObjects: UiRouteObject[], parentRoute: string) {
    return routeObjects.filter(routeObj => routeObj.explicitEnvironments == null
        || routeObj.explicitEnvironments.includes(getEnvVariableValue('name')?.toLowerCase() ?? '')
    ).map(routeObj => {
        // Create a shallow copy of the route object to avoid mutating the original
        const newRouteObj = { ...routeObj };

        // Update the path if necessary
        const originalRoute = newRouteObj.path;
        if (originalRoute && !newRouteObj.index) {
            newRouteObj.path = fixNestedRoute(originalRoute, parentRoute);
        }

        // Recursively update children if they exist
        if (newRouteObj.children) {
            newRouteObj.children = recursiveRouteReplace(newRouteObj.children, originalRoute ?? parentRoute);
        }

        // Return the updated route object
        return newRouteObj;
    });
}

export function flattenRedirectUrls(
    routeObjects: UiRouteObject[],
    parentRoute = PORTAL_PREFIX,
    flattenedRouteObjects: UiRouteObject[] = [],
) {
    for (const routeObj of routeObjects) {
        if (routeObj.children) {
            flattenRedirectUrls(routeObj.children, routeObj.path ?? parentRoute, flattenedRouteObjects);
        }

        if (routeObj.explicitEnvironments != null
            && routeObj.explicitEnvironments.length > 0
            && !routeObj.explicitEnvironments.includes(getEnvVariableValue('name')?.toLowerCase() ?? '')) {
            continue;
        }

        routeObj.redirectUrls?.forEach(redirectUrl => {
            const obj = {
                ...routeObj,
                path: redirectUrl,
                element: <NavigateWithParams
                    to={urljoin(PORTAL_PREFIX, routeObj.path ?? parentRoute)}
                    replace
                    originalRoute={redirectUrl}
                    logger
                />,
            };
            delete obj.redirectUrls;
            delete obj.index;
            delete obj.children;
            flattenedRouteObjects.push(obj);
        });
    }

    return flattenedRouteObjects;
}
