import { PORTAL_PREFIX } from '@experiences/constants';
import { userGlobalId } from '@experiences/ecommerce';
import {
    DirectoryEntityType,
    Entity,
} from '@experiences/interfaces';
import {
    useNavigateWithParams,
    useShowDialog,
} from '@experiences/util';
import type { AxiosError } from 'axios';
import {
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    useMatch,
    useParams,
} from 'react-router-dom';
import useSWR from 'swr';

import { routePaths } from '../../../common/constants/routePaths';
import useSimpleGroup from '../../../common/hooks/SimpleGroup';
import { useCheckSSOEnabled } from '../../../common/hooks/useCheckSSOEnabled';
import type { IGroup } from '../../../common/interfaces/cis/group';
import type { IUserCIS } from '../../../common/interfaces/cis/user';
import { resolveById } from '../../../services/identity/DirectoryService';
import {
    checkIfOnlyUserInGroup,
    getGroupById,
} from '../../../services/identity/GroupService';
import { getUser } from '../../../services/identity/UserService';
import { accountGlobalId } from '../../../store/selectors';
import { UserGroup } from '../../common/UserGroups';
import { ProfileTabs } from './ProfileConstants';

async function getEntityById({
    entity, id, partitionGlobalId, groupIds,
}: { entity: Entity; id: string; partitionGlobalId: string; groupIds: string[] }): Promise<IUserCIS | IGroup | undefined> {
    const isGroup = entity.toLowerCase() === Entity.GROUPS;
    try {
        return isGroup ? await getGroupById({
            partitionGlobalId,
            id,
        }) : await getUser({ userGlobalId: id });
    } catch (error) {
        if ((error as AxiosError)?.response?.status !== 404) {
            throw error;
        }
    }

    const directoryEntity = await resolveById({
        partitionGlobalId,
        entityId: id,
        entityType: isGroup ? DirectoryEntityType.group : DirectoryEntityType.user,
        groupIds,
    });

    return {
        id: directoryEntity.identifier,
        members: [],
        name: directoryEntity.firstName,
        surName: directoryEntity.lastName,
        groupIDs: directoryEntity.groups?.map(group => group.identifier),
        ...directoryEntity,
    } as IUserCIS | IGroup;
}

export const useProfileComponentViewModel = () => {
    const { formatMessage: translate } = useIntl();
    const {
        entity, id,
    } = useParams() as { entity: Entity; id: string };

    const partitionGlobalId = useSelector(accountGlobalId);
    const currentUserId = useSelector(userGlobalId);
    const navigate = useNavigateWithParams();

    const isGroup = entity.toLowerCase() === Entity.GROUPS;

    const isSSOConnectionEnabled = useCheckSSOEnabled();
    const {
        data: lastAdminInOrg, isLoading: lastAdminLoading,
    } = useSWR(
        !isGroup && !window.env.disableUsersLeaveOrg && !isSSOConnectionEnabled
            ? {
                partitionGlobalId,
                userGlobalId: id,
                groupId: UserGroup.Administrators,
            }
            : null,
        checkIfOnlyUserInGroup,
    );

    const isUserGroupMemberships = useMatch({
        path: PORTAL_PREFIX + routePaths.adminIdentitiesProfileGroupMemberships,
        end: false,
    });

    const isGroupMembers = useMatch({
        path: PORTAL_PREFIX + routePaths.adminIdentitiesProfileMembers,
        end: false,
    });

    const isAccess = useMatch({
        path: PORTAL_PREFIX + routePaths.adminIdentitiesProfileAccess,
        end: true,
    });

    const isLicenses = useMatch({
        path: PORTAL_PREFIX + routePaths.adminIdentitiesProfileLicenses,
        end: false,
    });

    const isInfo = useMatch({
        path: PORTAL_PREFIX + routePaths.adminIdentitiesProfileInfo,
        end: true,
    });

    const tabValue = useMemo(() => {
        if (isUserGroupMemberships) {
            return ProfileTabs.GROUP_MEMBERSHIPS;
        }
        if (isGroupMembers) {
            return ProfileTabs.MEMBERS;
        }
        if (isLicenses) {
            return ProfileTabs.LICENSES;
        }
        if (isAccess) {
            return ProfileTabs.ACCESS;
        }
        if (isInfo) {
            return ProfileTabs.INFO;
        }
        return ProfileTabs.INFO;
    }, [ isAccess, isGroupMembers, isInfo, isLicenses, isUserGroupMemberships ]);

    const {
        groups, loading: groupsLoading,
    } = useSimpleGroup(!!isUserGroupMemberships);

    const createDialog = useShowDialog();

    const {
        data, isLoading, mutate, error,
    } = useSWR(groupsLoading ? null : {
        entity,
        id,
        partitionGlobalId,
        groupIds: groups.map(group => group.id),
    }, getEntityById);

    useEffect(() => {
        if (error) {
            (async () => {
                await createDialog({
                    title: translate({ id: 'CLIENT_PROFILE_LOADING_ERROR' }),
                    body: translate({ id: 'CLIENT_PROFILE_LOADING_ERROR_BODY' }),
                    icon: 'error',
                    showCancel: false,
                    primaryButtonText: translate({ id: 'CLIENT_VIEW_ACCOUNTS_AND_LOCAL_GROUPS' }),
                });

                navigate(routePaths.adminIdentitiesUsers);
            })();
        }
    }, [ error, createDialog, translate, navigate ]);

    const getGroupsForUser = useCallback((userGroupIds: string[] = []) =>
        groups.filter(group => userGroupIds.indexOf(group.id) > -1)
    , [ groups ]);

    const formatProfileRoute = useCallback((
        route: string,
        {
            routeId = id, routeEntity = entity, tenantId,
        }: { routeId?: string; routeEntity?: Entity; tenantId?: string } = {
            routeEntity: entity,
            routeId: id,
        },
    ) =>
        route.replace(':entity', routeEntity.toLowerCase())
            .replace(':id', routeId)
            + (tenantId ? `?tenantId=${tenantId}` : ''),
    [ entity, id ]);

    const isCurrentUser = useMemo(() => isGroup
        ? data?.id === UserGroup.Everyone || (data as IGroup)?.members?.findIndex(member => member.identifier === currentUserId) > -1
        : currentUserId === id,
    [ currentUserId, id, isGroup, data ]);

    const isLeaveOrgEnabled = useMemo(() => !isGroup
        && !window.env.disableUsersLeaveOrg
        && !isSSOConnectionEnabled
        && isCurrentUser
        && !lastAdminInOrg,
    [ isCurrentUser, isGroup, isSSOConnectionEnabled, lastAdminInOrg ]);

    return {
        entity: entity.toLowerCase() as Entity,
        id,
        partitionGlobalId,
        isGroup,
        tabValue,
        data,
        loading: isLoading,
        groupsLoading,
        lastAdminLoading,
        getGroupsForUser,
        lastAdminInOrg,
        groups,
        mutate,
        formatProfileRoute,
        isCurrentUser,
        currentUserId,
        isLeaveOrgEnabled,
    };
};
