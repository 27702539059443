import React from 'react';

import useIsOnPrem from '../../../common/hooks/useIsOnPrem';
import { UiLicenseInGracePeriodBanner } from './UiLicenseInGracePeriodBanner';
import { UiLicenseInGracePeriodBannerK8s } from './UiLicenseInGracePeriodBannerK8s';

export default (props: any) => {
    const isOnPrem = useIsOnPrem();

    return isOnPrem ? <UiLicenseInGracePeriodBannerK8s {...props} /> : <UiLicenseInGracePeriodBanner {...props} />;
};
