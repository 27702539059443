import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import {
    getTenants,
    organizationManagementTenantUri,
} from '../../services/organization/TenantService';
import {
    accountGlobalId,
    accountLogicalName,
    isUnlicensedSelector,
} from '../../store/selectors';
import { Unlicensed } from '../licensing/Unlicensed';
import UsageChartsComponent from '../usage/UsageChartsComponent';
import HomePageHeader from './helper/HomePageHeader';

export const HomePageLicensesComponent: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const organizationGuid = useSelector(accountGlobalId);
    const accountName = useSelector(accountLogicalName);
    const isUnlicensedMode = useSelector(isUnlicensedSelector);
    const theme = useTheme();

    const { data: tenants } = useSWR(
        {
            url: organizationManagementTenantUri,
            organizationGuid,
            accountName,
        },
        getTenants,
    );

    const tenantIds = useMemo(() => (tenants ? tenants.map(tenant => tenant.id) : undefined), [ tenants ]);

    return (
        <div data-cy="home-page-license-widget">
            <HomePageHeader
                fontWeight={600}
                lineHeight='24px'
                color={theme.palette.semantic.colorForeground}
                label={translate({ id: 'CLIENT_LICENSE_ALLOCATION' })}
                fontSize="20px" />
            {isUnlicensedMode ? <Box sx={{ marginTop: '32px' }}>
                <Unlicensed />
            </Box> :
                <UsageChartsComponent
                    tenantIds={tenantIds}
                    isServiceMode={false} />}
        </div>
    );
};
