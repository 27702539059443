import { ENVIRONMENT } from '@experiences/constants';
import { CentralErrorHandlingProvider } from '@experiences/error';
import { isLocalFeatureFlagsEnabled } from '@experiences/feature-flags';
import { useUpdateLocalizationRoute } from '@experiences/locales';
import {
    ServiceOrderProvider,
    SnackbarProvider,
    UiDialogProvider,
    UiErrorBoundary,
    UiSuspensefulOutlet,
} from '@experiences/ui-common';
import { getEnvironment } from '@experiences/util';
import React from 'react';

import { PortalAuthenticationProvider } from '../auth/PortalAuthenticationProvider';
import { useTabName } from '../component/common/useTabName';
import { FeatureFlagOverrideDrawer } from '../component/diagnostics/FeatureFlagOverrideDrawer';
import { MicrofrontendsDisabler } from '../component/diagnostics/MicrofrontendsDisabler';

const App = () => {
    useTabName();
    useUpdateLocalizationRoute();
    // only show the microfrontends disabler on local and alp when the cookie is present
    const showMicrofrontendsDisabler = [ ENVIRONMENT.LOCAL, ENVIRONMENT.ALP ].includes(getEnvironment()) && document.cookie.includes('microfrontends');

    return (
        <CentralErrorHandlingProvider>
            <SnackbarProvider>
                <PortalAuthenticationProvider>
                    <UiDialogProvider>
                        <ServiceOrderProvider>
                            <UiErrorBoundary>
                                <UiSuspensefulOutlet />
                                {isLocalFeatureFlagsEnabled && <FeatureFlagOverrideDrawer />}
                                {showMicrofrontendsDisabler && <MicrofrontendsDisabler />}
                            </UiErrorBoundary>
                        </ServiceOrderProvider>
                    </UiDialogProvider>
                </PortalAuthenticationProvider>
            </SnackbarProvider>
        </CentralErrorHandlingProvider>
    );
};
export default App;
