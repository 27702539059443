import { OrganizationSettingTab } from '@experiences/interfaces';
import { UiSuspensefulOutlet } from '@experiences/ui-common';
import type { UiRouteObject } from '@experiences/util';
import type { PropsWithChildren } from 'react';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { LoginCustomization } from '../../common/constants/RouteNames';
import { routePaths } from '../../common/constants/routePaths';
import BreadcrumbProvider from '../../common/providers/BreadcrumbProvider';

const OrganizationSettingsPageComponent = React.lazy(() => import('./OrganizationSettingsPageComponent'));

const HostSettingsProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { formatMessage: translate } = useIntl();

    const breadCrumbLinks = useMemo(() => [
        {
            index: 0,
            link: routePaths.admin,
            name: translate({ id: 'CLIENT_HOST' }),
        },
        {
            index: 1,
            link: routePaths.adminHostSettings,
            name: translate({ id: 'CLIENT_SETTINGS' }),
        },
    ], [ translate ]);

    return <BreadcrumbProvider breadcrumbs={breadCrumbLinks}>
        {children}
    </BreadcrumbProvider>;
};

export const HostSettingsRoutes: UiRouteObject = {
    path: routePaths.adminHostSettings,
    element: <HostSettingsProvider>
        <UiSuspensefulOutlet />
    </HostSettingsProvider>,
    children: [
        {
            path: routePaths.adminHostSettingsLoginCustomization,
            redirectUrls: [ LoginCustomization ],
            element: <OrganizationSettingsPageComponent type={OrganizationSettingTab.LOGIN_CUSTOMIZATION} />,
        },
    ],
};
