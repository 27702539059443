import type { SvgIconTypeMap } from '@mui/material';
import {
    Badge,
    IconButton,
} from '@mui/material';
import type { OverridableComponent } from '@mui/material/OverridableComponent';
import { makeStyles } from '@mui/styles';
import { ApTooltip } from '@uipath/portal-shell-react';
import React from 'react';

const useStyles = makeStyles((theme) =>
    ({
        button: {
            position: 'fixed',
            bottom: ({ iconIndex }: { iconIndex: number }) => theme.spacing(6 * iconIndex - 4),
            left: theme.spacing(2),
            zIndex: 1000,
        },
        root: {
            height: 'calc(100vh - 200px)',
            overflow: 'hidden',
            overflowY: 'auto',
        },
    })
);

export const FloatingButton = ({
    onClick,
    badgeCount,
    Icon,
    iconIndex = 1,
    tooltipContent,
}: {
    onClick: () => void;
    badgeCount: number;
    Icon: OverridableComponent<SvgIconTypeMap> & { muiName: string };
    iconIndex?: number;
    tooltipContent?: string;
}) => {
    const classes = useStyles({ iconIndex });

    return (
        <Badge
            aria-hidden
            className={classes.button}
            badgeContent={badgeCount}
            overlap="circular"
            color="error">
            <ApTooltip
                content={tooltipContent}
                placement='right'>
                <IconButton
                    onClick={onClick}
                    color="primary"
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            onClick();
                        }
                    }}>
                    <Icon />
                </IconButton>
            </ApTooltip>
        </Badge>
    );
};
