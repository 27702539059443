import { OrganizationSettingTab } from '@experiences/interfaces';
import { UiSuspensefulOutlet } from '@experiences/ui-common';
import type { PropsWithChildren } from 'react';
import React, {
    Suspense,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';

import {
    EditOrganizationMigration,
    LoginCustomization,
    OrganizationMigration,
    OrganizationSettings,
    OrganizationSettingsAdvanced,
    OrganizationSettingsWarning,
} from '../../common/constants/RouteNames';
import { routePaths } from '../../common/constants/routePaths';
import { useOrganizationName } from '../../common/hooks/useOrganizationName';
import BreadcrumbProvider from '../../common/providers/BreadcrumbProvider';
import type { UiRouteObject } from '../../container/routeConfigs/UiRouteObject';

const OrganizationSettingsPageComponent = React.lazy(() => import('./OrganizationSettingsPageComponent'));
const OrgMigrationPage = React.lazy(() => import('./subcomponents/OrgMigration/OrgMigrationPage'));
const OrgMigrationDetailsDrawer = React.lazy(() => import('./subcomponents/OrgMigration/OrgMigrationDetailsDrawer'));
const AccountLogicalNameWarningDialogComponent = React.lazy(() => import('./subcomponents/AccountLogicalNameWarningDialogComponent'));

const OrganizationSettingsProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { formatMessage: translate } = useIntl();

    const organizationName = useOrganizationName();

    const breadCrumbLinks = useMemo(() => [
        {
            index: 0,
            link: routePaths.admin,
            name: organizationName ?? translate({ id: 'CLIENT_HOST' }),
        },

        {
            index: 1,
            link: routePaths.adminSettingsGeneral,
            name: translate({ id: 'CLIENT_SETTINGS' }),
        },
        {
            index: 1,
            link: routePaths.adminSettingsAdvanced,
            name: translate({ id: 'CLIENT_SETTINGS' }),
        },
        {
            index: 1,
            link: routePaths.adminSettings,
            name: translate({ id: 'CLIENT_SETTINGS' }),
        },
    ], [ organizationName, translate ]);

    return <BreadcrumbProvider breadcrumbs={breadCrumbLinks}>
        {children}
    </BreadcrumbProvider>;
};

export const AdminSettingsRoutes: UiRouteObject = {
    path: routePaths.adminSettings,
    element: <OrganizationSettingsProvider>
        <UiSuspensefulOutlet />
    </OrganizationSettingsProvider>,
    children: [
        {
            path: routePaths.adminSettings,
            redirectUrls: [ OrganizationSettings ],
            element: <OrganizationSettingsPageComponent type={OrganizationSettingTab.GENERAL} />,
        },
        {
            path: routePaths.adminSettingsGeneral,
            redirectUrls: [ OrganizationSettings ],
            element: <>
                <OrganizationSettingsPageComponent type={OrganizationSettingTab.GENERAL} />
                <UiSuspensefulOutlet />
            </>,
            children: [
                {
                    path: routePaths.adminSettingsGeneralWarning,
                    redirectUrls: [ OrganizationSettingsWarning ],
                    element: <AccountLogicalNameWarningDialogComponent />,
                },
            ],
        },
        {
            path: routePaths.adminSettingsAdvanced,
            redirectUrls: [ OrganizationSettingsAdvanced ],
            element: <>
                <OrganizationSettingsPageComponent type={OrganizationSettingTab.ADVANCED} />
                <UiSuspensefulOutlet />
            </>,
            children: [
                {
                    path: routePaths.adminSettingsAdvancedMigrationEdit,
                    redirectUrls: [ EditOrganizationMigration ],
                    element: <OrgMigrationDetailsDrawer />,
                },
            ],
        },
        {
            path: routePaths.adminSettingsAdvancedMigration,
            redirectUrls: [ OrganizationMigration ],
            element: <Suspense>
                <OrgMigrationPage />
            </Suspense>,
        },
        {
            path: routePaths.adminSettingsLoginCustomization,
            redirectUrls: [ LoginCustomization ],
            element: <OrganizationSettingsPageComponent type={OrganizationSettingTab.LOGIN_CUSTOMIZATION} />,
        },
    ],
};

