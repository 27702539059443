import {
    useAppError,
    useGetErrorInfo,
} from '@experiences/error';
import type { LanguageCulture } from '@experiences/locales';
import {
    getUserLanguageFromLocalStorage,
    useUpdateLanguage,
} from '@experiences/locales';
import {
    getDominantPersona,
    PERSONA_PRIORITY_ORDER,
    UiStorage,
} from '@experiences/util';
import {
    useCallback,
    useState,
} from 'react';
import { useIntl } from 'react-intl';

import { UserRole } from '../../common/constants/Constant';
import { getPersona } from '../../services/organization/Persona';
import { getUserOrganizationInfo } from '../../services/organization/UserOrganizationInfoService';
import { setUserProfile } from '../../store/action/UserProfileAction';
import useUserInfo from './UserInfo';

export default function useInit() {
    const [ initializing, setInitializing ] = useState(false);
    const { formatMessage: translate } = useIntl();
    const { getErrorObject } = useGetErrorInfo();

    const updateLanguage = useUpdateLanguage();

    const {
        ErrorComponent, renderError, setError, clearError,
    } = useAppError('login');

    const { token } = useUserInfo();

    const changeLanguage = useCallback(
        (id: LanguageCulture) => updateLanguage(id),
        [ updateLanguage ],
    );

    const init = useCallback(async () => {
        try {
            setInitializing(true);

            const [ userOrganizationInfo, personaFit ] = await Promise.all([
                getUserOrganizationInfo(token),
                getPersona(token).catch(e => undefined),
            ]);

            userOrganizationInfo.persona = getDominantPersona(
                personaFit,
                userOrganizationInfo?.accountRoleType !== UserRole.DEFAULT_USER,
                userOrganizationInfo?.accountUserDto?.accountType,
                PERSONA_PRIORITY_ORDER);

            UiStorage.setItem('PORTAL_CURRENT_USERID', userOrganizationInfo.userGlobalId);

            await setUserProfile(userOrganizationInfo);

            changeLanguage(
                getUserLanguageFromLocalStorage(userOrganizationInfo?.accountUserDto?.accountLanguage ?? 'en')
            );
        } catch (error) {
            const errorObject = await getErrorObject(error);
            setError(
                error,
                'Error occurred while getting user organization info',
                clearError,
                {
                    title: translate({ id: 'CLIENT_ERROR_ORGANIZATION_INFO' }),
                    message: errorObject,
                },
            );
        } finally {
            setInitializing(false);
        }
    }, [
        token,
        changeLanguage,
        getErrorObject,
        setError,
        clearError,
        translate,
    ]);

    return {
        init,
        initializing,
        ErrorComponent,
        renderError,
    };
}
