import type { IConsumable } from '@experiences/interfaces';
import { UiText } from '@experiences/ui-common';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { useIntl } from 'react-intl';

import { ConsumptionGraphGroup } from '../../Consumables/ConsumptionGraphGroup';
import UsageDetailsComponent from '../../UsageDetailsComponent';

interface UiChartSection {
    product: string;
    consumable?: IConsumable;
    tenantId?: string;
}

const useStyles = makeStyles(theme =>
    createStyles({
        title: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForeground,
            fontWeight: 600,
            marginTop: '16px',
            marginBottom: '7px',
        },
        subTitle: {
            marginTop: '4px',
            marginBottom: '4px',
        },
        boldText: { fontWeight: 600 },
    }));

export const UiChartSection: React.FC<UiChartSection> = ({
    product, consumable, tenantId,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    return (
        <div
            key={product}
            data-cy={`chart-section-${product}`}>
            <UiText
                className={classes.title}
                role="heading"
                aria-level={3}>
                {translate({ id: `CLIENT_CONSUMABLE_CHART_HEADER_${product}` },)}
            </UiText>
            {product === 'APICALLS' ? (
                <UsageDetailsComponent
                    entitlement={product} />
            ) : (
                consumable && <ConsumptionGraphGroup
                    product={product}
                    consumable={consumable}
                    tenantId={tenantId} />
            ) }
        </div>
    );
};
