import type {
    IHistoryStatus,
    ILicensePendingChange,
    IMonthlyUpsellRequest,
    IMonthlyUpsellStartDateValidation,
    IPackagesConfig,
    IPlanDetails,
    IProductPriceOptions,
} from '@experiences/ecommerce';

import type { ILicenseActivationResponse } from '../../common/interfaces/license.activation';
import {
    axiosDelete,
    get,
    post,
} from '../utility/Requests';

export const billingUrl = '/api/billing';

export async function getPackagesConfig({
    accountName, countryCode,
}: { url: string; accountName: string; countryCode?: string; closed?: boolean }) {
    const urlParams: any = { accountName };
    if (countryCode) {
        urlParams['countryCode'] = countryCode;
    }
    return await get<IPackagesConfig>(`${billingUrl}/packagesConfig`, { urlParams });
}

export async function getPackagesConfigByCurrency({
    accountName, currency, url: _url,
}: { accountName: string; currency?: string; url: string }) {
    const urlParams: any = { accountName };
    if (currency) {
        urlParams['currency'] = currency;
    }
    return await get<IPackagesConfig>(`${billingUrl}/packagesConfigByCurrency`, { urlParams });
}

export async function getProductsPricesInAllCurrencies({
    accountName, url: _url,
}: { accountName?: string; url: string }) {
    const urlParams = { accountName };
    return await get<IProductPriceOptions[]>(`${billingUrl}/productsPricesInAllCurrencies`, { urlParams });
}

export async function getCurrentCycleProductsPricesInAllCurrencies({ accountName }: { url: string; accountName?: string }) {
    const urlParams = { accountName };
    return await get<IProductPriceOptions[]>(`${billingUrl}/currentCycleProductsPricesInAllCurrencies`, { urlParams });
}

export async function fetchPlanDetails({
    url: _url, accountName,
}: { url: string; accountName: string }) {
    const urlParams = { accountName };
    return await get<IPlanDetails>(`${billingUrl}/planDetails`, { urlParams });
}

export async function fetchHistoryStatus({
    url: _url, accountName,
}: { url: string; accountName: string }) {
    const urlParams = { accountName };
    return await get<IHistoryStatus>(`${billingUrl}/historyStatus`, { urlParams });
}

export async function goToStripeCustomerPortalSession(accountName: string) {
    const urlParams = { accountName };
    const stripeUrl = await post<string>(`${billingUrl}/createStripeCustomerSession`, {
        urlParams,
        body: { returnUrl: window.location.href },
    });
    window.location.assign(stripeUrl);
}

export async function getLicensePendingChange({
    accountName, stripeSubscriptionScheduleId,
}: { accountName: string; stripeSubscriptionScheduleId?: string }) {
    const urlParams = {
        accountName,
        stripeSubscriptionScheduleId,
    };
    return await get<ILicensePendingChange>(`${billingUrl}/getLicensePendingChange`, { urlParams });
}

export async function cancelLicensePendingChange(accountName: string, stripeSubscriptionScheduleId: string) {
    const urlParams = {
        accountName,
        stripeSubscriptionScheduleId,
    };
    return await axiosDelete(`${billingUrl}/cancelLicensePendingChange`, { urlParams });
}

export async function validateMonthlyUpsellStartDate(accountName: string, licenseCode: string) {
    const urlParams: any = { accountName };
    if (licenseCode) {
        urlParams['licenseCode'] = licenseCode;
    }
    return await get<IMonthlyUpsellStartDateValidation>(`${billingUrl}/monthlyUpsellStartDateValidation`, { urlParams });
}

export async function applyMonthlyUpsell(accountName: string, monthlyUpsellRequest: IMonthlyUpsellRequest) {
    return await post<ILicenseActivationResponse>(`${billingUrl}/monthlyUpsell`, {
        urlParams: { accountName },
        body: monthlyUpsellRequest,
    });
}
