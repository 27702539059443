import type { LegendRow } from '@uipath/apollo-lab';
import type { ChartValue } from '@uipath/apollo-lab/core/common/util';

export function addXAxisFooter(dataValues: LegendRow[], values: ChartValue[]) {
    if (values && values.length > 0 && values[0].value) {
        dataValues.push({
            cells: [
                {
                    text: `${(values[0].value.x)}`,
                    role: 'secondary',
                },
            ],
        });
        if (dataValues.length > 1) {
            dataValues[dataValues.length - 2].isSeparated = true;
        }
    }
}
