import html from 'nanohtml';

export const customLegendRenderer = ({
    borderColor,
    color,
    text,
}: {
    borderColor: string;
    color: string;
    text: string;
}) => html`
        <div style="justify-content: flex-start;">
          <div class="block"
                style="outline: 1px inset rgba(0, 0, 0, 0.1);
                      border-radius: 50%;
                      border: 1px solid ${borderColor};
                      background-color: ${color};
                      display: inline-block;">
          </div>
          <label class="primary">
            ${text}
          </label>
        </div>
    `;
