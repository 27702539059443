import type {
    TMOData,
    TMProject,
} from '@experiences/interfaces';
import { get } from '@experiences/util';

export function getTMProjects(accessToken: string, tenantName: string) {
    const url = `/${tenantName}/testmanager_/api/v2/projects?orderby=updated%20desc&top=20`;

    return get<TMOData<TMProject>>(url, {
        accessToken,
        stripPortalPath: true,
    });
}
