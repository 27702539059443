import { ContactUsLinks } from '@experiences/constants';
import type { IFreeTrial } from '@experiences/interfaces';
import { GlobalStyles } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import { useLocalizedLinks } from '@experiences/util';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { PortalCustomIcon } from '@uipath/portal-shell-react';
import React, { useEffect } from 'react';
import {
    FormattedDate,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import { useSWRConfig } from 'swr';

import useIsOnPrem from '../../../common/hooks/useIsOnPrem';
import { licenseManagementAccountConsumablesUrl } from '../../../services/licensing/management/AccountService';
import {
    subscriptionCodeToServiceName,
    trialServicesSwrKey,
} from '../../../services/licensing/TrialPerSku';
import { accountGlobalId } from '../../../store/selectors';
import { FreeTrialActionButtons } from './FreeTrialActionButtons';
import FreeTrialsHeader from './FreeTrialsHeader';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        content: {
            display: 'flex',
            flexDirection: 'column',
            padding: '16px',
            height: '100%',
            justifyContent: 'space-between',
            border: '1px solid',
            borderColor: theme.palette.semantic.colorBorderDeEmp,
            borderRadius: '3px',
        },
        textContent: {
            display: 'flex',
            minHeight: '60px',
            justifyContent: 'space-between',
        },
        entry: {
            flex: 1,
            minWidth: 0,
        },
        header: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        serviceTitle: {
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: '600',
            color: theme.palette.semantic.colorForeground,
            marginLeft: '12px',
        },
        actionButtons: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: '16px',
        },
        actionButton: {
            color: theme.palette.semantic.colorForegroundLink,
            cursor: 'pointer',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
            '&:hover': { textDecoration: 'underline' },
        },
        serviceDescription: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '16px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        serviceExpiredInformation: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: '16px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        serviceExpiredDate: {
            display: 'flex',
            justifyContent: 'flexStart',
        },
        loadingGrid: {
            display: 'flex',
            minHeight: '80px',
            justifyContent: 'space-between',
        },
        errorMessage: { margin: '40x' },
    }),
}));

export const FreeTrialsPanel: React.FC<{ freeTrials: IFreeTrial[] }> = ({ freeTrials }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const getLocalizedLink = useLocalizedLinks();

    const accountId = useSelector(accountGlobalId);
    const isOnPrem = useIsOnPrem();

    const { mutate } = useSWRConfig();

    useEffect(() => {
        const invalidateCache = async () => {
            await mutate({
                url: licenseManagementAccountConsumablesUrl,
                accountGlobalId: accountId,
                includeTrialConsumables: true,
            });
            await mutate(trialServicesSwrKey);
        };

        invalidateCache().then(() => {});

    }, [ accountId, mutate ]);

    return freeTrials && freeTrials.length > 0 ? (
        <>
            <FreeTrialsHeader />
            <Grid
                container
                spacing={3}>
                {freeTrials.map((freeTrial, i) => (
                    <Grid
                        key={i}
                        item
                        xs={4}
                        data-cy="trial-grid-item">
                        <div
                            tabIndex={i}
                            className={classes.content}
                            data-cy={`service-${freeTrial.subscriptionCode.toLowerCase()}`}>
                            <div className={classes.textContent}>
                                <div className={classes.entry}>
                                    <div
                                        className={classes.header}
                                        data-cy="trial-header">
                                        <PortalCustomIcon
                                            name={subscriptionCodeToServiceName[freeTrial.subscriptionCode]}
                                            size="32px" />
                                        <UiText className={classes.serviceTitle}>
                                            {translate({ id: `CLIENT_TRIAL_SERVICE_HEADER_${freeTrial.subscriptionCode}` })}
                                        </UiText>
                                    </div>
                                    {!freeTrial.isExpired ? (
                                        <UiText
                                            className={classes.serviceDescription}
                                            data-cy={`service-description-${freeTrial.subscriptionCode.toLowerCase()}`}>
                                            {translate({ id: `CLIENT_TRIAL_SERVICE_DESCRIPTION_${freeTrial.subscriptionCode}` })}
                                        </UiText>
                                    ) : (
                                        <div
                                            className={classes.serviceExpiredInformation}
                                            data-cy={`service-expired-information-${freeTrial.subscriptionCode.toLowerCase()}`}
                                        >
                                            <div className={classes.serviceExpiredDate} >
                                                <UiText>
                                                    {translate({ id: `CLIENT_TRIAL_EXPIRED` })}
                                                </UiText>
                                                <div style={{ marginLeft: '8px' }}>
                                                    {freeTrial.expiryDate && <UiText data-cy="trial-expired-date">
                                                        <FormattedDate
                                                            value={new Date(freeTrial.expiryDate * 1000)}
                                                            year="numeric"
                                                            month="short"
                                                            day="numeric"
                                                            timeZone="UTC"
                                                        />
                                                    </UiText>}
                                                </div>
                                            </div>
                                            <UiText>
                                                {translate({ id: 'CLIENT_TRIAL_SERVICE_EXPIRED_INFORMATION' })}
                                            </UiText>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={classes.actionButtons}>
                                {!freeTrial.isExpired ? !isOnPrem && (
                                    <FreeTrialActionButtons subscriptionCode={freeTrial.subscriptionCode} />
                                ) : (
                                    <a
                                        className={classes.actionButton}
                                        target="_blank"
                                        rel="noreferrer"
                                        href={getLocalizedLink(ContactUsLinks)}
                                        data-cy="learn-more-link"
                                    >
                                        {translate({ id: 'CLIENT_CONTACT_SALES' })}
                                    </a>
                                )}
                            </div>
                        </div>
                    </Grid>
                ))}
            </Grid>
        </>
    ) : null;
};
