import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    isGuid,
    UiStorage,
    useFilteredParams,
} from '@experiences/util';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const invalidOrganizationNames = [ 'portal_', 'host' ];

export default function useAuthParams() {
    const location = useLocation();

    const EnableAzureB2CAuthentication = useFeatureFlagValue(Features.EnableAzureB2CAuthentication.name);
    const EnableAzureB2CAuthenticationDefaultIdp = useFeatureFlagValue(Features.EnableAzureB2CAuthenticationDefaultIdp.name);

    const [ organizationId, isAuth0 ] = useFilteredParams([ 'organizationId', 'isAuth0' ]);

    const organizationName = useMemo(() => location.pathname.split('/')[1], [ location.pathname ]);

    const authParams = useMemo(() => {
        const loginExtraParams = {} as any;

        const acr_values =
      organizationId && isGuid(organizationId) ? `tenant:${organizationId}` : `tenantName:${organizationName}`;

        const govAuthorityId = window.env?.PORTAL_GOV_APPLICATION_IDENTITY_PROVIDER_ID;

        if (isAuth0) {
            if (EnableAzureB2CAuthentication && EnableAzureB2CAuthenticationDefaultIdp) {
                UiStorage.setItem('banner_seen', 'true');
                loginExtraParams['preferred_authority'] = 1;
            } else if (EnableAzureB2CAuthentication && govAuthorityId) {
                UiStorage.setItem('banner_seen', 'true');
                loginExtraParams['enforce_authority_for_testing_only'] = govAuthorityId;
            } else {
                loginExtraParams['preferred_authority'] = 1;
            }
        }

        const extraQueryParams = {
            ...loginExtraParams,
            hide_signup: true,
            type: 'login',
            signup_redirect_uri: `${window.location.origin}/portal_`,
            cloudrpa_signup_domain: `${window.location.hostname.toLowerCase()}`,
            cloudrpa_signup_subdomain: '/portal_',
            register_endpoint: '/register',
            company_code: 'B2B_CP',
            product_name: 'UiPath Automation Cloud',
            platform_name: 'UiPath Automation Cloud',
        };

        // check storage to see if banner_seen flag exists from gov cloud
        const banner_seen = UiStorage.getItem('banner_seen') ?? undefined;
        // if flag exists, send as param and remove from storage
        if (banner_seen) {
            extraQueryParams.banner_seen = banner_seen;
            UiStorage.removeItem('banner_seen');
        }

        return {
            acr_values,
            state: { returnTo: location.pathname + location.search },
            extraQueryParams,
        };
    }, [
        organizationId,
        organizationName,
        isAuth0,
        location.pathname,
        location.search,
        EnableAzureB2CAuthentication,
        EnableAzureB2CAuthenticationDefaultIdp,
    ]);

    const authParamsK8s = useMemo(() => {
        const params: any = {};

        const acr_values = organizationId && isGuid(organizationId)
            ? `tenant:${organizationId}`
            : organizationName && invalidOrganizationNames.indexOf(organizationName) === -1
                ? `tenantName:${organizationName}`
                : undefined;

        if (acr_values) {
            params.acr_values = acr_values;
        }

        return {
            ...params,
            state: { returnTo: location.pathname + location.search },
        };

    }, [ organizationName, organizationId, location ]);

    return window.env.isOnPrem ? authParamsK8s : authParams;
}
