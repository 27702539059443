import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import type {
    AccountType,
    IEntitlementUsage,
    IMlKey,
    IProductAllocation,
    ITabEntitlementUsage,
    ITabProductAllocation,
} from '@experiences/interfaces';
import type {
    IEntitlementUsageConfiguration,
    IProductConfiguration,
} from '@experiences/util';
import assign from 'lodash/assign';
import find from 'lodash/find';
import map from 'lodash/map';

export function extractProducts(
    productConfigs: IProductConfiguration[],
    productAllocations: IProductAllocation[],
    isServiceMode: boolean,
    isHome: boolean,
): ITabProductAllocation[] {
    const AccountLevelOnlyProductCodes = [ 'TIE', 'CV', 'AHUB', 'TMNG', 'PM' ];

    if (!getFeatureFlagValue(Features.EnableAiuAllocationOnTenant.name) && !AccountLevelOnlyProductCodes.includes('AIU')) {
        AccountLevelOnlyProductCodes.push('AIU');
    }

    return map(productConfigs, p => assign(p, find(productAllocations, { code: p.code }))).filter(p => {
        const isAvailable = !window.env.unavailableProductCodes?.includes(p.code) ?? true;
        const hasQuantity = p.allocated > 0 || p.total > 0;
        const isAccountLevel = !isServiceMode;
        const isProductForServices = AccountLevelOnlyProductCodes.indexOf(p.code) === -1;
        const hideInService = p.hideInService && isServiceMode;
        const hideInHomepage = p.hideInHomepage && isHome;
        return (
            isAvailable && hasQuantity && (isAccountLevel || isProductForServices) && !(hideInService || hideInHomepage)
        );
    });
}

function extractEntitlementUsage(
    config: IEntitlementUsageConfiguration,
    source: IEntitlementUsage | undefined): ITabEntitlementUsage {
    const poolSummary =
    map(config.poolSummary, ps => assign(ps, find(source?.poolSummary, { poolName: ps.poolName }))).filter (cp => cp.totalQuantity > 0);
    return assign(
        config,
        source,
        { poolSummary },
    ) as ITabEntitlementUsage;
}

export function extractEntitlementUsages(
    config: IEntitlementUsageConfiguration[],
    source: IEntitlementUsage[]
): ITabEntitlementUsage[] {
    return source && map(config, cfg => extractEntitlementUsage(cfg, find(source, { entitlement: cfg.entitlement }))).filter(e => {
        const hasQuantity = e.poolSummary.find(ps => ps.totalQuantity > 0);
        return hasQuantity;
    });
}

export function computeProductProperties(
    p: any,
    isHostMode: boolean,
    mlKeys: IMlKey[] | undefined,
    accountTypeSelector: AccountType | undefined,
    isGovCloud: boolean
): ITabProductAllocation {
    const extendedProduct = p as typeof p & Partial<ITabProductAllocation>;
    if (shouldComputeAPIKey(isHostMode, extendedProduct, isGovCloud)) {
        extendedProduct.licenseType = `MLSERVICE_${extendedProduct.customAPIKeyCode ?? extendedProduct.code}MODEL`;
        if (mlKeys) {
            const foundKey = mlKeys.find(key => key.licenseType === extendedProduct.licenseType);
            if (foundKey != null) {
                extendedProduct.mlServiceKey = foundKey.mlServiceKey;
            }
        }
    }

    if (extendedProduct.renderWithSubscriptionPlan) {
        extendedProduct.subscriptionPlan = accountTypeSelector;
    }

    if (extendedProduct.hideProgressForAccountType === accountTypeSelector) {
        extendedProduct.hideProgress = true;
    }

    if (extendedProduct.hideQuantityForAccountType === accountTypeSelector) {
        extendedProduct.hideQuantity = true;
    }

    if (extendedProduct.type === 'UNLIMITED' && isHostMode) {
        extendedProduct.hideProgress = false;
        extendedProduct.hideQuantity = false;
    }

    return extendedProduct;
}

function shouldComputeAPIKey(isHostMode: boolean, extendedProduct: any, isGovCloud: boolean) {
    const MLServicesWithNoAPIKeyGovCloud = [ 'CV' ];

    return !isHostMode && extendedProduct.hasAPIKey && window.env.enableApiKeys &&
        ((isGovCloud && MLServicesWithNoAPIKeyGovCloud.indexOf(extendedProduct.code) === -1) || !isGovCloud);
}
