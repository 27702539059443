import type {
    IMigrationRequest,
    IMigrationResponse,
    IMigrationStatus,
    IRegionsPerService,
} from '../../common/interfaces/gws';
import {
    axiosDelete,
    get,
    post,
} from '../utility/Requests';

export const tenantMigrationUrl = '/api/move/tenants';

export function getScheduledTenantMigrationStatus({ tenantId }: { url: string; tenantId: string }) {
    return get<IMigrationStatus>(`${tenantMigrationUrl}/getScheduledTenantMigrationStatus`, { urlParams: { tenantId } });
}

export function scheduleTenantMove(moveRequest: IMigrationRequest, tenantId: string): Promise<IMigrationResponse> {
    return post<IMigrationResponse>(`${tenantMigrationUrl}/scheduleTenantMove`, {
        body: moveRequest,
        urlParams: { tenantId },
    });
}

export function cancelTenantMove(tenantId: string) {
    return axiosDelete(`${tenantMigrationUrl}/cancelTenantMove`, { urlParams: { tenantId } });
}

export function getServiceRegion({ tenantId }: { url: string; tenantId: string }) {
    return get<IRegionsPerService>(`${tenantMigrationUrl}/RegionsPerService`, { urlParams: { tenantId } });
}
