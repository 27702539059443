import { useLocalization } from '@experiences/locales';
import {
    ApButton,
    ApLink,
} from '@uipath/portal-shell-react';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import downloadResources from '../../common/constants/DownloadResources';
import { studioDownloadMarketoUpdate } from '../../services/licensing/MarketoService';
import {
    accountGlobalId,
    accountLogicalName,
    accountType,
    profile,
} from '../../store/selectors';
import {
    logStudioDownload,
    StudioDownload,
} from '../../telemetry';

export const HomePageDownloadStudioButton = ({ asLink }: { asLink?: boolean }) => {
    const accountTypeSelector = useSelector(accountType);

    const { formatMessage: translate } = useIntl();

    const profileState = useSelector(profile);
    const accountGUID = useSelector(accountGlobalId);
    const logicalName = useSelector(accountLogicalName);
    const currentAccountLanguage = useLocalization();

    const updateMarketo = useCallback(
        async (editionType: string) => {
            await studioDownloadMarketoUpdate(editionType, profileState, accountGUID, logicalName, currentAccountLanguage);
        },
        [ profileState, accountGUID, logicalName, currentAccountLanguage ],
    );

    const studioEditionAndLink: {
        edition: 'Enterprise Cloud' | 'Enterprise Trial' | 'Community Edition' | 'Enterprise';
        link: string;
    } = useMemo(() => {
        if (!window.env.userLicenseManagementCloud) {
            return {
                edition: 'Enterprise',
                link: downloadResources.ENTERPRISE_STUDIO_DOWNLOAD_LINK,
            };
        }

        switch (accountTypeSelector) {
            case 'ENTERPRISE':
                return {
                    edition: 'Enterprise Cloud',
                    link: downloadResources.ENTERPRISE_CLOUD_STUDIO_DOWNLOAD_LINK,
                };
            case 'TRIAL':
                return {
                    edition: 'Enterprise Trial',
                    link: downloadResources.ENTERPRISE_TRIAL_STUDIO_DOWNLOAD_LINK,
                };
            case 'COMMUNITY':
            default:
                return {
                    edition: 'Community Edition',
                    link: downloadResources.COMMUNITY_STUDIO_DOWNLOAD_LINK,
                };
        }
    }, [ accountTypeSelector ]);

    const onClick = useCallback(async () => {
        await logStudioDownload(studioEditionAndLink.edition, asLink ? StudioDownload.RightNavLink : StudioDownload.RightNav);
        updateMarketo(studioEditionAndLink.edition);
        if (!asLink) {
            window.open(downloadResources.getDownloadURL(studioEditionAndLink.link));
        }
    }, [ asLink, studioEditionAndLink.edition, studioEditionAndLink.link, updateMarketo ]);

    if (asLink) {
        return <ApLink
            onClick={onClick}
            data-cy="home-page-sidebar-download-studio-link"
            href={downloadResources.getDownloadURL(studioEditionAndLink.link)}>
            {translate({ id: 'CLIENT_STUDIO_DESKTOP_CTA' })}
        </ApLink>;
    }

    return (
        <ApButton
            variant="secondary"
            onClick={onClick}
            data-cy="home-page-sidebar-download-studio-button"
            label={translate({ id: 'CLIENT_DOWNLOAD_STUDIO' })}
        />
    );
};
