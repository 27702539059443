export const AboutAccountsLinkK8s = '/admin-guide/about-accounts';

export const GroupsAndRolesLinkK8s = '/admin-guide/about-accounts';

export const EnablingDisablingServicesLinkK8s = '/admin-guide/managing-tenants-and-services#enabling-or-disabling-services';

export const AboutLicensingLinkK8s = '/admin-guide/about-licensing';

export const LicensingRobotServicesLinkK8s = '/admin-guide/about-licensing';

export const PortalTagManagementOrganizingResourcesLinkK8s = '/admin-guide/managing-tags';

export const ConfiguringVPNForCloudRobotsLinkK8s = '/admin-guide/configuring-vpn-for-cloud-robots';

export const KerberosSetupLinkK8s = '/installation-guide/setting-up-kerberos-authentication';

// Need to clean up Cloud only links below
export const ManagingUsersBulkAddingLinkK8s = '/admin-guide/managing-user-accounts#adding-users-in-bulk';

export const DataResidencyCloudLinkK8s = '/admin-guide/data-residency-cloud';

export const LeavingOrganizationLinkK8s = '/admin-guide/managing-user-preferences#leaving-an-organization';

export const LicensingManagementOptionsLinkK8s = '/overview/license-management-options';

export const LegacyLicenseManagementLinkK8s = '/license-management-options#legacy-license-management';

export const IPRestrictionLinkK8s = '/admin-guide/restricting-access-by-ip';

export const IPRestrictionImportCsvLinkK8s = '/admin-guide/restricting-access-by-ip#csv-import';

export const SessionPolicyLinkK8s = '/admin-guide/session-policy';

export const EncryptionLinkK8s = '/admin-guide/encryption';

export const CustomerManagedKeyBestPracticesLinkK8s = '/admin-guide/customer-managed-key#best-practices-for-using-customer-managed-keys';

export const CustomerManagedKeySwitchLinkK8s = '/admin-guide/switch-from-customer-managed-to-uipath-managed-keys';

export const DirectorySSOLinkK8s = '/admin-guide/configuring-authentication-and-security-organization-level#models';

export const CustomerManagedKeyLinkK8s = '/admin-guide/customer-managed-key';

export const UnassistedTaskMiningDeprecationLinkK8s = '/user-guide/faq#unassisted-task-mining-deprecation';
