import { TenantTagsTab } from '@experiences/interfaces';
import {
    UiDataContextProvider,
    UiSuspensefulOutlet,
} from '@experiences/ui-common';
import type { PropsWithChildren } from 'react';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
    TenantTagsLabels,
    TenantTagsLabelsAdd,
    TenantTagsLabelsEdit,
    TenantTagsProperties,
    TenantTagsPropertiesAdd,
    TenantTagsPropertiesDrawerAdd,
    TenantTagsPropertiesDrawerEdit,
    TenantTagsPropertiesEdit,
} from '../../common/constants/RouteNames';
import { routePaths } from '../../common/constants/routePaths';
import { useOrganizationName } from '../../common/hooks/useOrganizationName';
import BreadcrumbProvider, { useBreadcrumbs } from '../../common/providers/BreadcrumbProvider';
import { useTenantIdContext } from '../../common/providers/TenantIdProvider';
import type { UiRouteObject } from '../../container/routeConfigs/UiRouteObject';
import {
    TenantTagsLabelProvider,
    useTenantTagsLabelContext,
} from './addedit/labels/TenantTagsLabelProvider';
import {
    TenantTagsPropertiesProvider,
    useTenantTagsPropertiesContext,
} from './addedit/properties/TenantTagsPropertiesProvider';
import type { ITenantTagsPropertiesContext } from './addedit/properties/types';

const TenantTagsViewComponent = React.lazy(() => import('./base/TenantTagsViewComponent'));
const AddEditTenantTagsLabelsComponent = React.lazy(() => import('./addedit/labels/AddEditTenantTagsLabelsComponent'));
const AddEditTenantTagsPropertiesComponent = React.lazy(() => import('./addedit/properties/AddEditTenantTagsPropertiesComponent'));
const AddEditTenantTagsPropertiesDrawer = React.lazy(() => import('./addedit/properties/AddEditTenantTagsPropertiesDrawer'));

const TenantTagsProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { formatMessage: translate } = useIntl();
    const organizationName = useOrganizationName();
    const { tenantName } = useTenantIdContext();

    const breadCrumbLinks = useMemo(() => [
        {
            index: 0,
            link: routePaths.admin,
            name: organizationName,
        },
        {
            index: 1,
            link: routePaths.adminTenantHome,
            name: tenantName,
        },
        {
            index: 2,
            link: routePaths.adminTenantTagsLabels,
            name: translate({ id: 'CLIENT_LABELS' }),
        },
        {
            index: 2,
            link: routePaths.adminTenantTagsProperties,
            name: translate({ id: 'CLIENT_PROPERTIES_KEY_VALUE' }),
        },
    ],
    [ organizationName, tenantName, translate ]);

    return <BreadcrumbProvider breadcrumbs={breadCrumbLinks}>
        {children}
    </BreadcrumbProvider>;
};

const AddEditTenantTagsProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { formatMessage: translate } = useIntl();

    const label = useTenantTagsLabelContext();
    const property = useTenantTagsPropertiesContext();

    const { original } = useBreadcrumbs();

    const breadCrumbLinks = useMemo(() => [
        ...original,
        {
            index: 3,
            link: routePaths.adminTenantTagsLabelsAdd,
            name: translate({ id: 'CLIENT_ADD_LABEL' }),
        },
        {
            index: 3,
            link: routePaths.adminTenantTagsLabelsEdit,
            name: translate({ id: 'CLIENT_EDIT_LABEL' }, { label: label?.name }),
        },
        {
            index: 3,
            link: routePaths.adminTenantTagsPropertiesAdd,
            name: translate({ id: 'CLIENT_ADD_PROPERTY' }),
        },
        {
            index: 3,
            link: routePaths.adminTenantTagsPropertiesEdit.replace(':editType', 'details'),
            name: translate({ id: 'CLIENT_EDIT_KEY_DETAILS' }, { key: property?.name }),
        },
        {
            index: 3,
            link: routePaths.adminTenantTagsPropertiesEdit.replace(':editType', 'keyValues'),
            name: translate({ id: 'CLIENT_EDIT_KEY_VALUES' }, { key: property?.name }),
        },
    ],
    [ label?.name, original, property?.name, translate ]);

    return <BreadcrumbProvider breadcrumbs={breadCrumbLinks}>
        {children}
    </BreadcrumbProvider>;
};

export const TenantTagsRoutes: UiRouteObject = {
    path: routePaths.adminTenantTags,
    element: <TenantTagsProvider>
        <UiSuspensefulOutlet />
    </TenantTagsProvider>,
    children: [
        {
            path: routePaths.adminTenantTagsLabels,
            redirectUrls: [ TenantTagsLabels ],
            element: <TenantTagsViewComponent type={TenantTagsTab.LABELS} />,
        },
        {
            path: routePaths.adminTenantTagsLabelsAdd,
            redirectUrls: [ TenantTagsLabelsAdd.replace(':type', 'add') ],
            element: <AddEditTenantTagsProvider>
                <AddEditTenantTagsLabelsComponent type="add" />
            </AddEditTenantTagsProvider>,
        },
        {
            path: routePaths.adminTenantTagsLabelsEdit,
            redirectUrls: [ TenantTagsLabelsEdit.replace(':type', 'edit') ],
            element: <TenantTagsLabelProvider>
                <AddEditTenantTagsProvider>
                    <AddEditTenantTagsLabelsComponent type="edit" />
                </AddEditTenantTagsProvider>
            </TenantTagsLabelProvider>,
        },
        {
            path: routePaths.adminTenantTagsProperties,
            redirectUrls: [ TenantTagsProperties ],
            element: <TenantTagsViewComponent type={TenantTagsTab.PROPERTIES} />,
        },
        {
            path: routePaths.adminTenantTagsPropertiesAdd,
            redirectUrls: [ TenantTagsPropertiesAdd.replace(':type', 'add') ],
            element: <TenantTagsPropertiesProvider>
                <AddEditTenantTagsProvider>
                    <UiDataContextProvider<ITenantTagsPropertiesContext> initialState={{
                        Values: [],
                        DataType: 'String',
                    }}>
                        <AddEditTenantTagsPropertiesComponent type="add" />
                        <UiSuspensefulOutlet />
                    </UiDataContextProvider>
                </AddEditTenantTagsProvider>
            </TenantTagsPropertiesProvider>,
            children: [
                {
                    path: routePaths.adminTenantTagsPropertiesAddValues,
                    redirectUrls: [ TenantTagsPropertiesDrawerAdd.replace(':type', 'add') ],
                    element: <AddEditTenantTagsPropertiesDrawer type="add" />,
                },
            ],
        },
        {
            path: routePaths.adminTenantTagsPropertiesEdit,
            redirectUrls: [ TenantTagsPropertiesEdit.replace(':type', 'edit') ],
            element: <TenantTagsPropertiesProvider>
                <AddEditTenantTagsProvider>
                    <UiDataContextProvider<ITenantTagsPropertiesContext> initialState={{
                        Values: [],
                        DataType: 'String',
                    }}>
                        <AddEditTenantTagsPropertiesComponent type="edit" />
                        <UiSuspensefulOutlet />
                    </UiDataContextProvider>
                </AddEditTenantTagsProvider>
            </TenantTagsPropertiesProvider>,
            children: [
                {
                    path: routePaths.adminTenantTagsPropertiesEditValues,
                    redirectUrls: [ TenantTagsPropertiesDrawerEdit.replace(':type', 'edit') ],
                    element: <AddEditTenantTagsPropertiesDrawer type="edit" />,
                },
            ],
        },
    ],
};
