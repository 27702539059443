import { RouteProvider } from '@experiences/util';
import React from 'react';
import {
    Navigate,
    type RouteObject,
} from 'react-router-dom';

import AuthCallback from '../../auth/AuthCallback';
import AuthError from '../../auth/AuthError';
import { ClearAuthStorageComponent } from '../../auth/ClearAuthStorageComponent';
import Logout from '../../auth/Logout';
import { SessionExpiry } from '../../auth/SessionExpiry';
import * as RouteNames from '../../common/constants/RouteNames';
import AccountAdmin from '../../component/authentication/AccountAdmin';
import GenericError from '../../component/authentication/GenericError';
import InvalidUrl from '../../component/authentication/InvalidUrl';
import RedirectToService from '../../component/authentication/RedirectToService';
import Unregistered from '../../component/authentication/Unregistered';
import { Diagnostics } from '../../component/diagnostics/Diagnostics';
import AccessPolicyError from '../../component/securitySettings/subcomponents/AccessPolicyError';
import RestrictedPolicyError from '../../component/securitySettings/subcomponents/RestrictedPolicyError';
import OrchestratorRouting from '../../component/tenants/subcomponents/OrchestratorRouting';
import RequestUserTokenCallback from '../../component/tenants/subcomponents/token/RequestUserTokenCallback';
import { TestConnection } from '../../component/users/subcomponents/TestConnection';
import App from '../App';
import OriginRouteComponent from '../OriginRouteComponent';
import PortalRedirectComponent from '../PortalRedirectComponent';
import ShellRouteWrapper from '../ShellRouteWrapper';

const getRoutes = () => [
    {
        element: <App />,
        children: [
            {
                path: '/genericerror',
                element: <GenericError />,
            },
            {
                path: '/portal_/genericerror',
                element: <GenericError />,
            },
            {
                path: '/',
                element: window.env.isOnPrem ? <Navigate to="/portal_/cloudrpa" /> : <OriginRouteComponent />,
            },
            ...(window.env.isOnPrem ? [
                {
                    path: '/host',
                    element: <Navigate to="/portal_/cloudrpa" />,
                },
            ] : []),
            {
                path: '/portal_',
                element: window.env.isOnPrem ? <PortalRedirectComponent /> : <OriginRouteComponent />,
            },
            {
                path: '/portal_/cloudrpa',
                element: window.env.isOnPrem ? <AccountAdmin /> : <OriginRouteComponent />,
            },
            {
                path: '/:accountName',
                element: window.env.isOnPrem ? <PortalRedirectComponent /> : <OriginRouteComponent />,
            },
            {
                path: '/portal_/logout',
                element: <Logout />,
            },
            {
                path: '/portal_/testconnection',
                element: <TestConnection />,
            },
            {
                path: '/portal_/restricted-policy',
                element: <RestrictedPolicyError />,
            },
            ...(!window.env.isOnPrem ? [
                {
                    path: '/portal_/autoroute/:accountName/:tenantName',
                    element: <OrchestratorRouting />,
                },
            ] : []),
            {
                path: '/portal_/unregistered',
                element: <Unregistered />,
            },
            ...(!window.env.isOnPrem ? [
                {
                    path: '/portal_/requestusertokencallback',
                    element: <RequestUserTokenCallback />,
                },
            ] : []),
            {
                path: '/portal_/sessionexpired',
                element: <SessionExpiry />,
            },
            {
                path: '/portal_/autherror',
                element: <AuthError />,
            },
            {
                path: '/portal_/accesserror',
                element: <AccessPolicyError />,
            },
            {
                path: '/portal_/loginsuccess',
                element: <AuthCallback />,
            },
            ...(window.env.isOnPrem ? [
                {
                    path: '/portal_/clearAuthStorage',
                    element: <ClearAuthStorageComponent />,
                },
            ] : []),
            ...(window.env.isOnPrem ? [
                {
                    path: '/:accountName/portal_/diagnostics',
                    element: <Diagnostics />,
                },
            ] : []),
            {
                path: RouteNames.Root,
                element: <AccountAdmin />,
            },
            {
                path: RouteNames.LoginSuccess,
                element: <AuthCallback />,
            },
            {
                path: RouteNames.CloudRPA,
                element: <AccountAdmin />,
            },
            {
                path: RouteNames.RedirectToService,
                element: <RedirectToService />,
            },
            {
                path: `${RouteNames.PortalPrefix}/*`,
                element: <RouteProvider>
                    <ShellRouteWrapper />
                </RouteProvider>,
            },
            {
                path: '*',
                element: <InvalidUrl />,
            },
        ],
    },
] satisfies RouteObject[];

export default getRoutes;
