import { HeaderNames } from '@experiences/constants';
import type {
    IBlob,
    IFile,
    IRequestPropsWithToken,
} from '@experiences/interfaces';
import { isLocal } from '@uipath/portal-shell-util';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';

import { imageUrlToBase64 } from '../static/FileUtil';
import { getBasePath } from '../static/PathUtil';
import { getHeaders } from './Headers';
import {
    getRequestBody,
    getRequestParams,
} from './RequestUtil';
import { getFileName } from './ResponseUtil';

export const getBaseURL = () => {
    const rootContainer = window?.RootContainer ?? {};

    if (rootContainer.portalDomain && !isLocal()) {
        return `//${rootContainer.portalDomain}`;
    }

    return window.location?.origin;
};

// eslint-disable-next-line no-underscore-dangle
export const axiosInstance = axios.create({ baseURL: getBaseURL() });

interface CacheItem<T> {
    promise: Promise<AxiosResponse<T>>;
    timer: NodeJS.Timeout;
}
const CACHE_TIMEOUT = 10000;
const cache: Record<string, CacheItem<any>> = {};

// Proof of concept for caching axios requests - default is disabled
export async function get<T>(url: string, requestProps: IRequestPropsWithToken, enableCaching = false): Promise<T> {
    const headers = await getHeaders(requestProps?.accessToken, requestProps.headers);
    const basePath = requestProps?.ignoreBasePath ? '' : getBasePath(requestProps.stripPortalPath);
    const params = getRequestParams(
        requestProps?.urlParams,
        omitBy(requestProps?.pagination, isUndefined),
        requestProps?.shouldEnumerate,
    );
    const fullUrl = `${basePath}${url}${params}`;

    // Check if the promise is in the cache
    if (enableCaching) {
        const cachedItem = cache[fullUrl];
        if (cachedItem) {
            if (process.env.NODE_ENV === 'development') {
                // eslint-disable-next-line no-console
                console.log(' ✅ [AXIOS-CACHE-HIT]: ', fullUrl);
            }
            const response = await cachedItem.promise;
            return response.data;
        }
    }

    const timeout = setTimeout(() => {
        if (requestProps?.cancelTokenSource) {
            if (process.env.NODE_ENV === 'development') {
                // eslint-disable-next-line no-console
                console.log(' ❌ [AXIOS-TIMEOUT]: ', fullUrl);
            }
            requestProps.cancelTokenSource.cancel();
        }
    }, 3000);

    const promise = axiosInstance.get<T>(fullUrl,
        {
            headers,
            data: {}, // content-type header is not passed if data is undefined
            cancelToken: requestProps?.cancelTokenSource?.token,
            timeout: requestProps?.timeout,
        },
    );

    // Store the promise in the cache
    if (enableCaching) {
        cache[fullUrl] = {
            promise,
            timer: setTimeout(() => {
                delete cache[fullUrl];
            }, CACHE_TIMEOUT),
        };
        promise.catch(() => {
            if (process.env.NODE_ENV === 'development') {
                // eslint-disable-next-line no-console
                console.log(' ❌ [AXIOS-FAILED]: ', fullUrl);
            }
            delete cache[fullUrl];
        });
    }

    const response = await promise;

    clearTimeout(timeout);

    return response.data;
}

export async function getFile(url: string, requestProps: IRequestPropsWithToken): Promise<IFile> {
    const headers = await getHeaders(requestProps.accessToken, requestProps?.headers);
    const basePath = requestProps?.ignoreBasePath ? '' : getBasePath(requestProps.stripPortalPath);

    const response = await axiosInstance.get<string>(
        `${basePath}${url}` +
      getRequestParams(
          requestProps?.urlParams,
          omitBy(requestProps?.pagination, isUndefined),
          requestProps?.shouldEnumerate,
      ),
        {
            headers,
            data: {}, // content-type header is not passed if data is undefined
            cancelToken: requestProps?.cancelTokenSource?.token,
        },
    );

    const filename = getFileName(response.headers[HeaderNames.ContentDisposition]);
    const data = response.data;
    return {
        filename,
        data,
    };
}

export async function getBlob(url: string, requestProps: IRequestPropsWithToken): Promise<IBlob> {
    const headers = await getHeaders(requestProps.accessToken, requestProps?.headers);
    const basePath = requestProps?.ignoreBasePath ? '' : getBasePath(requestProps.stripPortalPath);

    const response = await axiosInstance.get<Blob>(
        `${basePath}${url}` +
      getRequestParams(
          requestProps?.urlParams,
          omitBy(requestProps?.pagination, isUndefined),
          requestProps?.shouldEnumerate,
      ),
        {
            responseType: 'blob',
            headers,
            data: {}, // content-type header is not passed if data is undefined
            cancelToken: requestProps?.cancelTokenSource?.token,
        },
    );

    const filename = getFileName(response.headers[HeaderNames.ContentDisposition]);
    const data = response.data;
    return {
        filename,
        data,
    };
}

export async function post<T>(url: string, requestProps: IRequestPropsWithToken) {
    const headers = await getHeaders(requestProps.accessToken, requestProps?.headers);
    const body = getRequestBody(requestProps);
    const basePath = requestProps?.ignoreBasePath ? '' : getBasePath(requestProps.stripPortalPath);

    const response = await axiosInstance.post<T>(
        `${basePath}${url}` + getRequestParams(requestProps?.urlParams, omitBy(requestProps?.pagination, isUndefined)),
        body,
        {
            headers,
            xsrfCookieName: window.env.xsrfCookieName,
            cancelToken: requestProps?.cancelTokenSource?.token,
            timeout: requestProps?.timeout,
        },
    );

    return response.data;
}

export async function put<T>(url: string, requestProps: IRequestPropsWithToken) {
    const headers = await getHeaders(requestProps.accessToken, requestProps?.headers);
    const body = requestProps?.body ? JSON.stringify(requestProps?.body) : {};
    const basePath = requestProps?.ignoreBasePath ? '' : getBasePath();

    const response = await axiosInstance.put<T>(`${basePath}${url}`, body, {
        headers,
        xsrfCookieName: window.env.xsrfCookieName,
        cancelToken: requestProps?.cancelTokenSource?.token,
    });

    return response.data;
}

export async function patch<T>(url: string, requestProps: IRequestPropsWithToken) {
    const headers = await getHeaders(requestProps.accessToken, requestProps.headers);
    const body = requestProps?.body ? JSON.stringify(requestProps?.body) : {};
    const basePath = getBasePath();

    const response = await axiosInstance.patch<T>(`${basePath}${url}` + getRequestParams(requestProps?.urlParams), body, {
        headers,
        xsrfCookieName: window.env.xsrfCookieName,
        cancelToken: requestProps?.cancelTokenSource?.token,
    });

    return response.data;
}

export async function axiosDelete(url: string, requestProps: IRequestPropsWithToken) {
    const headers = await getHeaders(requestProps.accessToken, requestProps.headers);
    const body = requestProps?.body ? JSON.stringify(requestProps?.body) : {};
    const basePath = requestProps?.ignoreBasePath ? '' : getBasePath();

    const response = await axiosInstance.delete(
        `${basePath}${url}` + getRequestParams(requestProps?.urlParams, omitBy(requestProps?.pagination, isUndefined)),
        {
            headers,
            data: body,
            xsrfCookieName: window.env.xsrfCookieName,
            cancelToken: requestProps?.cancelTokenSource?.token,
        },
    );

    return response.data;
}

export async function getAsset(url: string, token: string, { base64 = false } = {}) {
    const response = await fetch(url, { headers: { 'Authorization': `Bearer ${token}` } });
    const blob = await response.blob();

    if (base64) {
        return await imageUrlToBase64(blob);
    }

    return await response.text();
}

export function isAxiosError(error: any): error is AxiosResponse {
    return error.isAxiosError;
}
