import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React from 'react';

import { UiText } from '../UiText/UiText';
import { UiLogo } from './UiLogo';

const useStyles = makeStyles(theme =>
    createStyles({
        flex: { display: 'flex' },
        titleContainer: {
            marginTop: '12px',
            marginLeft: '4px',
        },
        primaryTitle: {
            fontSize: '32px',
            letterSpacing: '-1px',
        },
        secondaryTitle: {
            fontSize: '12px',
            letterSpacing: '1px',
            marginTop: '1px',
        },
        trademark: {
            marginTop: '-4px',
            fontSize: '6px',
        },
        standardFont: {
            fontFamily: 'poppins',
            fontWeight: 600,
        },
        standardColor: { color: theme.palette.text.primary },
    }),
);

interface IUiSignupLogoProps {
    productName?: string;
    className?: string;
    isTestCloud?: boolean;
}

export const UiSignupLogo = ({
    productName, className, isTestCloud,
}: IUiSignupLogoProps) => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.flex, className)}>
            <UiLogo />
            <div className={classes.titleContainer}>
                {!isTestCloud && (
                    <div className={classes.flex}>
                        <UiText className={clsx(classes.primaryTitle, classes.standardFont, classes.standardColor)}>
                            Automation Cloud
                        </UiText>
                        <sup className={clsx(classes.trademark, classes.standardColor)}>
                            TM
                        </sup>
                    </div>
                )}
                <UiText className={clsx(classes.secondaryTitle, classes.standardFont, classes.standardColor)}>
                    {productName ? productName.toUpperCase() : ''}
                </UiText>
            </div>
        </div>
    );
};
